<template>
  <div class="sidebar">
    <slot />
  </div>
</template>

<style scoped>
.sidebar {
  /* TODO: костыль пока не понял что делать с контейнером */
  position: relative;
  bottom: 28px;
  /* TODO: высоту хедера надо в константу */
  max-height: calc(100vh - 148px);
  gap: 16px;
  display: flex;
  flex-direction: column;
}

:deep(div) {
  flex-shrink: 0;
}

:deep(div.shrinkable) {
  flex-shrink: 1;
}
</style>