<template>
  <g style="cursor: pointer">
    <path
      fill="black"
      stroke="none"
      stroke-width="0"
      :d="`M${left - 12},${top + 10} h${10} a${4},${4} 0 0 1 ${4},${4}
          v${8} a${4},${4} 0 0 1 -${4},${4}
          h-${10} a${4},${4} 0 0 1 -${4},-${4}
          v-${8} a${4},${4} 0 0 1 ${4},-${4}
          z`"
    />
    <path
      v-for="(color, index) in lines"
      :key="color + index"
      :d="`M${left -12},${top + 14 + (index*4)} h${10} `"
      :stroke="color"
      stroke-width="1"
    />
  </g>
</template>

<script>
export default {
  props: ['top', 'left', 'item', 'borderRadius'],
  computed: {
  
    lines() {
      const result = [];
      const children = this.item.children;
      for (let i = 0; i < 3; i++) {
        if (children[i]) {
          result.push(children[i].b_arrow_color);
        }
      }
      return result;
    },
  },
};
</script>

<style scoped></style>
