<template>
  <div
    style="width: 200px; height: 256px; background: #232323; border-radius: 8"
  >
    <div>Show on tree</div>
    <div class="d-flex align-start text-caption px-2" style="gap: 1em">
      <button @click="$emit('close')">Cancel</button>
      <button @click="handleApply">Apply</button>
    </div>
    <div class="d-flex align-center px-2 text-caption" v-if="localModel">
      <input
        type="checkbox"
        hide-details
        density="compact"
        :checked="getAllCheckValue"
        @click="handleClickAll"
      />
      All moves
    </div>
    <div
      v-if="localModel"
      style="height: 168px; overflow-y: scroll"
      class="px-2"
    >
      <div
        v-for="(child, i) in item.children"
        :key="'child' + i"
        class="d-flex align-center text-caption my-1"
        style="height: 28px; gap: 1em"
      >
        <input
          type="checkbox"
          density="compact"
          hide-details
          :checked="getVal(child.nodethis)"
          @click="e =>{  e.stopPropagation(); setVal(child.nodethis, !getVal(child.nodethis))}"
        />
        <div style="position: relative">
          <div
            v-if="child.move_count_hero > 0"
            style="
              font-size: 10px;
              position: absolute;
              top: 0px;
              left: 2px;
              color: #9a9a9a;
            "
          >
            {{ child.move_count_hero }}
          </div>
          <svg
            width="23"
            height="31"
            viewBox="0 0 23 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 16.5C0.723858 16.5 0.5 16.7239 0.5 17C0.5 17.2761 0.723858 17.5 1 17.5V16.5ZM23 17L18 14.1132V19.8868L23 17ZM1 17.5H18.5V16.5H1V17.5Z"
              :fill="child.b_arrow_color"
            />
          </svg>
        </div>
        <div
          class="menu-block d-flex align-center"
          :style="{ background: getBg(child) }"
        >
          <div class="inner-menu-block">{{ getMoveText(child) }}</div>
          <div style="color: #232323; font-size: 12px; margin-left: 2px">
            {{ child.ev_text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
const pink = '#BB9BEE';
const blue = '#9EDDF8';
const grey = '#9A9A9A';
export default {
  props: ['item', 'permanentlyHiddenNodes', 'userPreferences', 'rootColor'],
  data: () => ({
    localModel: null,
  }),
  computed: {
    getVal() {
      const localModel = this.localModel;
      return (nodethis) => {
        return localModel[nodethis];
      };
    },
    getAllCheckValue() {
      const localModel = this.localModel;
      if (!localModel) return false;
      return Object.values(localModel).indexOf(false) === -1;
    },
  },
  methods: {
    getMoveText(item) {
      if (!this.userPreferences) return;

      const { lang_pieces } = this.userPreferences;
      const move_lang = item?.move_lang || [];
      if (!move_lang) {
        return 'Root';
      }
      if (item.hideText === true) return '';
      if (move_lang && move_lang[lang_pieces]) {
        return move_lang[lang_pieces];
      }
      return move_lang[0] || '';
    },
    getBg(item) {
      const { status, default_training } = item;

      if (status === 1) {
        return grey;
      }

      if (this.rootColor === 'black') {
        if (item.hm % 2 === 0) {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return pink;
          }

          return grey;
        } else {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return blue;
          }

          return grey;
        }
      } else {
        if (item.hm % 2 !== 0) {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return pink;
          }

          return grey;
        } else {
          if (status === 0 || (status === 2 && default_training === 1)) {
            return blue;
          }

          return grey;
        }
      }
    },
    setVal(nodethis, val) {
      const clone = cloneDeep(this.localModel);
      clone[nodethis] = val;
      this.localModel = clone;
    },
    handleClickAll() {
      const getAllCheckValue = this.getAllCheckValue;
      const result = {};
      let newValue = true;
      if (getAllCheckValue === true) {
        newValue = false;
      }
      for (let key of Object.keys(this.localModel)) {
        result[key] = newValue;
      }

      this.localModel = result;
    },
    handleApply() {
      const clone = cloneDeep(this.permanentlyHiddenNodes);

      for (let key of Object.keys(this.localModel)) {
        if (clone[key] === true && this.localModel[key] === true) {
          delete clone[key];
        }
        if (this.localModel[key] === false) {
          clone[key] = true;
        }
      }
      this.$emit('apply', clone);
      this.$emit('close');
    },
  },
  watch: {
    item: {
      immediate: true,
      handler: function (val) {
        const children = val.children;
        const result = {};
        for (let child of children) {
          if (this.permanentlyHiddenNodes[child.nodethis]) {
            result[child.nodethis] = false;
          } else {
            result[child.nodethis] = true;
          }
        }

        this.localModel = result;
      },
    },
    permanentlyHiddenNodes: {
      deep: true,
      handler: function () {
        const children = this.item.children;
        const result = {};
        for (let child of children) {
          if (this.permanentlyHiddenNodes[child.nodethis]) {
            result[child.nodethis] = false;
          } else {
            result[child.nodethis] = true;
          }
        }

        this.localModel = result;
      },
    },
  },
};
</script>

<style scoped>
.menu-block {
  height: 28px;
  width: 86px;
  border-radius: 8px;
}
.inner-menu-block {
  margin-left: 4px;
  padding-left: 4px;

  background: white;
  height: 20px;
  width: 42px;
  border-radius: 6px;
  color: #232323;
  font-size: 12px;
}
</style>
