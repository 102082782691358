<template>
  <portal to="app-bar-portal">
    <div class="mx-2 pointer">
      <analysis-preferences />
    </div>
  </portal>
  <TooltipWithBoard
    v-if="isTooltipShown"
    :tooltip="tooltip"
  />
  <div
    style="position: relative; width: 100%; height: 100%"
    @click="handleOuterClick"
  >
    <analysis-sub-header
      goBackText="Назад к дереву вариантов"
      goBackTarget="Analysis"
    >
      <template v-slot:filters> </template>
      <template v-slot:zoom>
        <analysis-tree-zoom :zoom="zoom" @zoomChange="(val) => (zoom = val)" />
      </template>

      <template v-slot:training>
        <ExercisesSpeedSettings :repeatModel="repeatModel" :massUpdateNodes="massUpdateNodes" :arrowDelayModel="arrowDelayModel" :autoDelayModel="autoDelayModel" :updateParentValue="updateValue"  /> </template>
      <template v-slot:fen></template>
      <template v-slot:board>
        <div
          class="d-flex align-center justify-center semibold-16"
          style="width: 484px"
        >
          <v-btn
            variant="text"
            :class="{ 'text-lightGrey': mode !== 0 }"
            @click="mode = 0"
          >
            Обучение
          </v-btn>
          <v-btn
            variant="text"
            :class="{ 'text-lightGrey': mode !== 1 }"
            @click="mode = 1"
          >
            Тест
          </v-btn>
        </div>
      </template>
    </analysis-sub-header>

    <div style="width: 100vw; height: calc(90vh - 40px)" class="d-flex">
      <div style="height: calc(90vh - 40px); flex: 10">
        <div
          style="width: 100%; height: calc(100vh - 100px); position: relative"
        >
          <div
            :style="`overflow: auto;
              width:calc(100vw - 500px);
              height: calc(100vh - 200px);
              position: absolute;
              padding-top: 10px;
              left: ${showLeftMenu ? 500 : 0}px`"
            ref="treeContainer"
            @click="contextMenu = null"
          >
            <div
              v-if="contextMenu"
              :style="{
                position: 'absolute',
                top: contextMenu.top + 'px',
                left: contextMenu.left + 'px',
                width: '100px',
                height: '40px',
              }"
            >
              <v-btn size="small" @click="goBackWithHeroChain" color="red">
                <v-icon color="white">mdi-arrow-left</v-icon> Go to 3
              </v-btn>
            </div>
            <svg-tree
              v-if="preparedTree"
              :items="getFlatArray"
              :getPaddingTop="getPaddingTop"
              :loadChildren="() => {}"
              :blockHeight="blockHeight"
              :blockWidth="blockWidth"
              :getParentCoords="getParentCoords"
              :setTooltip="toggleTooltip"
              :handleSelectOpening="handleBlockClick"
              :selectedOpening="getSelectedOpening"
              :mode="treeModel"
              :movesModel="movesModel"
              :handleArrowPress="() => {}"
              :rootColor="getOrientation"
              :simple="true"
              :getBg="getBg"
              :size="$store.state.sizes.vw"
              :onContextClick="handleRightClick"
              :blockGap="blockWidth + 32 + 16"
              :scale="zoom"
              :finishedBranches="finishedBranches"
              ref="tree"
               variant="training"
            />
          </div>
        </div>
      </div>
      <div class="container">
        <div class="sidebar">
          <v-card style="height: 156px" class="my-2">
            <ExercisesTotals :totalsData="totalsData" />
          </v-card>
          <v-card
            :style="`height: ${openedCardId !== null ? '156px' : '520px'}`"
          >
            <ExercisesVariantsList
              :table-data="tableData"
              :table-headers="tableHeaders"
              @select="selectBranch"
              :height="openedCardId !== null ? '156px' : '520px'"
            />
          </v-card>

          <collapsible-card
            title="Легенда"
            maxWidth="240px"
            expandedMaxWidth="240px"
            card-id="1"
            :openedCardId="openedCardId"
            :changeOpenedCardId="changeOpenedCardId"
          >
            <ExercisesLegend />
          </collapsible-card>

          <collapsible-card
            :openedCardId="openedCardId"
            :changeOpenedCardId="changeOpenedCardId"
            card-id="3"
            title="Выбрать другую позицию для изучения"
            expandedMaxWidth="520px"
            expandedMaxHeight="320px"
            collapsedIconId="editCard"
            expandedIconId="cancelCard"
          >
            <MistakesList
              @mistakeClick="
                (val) => SET_DATA_BY_KEY({ key: 'selectedMistake', value: val })
              "
              :toggleTooltip="toggleTooltip"
              :exercisesList="exercisesList"
              deletable
              :altFilters="true"
              :customHeight="120"
              :exerOnly="true"
            />
          </collapsible-card>
        </div>
      </div>
      <div style="position: absolute; right: 8px; height: 600px; width: 484px">
        <v-card style="width: 100%; height: 100%">
          <div
            :style="{
              outline: boardDisabled ? '2px solid red' : 'none',
            }"
          >
            <advanced-board
              :size="'400px'"
              :config="{
                viewOnly: boardDisabled,
                fen: showDefaultFen ? defaultFen : currentFen || originalFen,
                orientation: getOrientation,
                lastMove:
                  showDefaultFen && gameId
                    ? null
                    : getPrevMove || originalLastMove,
                animation: {
                  duration: 500,
                },
              }"
              :allMoves="[]"
              :currentMoveIndex="undefined"
              :movePieces="gameId !== undefined"
              ref="board"
              @customMove="handleCustomeMove"
              :arrows="showDefaultFen ? [] : arrows"
            />
          </div>
          <div
            class="d-flex align-center justify-center mt-8"
            style="width: 100%; gap: 1em"
          >
            <v-btn
              v-if="gameId === undefined"
              class="text-none semibold-14"
              size="small"
              color="blue"
              @click="startExercise"
            >
              {{ `Начать ${mode === 0 ? 'тренировку' : 'тестирование'}` }}
            </v-btn>
            <v-btn
              v-if="gameId !== undefined"
              @click="handleStartOver"
              size="small"
              class="text-none semibold-14"
              color="blue"
            >
              Начать заново
            </v-btn>
            <v-btn
              v-if="gameId !== undefined"
              @click="handleСhangeBranch;"
              size="small"
              class="text-none semibold-14"
              color="blue"
            >
              Следующий вариант
            </v-btn>
          </div>
        </v-card>
      </div>

      <!-- <div
        style="flex: 6; position: relative"
        :class="showDefaultFen ? 'cusboard-hidden' : ''"
      >
        <div style="width: 100%" class="d-flex align-center justify-center">
          <v-tabs v-model="mode">
            <v-tab :value="0"> {{ $t('opening2.pageLearn') }} </v-tab>
            <v-tab :value="1"> {{ $t('opening2.pageTest') }} </v-tab>
          </v-tabs>
        </div>
        <div style="position: absolute; left: 4px; top: 4px" v-if="scores">
          <div>Total:</div>
          <div>t_passed: {{ scores.t_passed }}</div>
          <div>t_failed: {{ scores.t_failed }}</div>
          <div>l_passed: {{ scores.l_passed }}</div>
          <div class="my-2">
            <v-text-field
              type="number"
              v-model="autoDelay"
              variant="outlined"
              density="compact"
              label="autoplay delay"
            />
          </div>
          <div v-if="mode === 0" class="my-2">
            <v-text-field
              type="number"
              v-model="arrowDelayModel"
              variant="outlined"
              density="compact"
              label="arrow delay"
            />
          </div>
          <div v-if="selectedNodes.length > 0">
            <div>
              <v-text-field
                type="number"
                v-model="repeatModel"
                variant="outlined"
                density="compact"
                label="repeat count"
              >
              </v-text-field>
            </div>
            <div class="d-flex align-center justify-between">
              <v-btn
                @click="(selectedNodes = []), (repeatModel = 0)"
                size="small"
                >reset</v-btn
              >
              <v-btn @click="massUpdateNodes">apply</v-btn>
            </div>
          </div>
        </div>
       

        <div
          style="width: 100%; gap: 1em"
          class="d-flex align-center justify-center pt-6"
        >
          <v-btn
            color="green"
            v-if="gameId === undefined"
            @click="startExercise"
          >
            {{ $t('opening2.buttonStart') }}</v-btn
          >
          <v-btn color="red" v-else @click="abortExcercise">{{
            $t('opening2.buttonAbort')
          }}</v-btn>
        </div>
        <div
          :style="`width: 36vw; height: 36vh; overflow: auto; position: relative`"
          ref="treeContainer"
        >
     
        </div>
      </div>
      <div style="flex: 2">
        <div v-if="mode === 1 || successCount > 0 || failCount > 0">
          <div>{{ $t('opening2.labelCorrect') }}: {{ successCount }}</div>
          <div>{{ $t('opening2.labelWrong') }}: {{ failCount }}</div>

          <v-btn @click="clearCounters">{{ $t('opening2.buttonReset') }}</v-btn>
        </div>
        <div>
          <v-checkbox label="showSubTrees" v-model="showSubTrees"></v-checkbox>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
/*eslint-disable*/

import AdvancedBoard from '@/components/AdvancedBoard';
import MistakesList from '@/components/MistakesList';
// import AnalysisFilters from '@/components/AnalysisFilters';
import axios from '@/plugins/axiosWrapper';
import SvgTree from '@/components/SvgTree';
import AnalysisSubHeader from '@/components/AnalysisSubHeader.vue';
import AnalysisTreeZoom from '@/components/AnalysisTreeZoom.vue';
import AnalysisPreferences from '@/components/AnalysisPreferences.vue';
import ExercisesVariantsList from '@/components/Exercises/ExercisesVariantsList.vue';
import ExercisesTotals from '@/components/Exercises/ExercisesTotals.vue';
import ExercisesSpeedSettings from '@/components/Exercises/ExercisesSpeedSettings.vue';

import cloneDeep from 'lodash/cloneDeep';
import { mapActions, mapMutations, mapState } from 'vuex';
import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';
import deepFind from '@/helpers/deepFind';
import ExercisesLegend from '@/components/Exercises/ExercisesLegend.vue';
import TooltipWithBoard from '@/components/TooltipWithBoard.vue';

import { useTooltip } from '@/shared/composables/';

const TOOLTIP_HEIGHT = 200;

const mockMoves = [
  'rnbqkb1r/3ppppp/p4n2/1PpP4/8/8/PP2PPPP/RNBQKBNR w KQkq - 0 5',
  'rnbqkbnr/pppppp1p/6p1/8/3P4/8/PPP1PPPP/RNBQKBNR w KQkq - 0 2',
  '2r1k2r/1bqnbppp/pp1ppn2/8/2PNPP2/2N1B1P1/PP4BP/2RQR1K1 b k - 0 13',
  '1qr2rk1/1b1nbppp/pp1ppn2/8/2PNPPP1/1PN1B3/P5BP/2RQR1K1 b - - 0 15',
  'r1b1qrk1/ppp2pb1/n5pp/4p3/2P1P1nB/2N2N2/PP2BPPP/R2Q1RK1 w - - 0 12',
  'rnbqkb1r/5ppp/p3pn2/1pp5/P2P4/1B2PN2/1P3PPP/RNBQ1RK1 b kq - 0 8',
  'r3r1k1/1bqnbppp/pp1ppn2/8/2PNPPP1/2N1B3/PP4BP/2RQR1K1 b - - 0 14',
  'r3r1k1/1bq1bp1p/pp1ppnp1/2n5/1PPNPPP1/2N5/P4BBP/2RQR1K1 b - - 0 16',
  'rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N1B3/PPP2PPP/R2QKB1R b KQkq - 0 6',
  'r4rk1/1bqnbppp/pp1ppn2/8/2PNPP2/2N1B1P1/PP4BP/2RQR1K1 b - - 0 13',
  'r2q1rk1/pbpn2pp/1p1pp3/5p2/2PPn3/P3PN2/1PQ1BPPP/R1B2RK1 w - - 0 12',
  'rnbqkb1r/1p3ppp/p2ppn2/8/3NP1P1/2N1B3/PPP2P1P/R2QKB1R b KQkq - 0 7',
  'rnbqkb1r/1p2pppp/p2p1n2/8/3NPP2/2N5/PPP3PP/R1BQKB1R b KQkq - 0 6',
  'rn1qkbnr/p3pppp/b1p5/8/PppPP3/8/1P3PPP/RNBQKBNR w KQkq - 0 7',
  'r3r1k1/1bq1bppp/pp1ppn2/2n5/2PNPPP1/2N5/PP3BBP/2RQR1K1 b - - 0 15',
  'r1b1qrk1/ppp2pbp/n5p1/4p1B1/2P1P1n1/2N2N2/PP2BPPP/R2Q1RK1 w - - 0 11',
  'rnbqkb1r/5ppp/p2ppn2/1p6/3NP1P1/2N1BP2/PPP4P/R2QKB1R b KQkq - 0 8',
  'r1bqkb1r/pp3pp1/2nppn1p/8/3NP1PP/2N5/PPP2P2/R1BQKBR1 b Qkq - 0 8',
  'r1b2rk1/p1B1ppbp/p5p1/3q4/3P4/4PN2/PP3PPP/R2QK2R b KQ - 0 10',
  'r2qkb1r/1b1n1ppp/pn1pp3/1p4P1/3NP3/2N1BP2/PPP3QP/2KR1B1R b kq - 0 12',
  'r1bqkb1r/3n1ppp/p2ppn2/1p6/3NP1P1/2N1BP2/PPPQ3P/R3KB1R b KQkq - 0 9',
  'rnbqkb1r/pppp1ppp/8/4n3/2P5/4P3/PP3PPP/RNBQKBNR w KQkq - 0 5',
  'rnbq1rk1/pp2bppp/3ppn2/8/3NPP2/2N5/PPP1B1PP/R1BQ1RK1 b - - 0 8',
  'rnbqk2r/ppp1ppbp/5np1/3p2B1/2PP4/2N2N2/PP2PPPP/R2QKB1R b KQkq - 0 5',
  'rnbqkb1r/ppp2ppp/4pn2/3p4/4P3/3P1N2/PPPN1PPP/R1BQKB1R b KQkq - 0 4',
  'r4rk1/pp2pp1p/3q2p1/n2Pb1B1/4P3/5BP1/4QP1P/1R2R1K1 b - - 0 19',
  'rn1q1rk1/pb2ppbp/1p4p1/2p5/3PP3/2P2N2/P3BPPP/1RBQ1RK1 w - - 0 11',
  'r1bqkb1r/pp3pp1/2npp3/6Bn/3NP2P/2N5/PPP2P2/R2QKBR1 b Qkq - 0 10',
  'rnbqkb1r/1p2pppp/p2p1n2/8/3NP3/2N2P2/PPP3PP/R1BQKB1R b KQkq - 0 6',
  'r1b1k2r/pp2ppbp/2n3p1/q1p5/3PP3/2P1BN2/P2Q1PPP/2R1KB1R b Kkq - 0 10',
  'rnb1k2r/pp2ppbp/6p1/q1p5/3PP3/2P1B3/P2Q1PPP/2R1KBNR b Kkq - 0 9',
  'rnb2rk1/pp2ppbp/6p1/8/3PP3/5N2/q2BBPPP/1R1Q1RK1 b - - 0 12',
  'r2q2k1/p1pn2pp/1p1p2r1/3b1p2/1P2n3/P3PN2/1BQ1BPPP/R4RK1 w - - 0 16',
  'rnbq1rk1/pp3pbp/3ppnp1/2pP4/2P1P3/2N2N1P/PP3PP1/R1BQKB1R w KQ - 0 8',
  'rnb1kb1r/2q2ppp/p2ppn2/1p6/4P1P1/1NN1BP2/PPP1Q2P/R3KB1R b KQkq - 0 10',
  'rnbqkb1r/pp2pppp/3p1n2/2p5/4P3/2P2N2/PP1PBPPP/RNBQK2R b KQkq - 0 4',
  'rn2kbnr/pp3ppp/4p3/1q1pP3/3P4/P1N2N2/1P3PPP/R1BQK2R b KQkq - 0 9',
  'rn1r2k1/pp2ppbp/6p1/q1p5/3PP1b1/2P2N2/P2BBPPP/R2Q1RK1 w - - 0 12',
  'r1bqkb1r/pppp1ppp/2n1pn2/8/2PP4/5N2/PP2PPPP/RNBQKB1R w KQkq - 0 4',
  'rnbqkbnr/pp2pppp/3p4/2p5/4P3/6P1/PPPPNP1P/RNBQKB1R b KQkq - 0 3',
  'r1bqkb1r/3npppp/ppp5/2Pp3n/3P1B2/2N1PN2/PP3PPP/R2QKB1R w KQkq - 0 8',
  'rn3rk1/pp2ppbp/6p1/q1pP2B1/4P1b1/2P2N2/P4PPP/2RQKB1R w K - 0 11',
  'rnbqkbnr/pp1ppppp/8/2p5/4P3/1P6/P1PP1PPP/RNBQKBNR b KQkq - 0 2',
  'r1b1k1nr/pp2ppbp/3p2p1/q1p5/3nP3/2NPB1P1/PPPQNPBP/R3K2R b KQkq - 0 8',
  'r1bq1rk1/pp4bp/n1pp1ppn/3Pp3/2P1P2B/2N5/PP1NBPPP/R2QK2R w KQ - 0 12',
  'rnbq1rk1/pp3ppp/4pn2/8/1bBP4/2N2N2/PP3PPP/R1BQ1RK1 b - - 0 9',
  'rn2kbnr/pp1b1ppp/1q2p3/2ppP3/3P4/P1P2N2/1P3PPP/RNBQKB1R b KQkq - 0 6',
  'rnbqk1nr/pp2bppp/4p3/2Pp4/4P3/2P5/PP1N1PPP/R1BQKBNR b KQkq - 0 5',
  'r1bqkb1r/1p3pp1/p1nppn1p/8/3NPPP1/2N1B2P/PPP5/R2QKB1R b KQkq - 0 9',
  'rnbq1rk1/ppp2pbp/3p1np1/4p3/2PPP3/2N2N2/PP2BPPP/R1BQK2R w KQ - 0 7',
  'rn1qkb1r/p1pp1ppp/bp2pn2/8/2PP4/1P3NP1/P3PP1P/RNBQKB1R b KQkq - 0 5',
  'rnbqkb1r/pppp1ppp/8/4P3/2P3n1/8/PP2PPPP/RNBQKBNR w KQkq - 0 4',
  'rnbqkb1r/pp3pp1/3ppn1p/8/3NP1PP/2N5/PPP2P2/R1BQKB1R b KQkq - 0 7',
];
const gifs = {
  success: [
    'https://media.tenor.com/Cyr2PR6E3kkAAAAM/sacha-baron.gif',
    'https://media.tenor.com/-8Uay6X3E3UAAAAM/gil-cat.gif',
    'https://media.tenor.com/wn4IlOZj4cIAAAAM/solar-highway-great-success.gif',
    'https://media.tenor.com/lQBJJmatxPYAAAAM/mission-accomplished-penguins.gif',
    'https://media.tenor.com/0ny6vluAPvQAAAAM/fist-pump-data.gif',
    'https://media.tenor.com/nAKjBXVND3sAAAAM/approuv%C3%A9-approuve.gif',
    'https://media.tenor.com/y5gnooP0k1EAAAAM/cat-cats.gif',
    'https://media.tenor.com/eBP7GmgMBcAAAAAM/approve-startrek.gif',
    // "https://media.tenor.com/xMv5M4u4vBcAAAAM/snape-approved.gif"
  ],

  fail: [
    'https://media.tenor.com/7Blzpyg7858AAAAM/failure-boo.gif',
    'https://media.tenor.com/25E9ZD89nqIAAAAM/soccer-goal-goalie.gif',
    'https://media.tenor.com/zl7rf_B9SlIAAAAM/feliznavidad-wide-awake.gif',
    'https://media.tenor.com/Ighp-_7Q6EQAAAAM/elf-elf-meme.gif',
    'https://media.tenor.com/ZFc20z8DItkAAAAM/facepalm-really.gif',
    'https://media.tenor.com/VFtxv4iH02kAAAAM/po-facepalm.gif',
    'https://media.tenor.com/2PsASViaQWYAAAAM/facepalm-panda.gif',
    'https://media.tenor.com/WP8A1a_-OSQAAAAM/oh-no-facepalm.gif',
  ],
};

export default {
  data: () => ({
    showSubTrees: false,
    blockHeight: 24,
    blockWidth: 48,
    gameId: undefined,
    results: [],
    gifUrl: undefined,
    gameMoves: [],
    currentIndex: undefined,
    mode: 0,
    originalFen: undefined,
    originalLastMove: null,
    unfinishedId: undefined,
    successCount: 0,
    failCount: 0,
    defaultFen: undefined,
    showDefaultFen: false,
    trainId: null,
    movesModel: 1,
    preparedTree: null,
    treeModel: 1,
    selectedTraining: null,
    treeData: null,
    currentSubTree: undefined,
    currentSubTreeNode: undefined,
    currentSiblingsSubTree: {},
    possibleSiblings: [],
    currentFen: '',
    finishedBranches: {},
    finishedMoves: {},
    scores: null,
    localArrowDelay: 0,
    arrowTimeout: null,
    arrows: [],
    selectedNodes: [],
    repeatModel: 0,
    boardDisabled: false,
    getSubTrees: null,
    showDialog: true,
    contextMenu: null,
    autoDelay: '2',
    zoom: 1,
    openedCardId: '1',
    showRightMenu: true,
    showLeftMenu: true,
    selectedRow: undefined,
  }),
  setup () {
    const { toggleTooltip, isTooltipShown, tooltip } = useTooltip(TOOLTIP_HEIGHT);
    return {
      toggleTooltip,
      isTooltipShown,
      tooltip,
    };
  },
  components: {
    AdvancedBoard,
    AnalysisPreferences,
    MistakesList,
    SvgTree,
    AnalysisSubHeader,
    AnalysisTreeZoom,
    ExercisesLegend,
    ExercisesVariantsList,
    ExercisesTotals,
    ExercisesSpeedSettings,
    TooltipWithBoard,
  },
  computed: {
    ...mapState('data', [
      'openingsTree',
      'compareFilters',
      'selectedOpening',
      'heroesList',
      'unfinishedExercises',
      'exercisesList',
      'selectedMistake',
    ]),
    ...mapState('ui', ['userPreferences']),
    getCompareString() {
      const compareFilters = this.compareFilters;
      if (!compareFilters || Object.keys(compareFilters).length === 0)
        return '';

      const { pgid, avelo, categories, elodiff } = compareFilters;
      const name = this.getHeroName;

      if (pgid === 1) {
        return this.$t('playground.pg1');
      }

      const siteMap = {
        1: this.$t('playground.pg1'),
        2: this.$t('playground.pg2'),
        3: this.$t('playground.pg3'),
      };

      const aveloMap = {
        2100: this.$t('compare.avelo1'),
        2500: this.$t('compare.avelo2'),
        2900: this.$t('compare.avelo3'),
      };

      const categoriesMap = {
        2: this.$t('compare.tc2_2'),
        3: this.$t('compare.tc2_3'),
        4: this.$t('compare.tc2_4'),
      };

      const elodiffMap = {
        4: this.$t('compare.headered4'),
        '3,4,5': this.$t('compare.headered345'),
        '2,3,4,5,6': this.$t('compare.headered23456'),
      };

      return (
        `${siteMap[pgid]}
      ${avelo.map((i) => aveloMap[i]).join(this.$t('compare.separator1'))}` +
        this.$t('compare.separator3') +
        `${categories
          .map((i) => categoriesMap[i])
          .join(this.$t('compare.separator1'))}
      ${elodiffMap[elodiff]}
      `
      );
    },
    getSelectedOpening() {
      if (!this.gameId) return null;
      return (
        this.getSubTrees?.[this.currentSubTree]?.[this.currentSubTreeNode] ||
        null
      );
    },
    getFullSelectedPath() {
      const { selectedNodes } = this;

      return  this.getFullPath(selectedNodes);
    },
    autoDelayModel: {
      get() {
        const autoDelay = this.autoDelay;
        return autoDelay;
      },
      set(val) {
        localStorage.setItem('autoDelay', val);
        this.autoDelay = Number(val);
      },
    },
    arrowDelayModel: {
      get() {
        const localArrowDelay = this.localArrowDelay;
        return localArrowDelay;
      },
      set(val) {
        localStorage.setItem('arrowDelay', val);
        this.localArrowDelay = Number(val);
      },
    },
    arrowsModel() {
      const arrows = this.arrows;
      return arrows;
    },
    getBg() {
      const {
        getSubTrees,
        currentSubTree,
        selectedNodes,
        getFullSelectedPath,
        currentSubTreeNode,
      } = this;
      // if (currentSubTree === undefined || currentSubTreeNode === undefined) return 'white';
      const herowhite = Number(this.$route.params.herowhite);

      return (nodethis, hm) => {
        const userMoveColor = '#BB9BEE';
        const oppMoveColor = '#9EDDF8';
        const inactiveColor = '#52545A';

        if (selectedNodes.findIndex((i) => i.nodethis === nodethis) > -1) {
          return 'rgba(255,155,155,1)';
        }
        if (
          getFullSelectedPath.findIndex((i) => i.nodethis === nodethis) > -1
        ) {
          return 'rgba(255,195,195,0.7)';
        }
        //если вражьи ходы
        if (this.isEnemyMove(hm)) {
          if (currentSubTree == undefined) {
            return oppMoveColor;
          }
          if (
            getSubTrees?.[currentSubTree]?.find((i) => i?.nodethis === nodethis)
          ) {
            return oppMoveColor;
          } else {
            return inactiveColor;
          }
        }

        if (currentSubTree == undefined) {
          return userMoveColor;
        }

        if (
          getSubTrees?.[currentSubTree]?.find((i) => i?.nodethis === nodethis)
        ) {
          return userMoveColor;
        } else {
          return inactiveColor;
        }

        return userMoveColor;
      };
    },

    getFlatArray() {
      const tree = this.preparedTree;
      const movesModel = this.movesModel;
      const openedNodesModel = this.openedNodesModel;
      const mode = this.mode;
      const gameId = this.gameId;

      if (!tree) return [];
      if (!openedNodesModel(tree)) {
        return [[tree]];
      }
      const reducer =
        (iteration, parentIndex = 0) =>
        (acc, item, index) => {
          if (!acc[iteration]) {
            acc[iteration] = [];
          }

          if (movesModel === 2 && item.show_move !== 1) {
            return acc;
          }

          if (gameId) {
            item.hideText = mode === 1 ? !item.t_passed : false;
          }
          acc[iteration].push({
            ...item,
            parentIndex: parentIndex,
            visible: true,
          });

          if (!openedNodesModel(item)) {
            return acc;
          } else {
            if (!item.children) {
              item.children = [];
            } else {
              item.children.reduce(reducer(iteration + 1, index), acc);
            }
          }

          return acc;
        };

      const result = tree.children.reduce(reducer(1, 0), [[tree]]);

      return result;
    },

    // currentFen() {
    //   const gameMoves = this.gameMoves;
    //   if (gameMoves.length === 0 || this.currentIndex === undefined)
    //     return undefined;
    //   return gameMoves[this.currentIndex]?.fen_actual;
    // },
    getOrientation() {
      const currentFen = this.currentFen;
      if (this.gameId && this.selectedTraining) {
        return this.selectedTraining.herowhite == 1 ? 'white' : 'black';
      }
      if (!currentFen) {
        return this.$route.params.herowhite == 1 ? 'white' : 'black';
      }
      return currentFen.split(' ')[1] === 'w' ? 'white' : 'black';
    },
    currentMoveItem() {
      return this.gameMoves[this.currentIndex];
    },
    heroid() {
      return Number(this.$route.params.heroid);
    },
    posid() {
      return Number(this.$route.params.posid);
    },
    herowhite() {
      return Number(this.$route.params.herowhite);
    },
    heroCategories() {
      return this.$route.params.categories;
    },
    getPrevMove() {
      if (!this.gameId) return null;

      const currentNode =
        this?.getSubTrees?.[this.currentSubTree]?.[this.currentSubTreeNode];

      if (
        currentNode &&
        currentNode.prehistory_uci &&
        currentNode.prehistory_uci.length > 0
      ) {
        const lastMove =
          currentNode.prehistory_uci[currentNode.prehistory_uci.length - 1];
        const orig = lastMove.substring(0, 2);
        const dest = lastMove.substring(2, 4);

        return [orig, dest];
      }

      return null;
      // const currentMoveItem = this.currentMoveItem;

      // if (!currentMoveItem || !currentMoveItem.opp_uci) {
      //   return null;
      // } else {
      //   return [
      //     currentMoveItem.opp_uci.substring(0, 2),
      //     currentMoveItem.opp_uci.substring(2, 4),
      //   ];
      // }
    },
    arrowListener() {
      const { mode, currentSubTree, currentSubTreeNode, gameId, getSubTrees } =
        this;

      return Math.random();
    },
    pointsKeys() {
      return this.mode === 1 ? [ 't_passed', 't_need'] : ['l_passed', 'l_need'];
    },
    getPointsString() {
      const trees = this.getSubTrees;
      if (!trees) return [];
      const reducer = (acc, item) => {
        if (this.isEnemyMove(item.hm)) {
          return acc;
        }

        if (item[this.pointsKeys[0]] !== undefined) {
          acc[this.pointsKeys[0]] += item[this.pointsKeys[0]];
          acc[this.pointsKeys[1]] += item[this.pointsKeys[1]];
        }

        return acc;
      };

      return trees.map((item) => {
        const pointsArr = item.reduce(reducer, {
          [this.pointsKeys[0]]: 0,
          [this.pointsKeys[1]]: 0,
        });
        return {
          id: item[0].position_id,
          points: `${pointsArr[this.pointsKeys[0]]} / ${
            pointsArr[this.pointsKeys[1]]
          }`,
        };
      });
    },
    tableHeaders() {
      return [
        {
          value: 'index',
          title: '№',
        },
        {
          value: 'points',
          title: 'Обучение',
        },
        {
          value: 'test',
          title: 'Тест',
        },
      ];
    },
    totalsData() {
      const preparedTree = this.preparedTree;

      const obj = {
        learn: {
          all: 0,
          finished: 0,
        },
        test: {
          all: 0,
          finished: 0,
        },
      };

      if (!preparedTree) {
        return obj;
      }

      const reducer = (acc, item) => {
        if (!item) return acc;

        if (item.children && item.children.length > 0) {
          item.children.reduce(reducer, acc);
        }

        if (this.isEnemyMove(item.hm)) {
          return acc;
        }

        const { l_need, l_passed, t_need, t_passed } = item;

        acc.learn.all += l_need;
        acc.learn.finished += l_passed;

        acc.test.all += t_need;
        acc.test.finished += t_passed;

        return acc;
      };

      const reduced = [preparedTree].reduce(reducer, obj);

      return reduced;
    },
    tableData() {
      if (!this.getPointsString) return [];
      return this.getPointsString.map((item, i) => {
        const index = String(i + 1);
        return {
          index,
          points: item.points,
          test: '\u2014',
          id: item.id,
          selected: this.selectedRow === index,
        };
      });
    },
  },
  methods: {
    ...mapActions('data', [
      'getOpenings',
      'loadChildren',
      'getMistakes',
      'modifyPositionLabel',
      'getHeroesList',
      'getExercisesList',
      'getHeroChain',
    ]),
    ...mapMutations('data', ['SET_DATA_BY_KEY']),
    updateValue({key, value}) {
      this[key] = value
    },
    handleStartOver() {
      this.abortExcercise();
      this.startExercise();
    },
    handleChangeBranch() {},
    selectBranch(row) {
      this.selectedRow = row.index;
      console.log(row.index);
      this.currentSubTree = row.index - 1;
    },
    async goToNode(node, offset = { top: 0, left: 0 }) {
      const tree = this.$refs.tree;

      if (!tree) {
        return;
      }

      const coords = tree.getCoordsFromDict(node.nodethis);

      if (coords) {
        const container = this.$refs.treeContainer;

        if (container) {
          container.scrollTo({
            left: coords[0] + offset.left,
            top: coords[1] + offset.top,
            behavior: 'smooth',
          });
        }
      }
    },
    changeOpenedCardId(val) {
      this.openedCardId = val;
    },
    getFullPath(selectedNodes) {
      const treeData = this.treeData;


      if (selectedNodes.length === 0 || !treeData) {
        return [];
      }

      if (selectedNodes.length === 1) {
        return selectedNodes;
      }

      const result = {};

      const getFullPath = (target, acc, item) => {
        if (item.nodethis === target.nodethis && item.hm === target.hm) {
          return [...acc, item];
        }
        if (item.hm > target.hm) {
          return;
        }
        if (item.children) {
          let res = item.children
            .map((child) => getFullPath(target, [...acc, item], child))
            .filter((i) => i);
          if (res.length > 0) {
            return res[0];
          }
          return;
        }
      };

      for (let i = 0; i < selectedNodes.length; i++) {
        for (let u = i + 1; u < selectedNodes.length; u++) {
          const a = selectedNodes[i];
          const b = selectedNodes[u];

          const fullPath = getFullPath(b, [], a);
          if (fullPath) {
            fullPath.forEach((i) => (i.nodethis ? result[i.nodethis] = i : null));
          }
        }
      }

      return Object.values(result);
    },
    goBackWithHeroChain() {
      // const item = this.contextMenu.item

      // const poschain  = this.getFullPath([this.treeData, item ])?.map(i => (i.pos_to_id || i.position_id))
      //?.filter(i => i)
      // const result = {
      //   poschain,
      //   nodeid: item.pos_to_id,
      //   heroid: this.heroid,
      //   // hm: item.hm,
      //   herowhite: this.herowhite,
      // }

      this.SET_DATA_BY_KEY({ key: 'exerciseTree', value: this.preparedTree });

      this.contextMenu = null;

      this.gotToAnalysis();
    },
    handleOuterClick() {
      this.contextMenu = null;
    },
    handleRightClick({ item, e }) {
      this.contextMenu = {
        top: e.offsetY,
        left: e.offsetX,
        item,
      };
      console.log(e);
    },
    prepareSubTrees(treeData) {
      if (!treeData) return null;

      const start = [[]];

      const iterator = (currentArr, array) => {
        for (let i = 0; i < array.length; i++) {
          const item = array[i];
          if (i !== 0) {
            const newArr = [...currentArr].filter((n) => n.hm < item.hm);
            newArr.push(item);
            start.push(newArr);
            if (item.children && item.children.length > 0) {
              iterator(newArr, item.children);
            }
          } else {
            currentArr.push(item);
            if (item.children && item.children.length > 0) {
              iterator(currentArr, item.children);
            }
          }
        }
      };
      iterator(start[0], [treeData]);

      for (let i = 0; i < start.length; i++) {
        const current = start[i];
        if (this.isEnemyMove(current[current.length - 1].hm)) {
          current.length = current.length - 1;
        }
        if (current.length === 0) {
          start.splice(i, 1);
          i--;
        }
      }

      return start;
      // return [treeData].reduce(reducer(start[0]), start);
    },
    massUpdateNodes(val) {
      const mode = this.mode;
      const key = mode === 0 ? 'l_need' : 't_need';
      const repeatModel = Number(val)
      // const repeatModel = Number(this.repeatModel);
          const reducer = (acc,item) => {
          if(item.nodethis) {
            acc.push({...item })
          }
          if(item.children && item.children.length > 0) {
            item.children.reduce(reducer, acc)
          }
          return acc
        }
        // return [treeData].reduce(reducer, [])

      const allNodes = this.selectedNodes.length > 0 ?  this.getFullSelectedPath : [this.treeData].reduce(reducer, []);

      axios({
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {
          mtd: 'training/update',
          id: this.trainId,
          newvalues: allNodes.map((item) => ({
            nodethis: item.nodethis,
            [key]: repeatModel,
          })),
        },
      }).catch((e) => console.log(e));

      const clonedTree = cloneDeep(this.preparedTree);

      for (let i = 0; i < allNodes.length; i++) {
        const item = allNodes[i];
        const target = deepFind({
          array: [clonedTree],
          value: item?.nodethis,
          key: 'nodethis',
          cb: (n) => n.pos_from_id === item.pos_from_id,
        });

        if (target) {
          target[key] = repeatModel;
        }
      }

      this.preparedTree = clonedTree;

      this.selectedNodes = [];
      this.repeatModel = 0;
    },
    handleBlockClick(node, e) {
      if (this.gameId) return;
      const hasCtrl = e.ctrlKey;

      const clone = cloneDeep(this.selectedNodes);

      if (!hasCtrl) {
        this.selectedNodes = [node];
        return;
      }
      const index = clone.findIndex((i) => i.nodethis === node.nodethis);
      if (index === -1) {
        clone.push(node);
      } else {
        clone.splice(index, 1);
      }

      this.selectedNodes = clone.sort((a, b) => {
        return a.hm - b.hm;
      });
    },
    prepareScores() {
      const preparedTree = this.preparedTree;
      if (!preparedTree) return;
      const reducer = (acc, item) => {
        if (item.l_passed !== undefined) {
          acc.t_failed += item.t_failed;
          acc.t_passed += item.t_passed;
          acc.l_passed += item.l_passed;
        }

        if (item.children) {
          item.children.reduce(reducer, acc);
        }

        return acc;
      };

      this.scores = [preparedTree].reduce(reducer, {
        t_failed: 0,
        t_passed: 0,
        l_passed: 0,
      });
    },
    clearArrowTimeout() {
      if (this.arrowTimeout) {
        clearTimeout(this.arrowTimeout);

        this.arrowTimeout = null;
      }
    },
    getArrows() {
      const { currentSubTree, currentSubTreeNode, getSubTrees } = this;
      if (currentSubTree === undefined || currentSubTreeNode === undefined)
        return [];
      const item = getSubTrees?.[currentSubTree]?.[currentSubTreeNode];
      if (!item) return [];

      const nextMove = getSubTrees?.[currentSubTree]?.[currentSubTreeNode + 1];
      if (!nextMove) return [];

      return [
        {
          orig: nextMove.move_uci.substring(0, 2),
          dest: nextMove.move_uci.substring(2, 4),
          brush: 'green',
        },
      ];
    },
    prepareArrows() {
      const { mode, gameId, localArrowDelay } = this;
      this.arrows = [];
      this.clearArrowTimeout();

      if (mode === 1 || !gameId) {
        return [];
      }

      const fn = () => {
        this.arrows = this.getArrows();
      };

      this.arrowTimeout = setTimeout(fn, localArrowDelay * 1000);
    },
    prepareCurrentSubtreeSiblings() {
      const { currentSubTree, getSubTrees } = this;
      const herowhite = Number(this.$route.params.herowhite);
      const result = {};
      const blackList = {};
      if (currentSubTree === undefined) return;
      const arr = getSubTrees[currentSubTree];

      const check = (arr) => {
        //скипаем рут
        for (let i = 1; i < arr.length; i++) {
          const { hm, move_lang } = arr[i];

          for (let u = 0; u < getSubTrees.length; u++) {
            if (u === currentSubTree) {
              continue;
            }

            if (
              getSubTrees[u][i]?.move_lang?.[0] === arr[i]?.move_lang?.[0] &&
              !blackList[u]
            ) {
              if (result[u] === undefined) {
                result[u] = {
                  forkIndex: -1,
                  // array: getSubTrees[u]
                };
                check(getSubTrees[u]);
              }

              if (
                arr[i + 1] &&
                getSubTrees[u][i + 1]?.move_lang?.[0] !==
                  arr[i + 1]?.move_lang?.[0]
              ) {
                result[u].forkIndex = i;
              }
            } else {
              blackList[u] = true;
            }
          }

          // if (
          //   (hm % 2 === 0 && herowhite === 0) ||
          //   (hm % 2 !== 0 && herowhite === 1)
          // ) {

          // }
        }
      };
      check(arr);
      return result;
    },
    openedNodesModel() {
      return true;
    },
    getParentCoords(yIndex, column, allItems, columnIndex) {
      const parentSlice = allItems[columnIndex - 1];
      let result = 0;
      if (parentSlice) {
        const parentIndex = parentSlice.findIndex(
          (item) => item.nodethis === column[yIndex]?.nodeparent,
        );
        if (parentIndex === -1) return 0;
        const parentTop = this.getPaddingTop(
          parentIndex,
          parentSlice,
          allItems,
          columnIndex - 1,
        );
        result = parentTop;
      }
      return result;
    },
    getPaddingTop(yIndex, column, allItems, columnIndex) {
      const movesModel = this.movesModel;
      //go back and find the parent, then calculate the top padding for it
      const coordsY = this.getParentCoords(
        yIndex,
        column,
        allItems,
        columnIndex,
      );
      let result = coordsY;

      for (let i = 0; i < yIndex; i++) {
        const neightbourItem = column[i];
        if (neightbourItem.nodeparent !== column[yIndex].nodeparent) {
          continue;
        }
        const neightbourCount = neightbourItem.visibleChildren;
        result +=
          (neightbourCount ? neightbourCount : 1) * (this.blockHeight + 4);
      }

      return result;
    },
    prepareTree(obj) {
      if (!obj) return;
      const { tree, ...rest } = obj;
      const clone = { ...rest };
      clone.children = tree;
      clone.fen = this.selectedMistake?.fen;
      const treeData = {
        ...clone,
        hm: clone.children[0].hm - 1,
      };

      const secator = (item) => {
        item.isEnemyMove = this.isEnemyMove(item.hm)
        item.trainMode = this.mode
        if (item.children.length > 0) {
          for (let i = 0; i < item.children.length; i++) {
            const currentChild = item.children[i];
            const hasGrandChildren = currentChild.children.length > 0;
            if (!hasGrandChildren) {
              if (this.isEnemyMove(currentChild.hm)) {
                item.children.splice(i, 1);
                i--;
              }
            }
          }

          if (item.children.find((c) => c.children.length > 0)) {
            item.children.forEach(secator);
          } else {
            if (this.isEnemyMove(item.hm + 1)) {
              item.children = [];
            }
          }
        }
      };

      secator(treeData);

      this.treeData = treeData;
      const reducer = () => (acc, item) => {
        acc += item.children?.reduce(reducer(), 0) || 0;
        if (!item.children || item.children.length === 0) acc++;
        return acc;
      };

      const addOpenedChildrenCount = (item, nodeparent) => {
        item.nodeparent = nodeparent;
        item.visibleChildren = item.children?.reduce(reducer(), 0) || 0;
        //  item.parent =  cloneDeep(parent)
        item.children?.forEach((child) =>
          addOpenedChildrenCount(child, item.nodethis),
        );
      };
      addOpenedChildrenCount(treeData, treeData.nodethis, 0);

      return treeData;
    },
    clearCounters() {
      this.successCount = 0;
      this.failCount = 0;
    },
    getMoveCoords(str) {
      if (!str) return [];
      return [str.substring(0, 2), str.substring(2, 4)];
    },
    handleUpdateTooltipBoard: throttle(function (val) {
      if (!val) return;
      // console.log('val', val)
      this.smallBoardApi?.setPosition(val.fen);
      const coords = this.getMoveCoords(val.move_uci);

      if (coords.length === 2) {
        if (val.arrowColor) {
          this.smallBoardApi?.setShapes([
            { orig: coords[0], dest: coords[1], brush: val.arrowColor },
          ]);
        }
      }
    }, 200),
    handleUnfinishedClick(item) {
      const herowhite = item.fen.split(' ')[1] === 'w' ? 1 : 0;
      this.gameId = undefined;
      this.$router.push({
        name: 'ExercisePage',
        params: {
          heroid: this.$route.params.heroid,
          posid: item.posid,
          categories: this.$route.params.categories,
          herowhite: herowhite,
        },
      });
      this.unfinishedId = item.id;

      this.originalFen = item.fen;
      this.originalLastMove = null;
    },
    async handleMistakeClick(item, mistake, preventSet) {
      if (this.$refs.board) {
        this.$refs.board.resetBoard();
      }
      if (!preventSet) {
        this.SET_DATA_BY_KEY({ key: 'selectedMistake', value: mistake });
      }

      const train = (this.boardDisabled = false);
      //  console.log(JSON.parse(JSON.stringify(item)))
      this.unfinishedId = undefined;
      this.originalFen = null;
      this.originalLastMove = null;
      this.currentFen = null;
      this.gameId = undefined;
      this.mode = 0;
      this.gameMoves = [];
      this.treeData = null;
      this.currentSubTree = undefined;
      this.currentSubTreeNode = undefined;
      this.preparedTree = null;
      this.trainId = item.id;
      this.$router.push({
        name: 'ExercisePage',
        params: {
          heroid: this.$route.params.heroid,
          posid: item.position_id,
          categories: this.$route.params.categories,
          herowhite: item.herowhite,
        },
      });

      this.originalFen = item.fen;
      this.selectedTraining = item;
      if (item.prevmove) {
        this.originalLastMove = [
          item.prevmove.substring(0, 2),
          item.prevmove.substring(2, 4),
        ];
      } else {
        this.originalLastMove = null;
      }

      const resp = await axios({
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {
          mtd: 'training/load',
          id: this.trainId,
          // mtd: 'leading/new',
          // heroid: this.heroid,
          // posid: this.posid,
          // herowhite: Number(this.$route.params.herowhite),
          // barrier: 250,
          // depthfilter: 20,
        },
      }).catch((e) => console.log(e));

      // axios({
      //       method: 'post',
      //       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      //       url: `${process.env.VUE_APP_DEV_API}/api/`,
      //       data: {
      //         mtd: 'help',
      //       },
      //     })

      if (resp && resp.data) {
        console.time('prepareTree');
        this.preparedTree = this.prepareTree(resp.data);
        console.timeEnd('prepareTree');

        this.prepareScores();
        // this.gameId = resp.data.leading_id;
        // this.gameMoves = resp.data.puzzles;
        // this.currentIndex = 0;
      }
    },
    async startExercise() {
      this.boardDisabled = false;
      await axios({
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {
          mtd: this.mode === 0 ? 'training/startl' : 'training/startt',
          id: this.trainId,
          // mtd: 'leading/new',
          // heroid: this.heroid,
          // posid: this.posid,
          // herowhite: Number(this.$route.params.herowhite),
          // barrier: 250,
          // depthfilter: 20,
        },
      });
      this.gameId = this.trainId;

      const lastMove = this.getSubTrees[0][0]?.prehistory_uci;

      this.originalLastMove = [
        lastMove[lastMove.length - 1].substring(0, 2),
        lastMove[lastMove.length - 1].substring(2, 4),
      ];

      this.possibleSiblings = Object.keys(this.currentSiblingsSubTree);

      this.currentSubTreeNode = 0;
    },
    async makeRecap(start, array) {
      this.$refs.board.resetBoard();
      // this.showDefaultFen = false;
      this.originalFen = start;
      for (let i = 0; i < array.length; i++) {
        await new Promise((res) => setTimeout(res, 500));
        const orig = array[i].substring(0, 2);
        const dest = array[i].substring(2, 4);
        this.$refs.board.handleMove(orig, dest);
      }
    },

    handleSuccessMove(item) {
      // console.log(item)
      const mode = this.mode;

      const clone = cloneDeep(this.scores);

      clone[mode === 0 ? 'l_passed' : 't_passed'] += 1;

      this.scores = clone;

      const getObj = () => {
        if (mode === 0) {
          return {
            l_passed: 1,
          };
        } else {
          return {
            t_passed: 1,
          };
        }
      };
      axios({
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {
          mtd: 'training/update',
          id: this.gameId,
          newvalues: [{ nodethis: item.nodethis, ...getObj() }],
        },
      }).catch((e) => console.log(e));

      const moves = cloneDeep(this.finishedMoves);
      moves[item.nodethis] = true;
      this.finishedMoves = moves;
      const clonedTree = cloneDeep(this.preparedTree);

      const key = mode === 0 ? 'l_passed' : 't_passed';

      const target = deepFind({
        array: [clonedTree],
        value: item?.nodethis,
        key: 'nodethis',
        cb: (n) => n.pos_from_id === item.pos_from_id,
      });

      if (target && target[key] !== undefined) {
        target[key] += 1;
      }

      this.preparedTree = clonedTree;
    },
    handleBadMove(item) {
      // обрабатываем только если тренировка, а не изучение
      // console.log(item);
      if (this.mode === 0) {
        return;
      }
      const clone = cloneDeep(this.scores);

      clone.t_failed += 1;

      this.scores = clone;

      axios({
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {
          mtd: 'training/update',
          id: this.gameId,

          newvalues: [{ nodethis: item.nodethis, t_need: 0, t_failed: 1 }],
        },
      }).catch((e) => console.log(e));
    },
    isEnemyMove(hm) {
      const herowhite = this.herowhite;
      return (
        (hm % 2 === 0 && herowhite === 0) || (hm % 2 !== 0 && herowhite === 1)
      );
    },
    async handleCustomeMove(fen, orig, dest) {
      const {
        currentSubTree,
        currentSubTreeNode,
        getSubTrees,
        possibleSiblings,
        herowhite,
        finishedBranches,
        selectedTraining,
        finishedMoves,
        mode,
      } = this;
      const neededKey = mode === 0 ? 'l_need' : 't_need';
      const completedKey = mode === 0 ? 'l_passed' : 't_passed';

      if (currentSubTreeNode === undefined || currentSubTree === undefined)
        return;
      let currentMove = getSubTrees?.[currentSubTree]?.[currentSubTreeNode + 1];
      const filteredSubtrees = getSubTrees.map((st, ind) => {
        const nextMove = st[currentSubTreeNode + 1];
        if (!nextMove || finishedBranches[ind].finished === true) {
          return null;
        }

        const { hm } = nextMove;
        //если следующий ход - вражий
        if (
          (hm % 2 === 0 && herowhite === 0) ||
          (hm % 2 !== 0 && herowhite === 1)
        ) {
          if (!st[currentSubTreeNode + 2]) {
            //отсекаем ветки, которые кончаются на вражьем полуходе в следующем ходе
            return null;
          }
        }

        return st;
      });
      if (currentSubTreeNode === 0) {
        for (let i = 0; i < filteredSubtrees.length; i++) {
          if (!filteredSubtrees[i]) {
            continue;
          }
          const currentSubtreeMove =
            filteredSubtrees?.[i]?.[currentSubTreeNode + 1];

          if (currentSubtreeMove.move_uci === `${orig}${dest}`) {
            console.log('change branch');
            this.currentSubTree = i;
            currentMove = currentSubtreeMove;
            break;
          }
        }
      }

      if (!currentMove) {
        console.log('line ends');

        this.$refs.board.resetBoard();
        return;
      } else {
        const { move_uci } = currentMove;

        //если угадал
        if (`${orig}${dest}` === move_uci) {
          console.log('good move!');
          this.handleSuccessMove(currentMove);
          await new Promise((res) => setTimeout(res, 500));
          this.showDefaultFen = false;


          const nextMove =
            getSubTrees?.[this.currentSubTree]?.[currentSubTreeNode + 2];

          if (nextMove) {

            const { hm } = nextMove;

            //если следующий ход - вражий
            if (this.isEnemyMove(hm)) {
              if (
                !getSubTrees?.[this.currentSubTree]?.[currentSubTreeNode + 3]
              ) {
                const steelNeeded = getSubTrees?.[this.currentSubTree]?.reduce(
                  (acc, item) => {
                    const target = deepFind({
                      array: [this.preparedTree],
                      value: item?.nodethis,
                      key: 'nodethis',
                      cb: (n) => n.pos_from_id === item.pos_from_id,
                    });

                    if (
                      target[neededKey] > 0 &&
                      target[neededKey] > target[completedKey] &&
                      !this.isEnemyMove(target.hm)
                    ) {
                      acc = true;
                    }
                    return acc;
                  },
                  false,
                );

                if (steelNeeded) {
                  this.currentSubTreeNode = 0;
                  this.$refs.board.resetBoard();

                  this.originalFen =
                    getSubTrees[this.currentSubTree][0]?.fen ||
                    selectedTraining.fen;
                  this.originalLastMove = undefined;
                  return;
                }

                //значит, ветка кончается на вражьем
                console.log('line ends on opp move');
                const clone = cloneDeep(finishedBranches);
                clone[this.currentSubTree].finished = true;

                const otherUnfinishedBranchesIndexes = Object.keys(
                  clone,
                ).filter((key) => clone[key].finished === false);
                this.finishedBranches = clone;
                if (otherUnfinishedBranchesIndexes.length === 0) {
                  this.$refs.board.resetBoard();
                  this.abortExcercise();
                  console.log('exersize finished');
                  await axios({
                    method: 'post',
                    headers: {
                      Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                    url: `${process.env.VUE_APP_DEV_API}/api/`,
                    data: {
                      mtd:
                        this.mode === 0
                          ? 'training/completel'
                          : 'training/completet',
                      id: this.trainId,

                    },
                  }).catch((e) => console.log(e));
                  return;
                } else {

                  this.currentSubTreeNode = 0;
                  this.currentSubTree = Number(
                    otherUnfinishedBranchesIndexes[0],
                  );
                  this.$refs.board.resetBoard();

                  this.originalFen =
                    getSubTrees[this.currentSubTree][
                      this.currentSubTreeNode - 2
                    ]?.fen || selectedTraining.fen;
                  this.originalLastMove = undefined;

                  return;
                }
              }
            }

            this.originalFen = nextMove.fen;
            this.originalLastMove = [
              nextMove.move_uci.substring(0, 2),
              nextMove.move_uci.substring(2, 4),
            ];
          } else {
            console.log('line ends on hero move');

            const steelNeeded = getSubTrees?.[this.currentSubTree].reduce(
              (acc, item) => {
                const target = deepFind({
                  array: [this.preparedTree],
                  value: item?.nodethis,
                  key: 'nodethis',
                  cb: (n) => n.pos_from_id === item.pos_from_id,
                });

                if (
                  target[neededKey] > 0 &&
                  target[neededKey] > target[completedKey] &&
                  !this.isEnemyMove(target.hm)
                ) {
                  acc = true;
                }
                return acc;
              },
              false,
            );

            if (steelNeeded) {
              this.currentSubTreeNode = 0;
              this.$refs.board.resetBoard();

              this.originalFen =
                getSubTrees[this.currentSubTree][0]?.fen ||
                selectedTraining.fen;
              this.originalLastMove = undefined;
              return;
            }

            const clone = cloneDeep(finishedBranches);
            clone[this.currentSubTree].finished = true;

            const otherUnfinishedBranchesIndexes = Object.keys(clone).filter(
              (key) => clone[key].finished === false,
            );
            this.finishedBranches = clone;
            if (otherUnfinishedBranchesIndexes.length === 0) {
              this.$refs.board.resetBoard();
              console.log('exersize finished');
              this.abortExcercise();
              return;
            } else {

              this.currentSubTreeNode = 0;
              this.currentSubTree = Number(otherUnfinishedBranchesIndexes[0]);

              this.$refs.board.resetBoard();

              this.originalFen =
                getSubTrees[this.currentSubTree][this.currentSubTreeNode - 2]
                  ?.fen || selectedTraining.fen;
              this.originalLastMove = undefined;

              return;
            }
          }
          this.currentSubTreeNode += 2;
          return;
        } else {
          for (let siblingIndex of possibleSiblings) {
            const sibling = getSubTrees[siblingIndex];
            if (sibling.forkIndex === currentSubTreeNode) {
              const currentSiblingMove =
                getSubTrees[siblingIndex][currentSubTreeNode + 1];

              if (currentSiblingMove) {
                if (`${orig}${dest}` === currentSiblingMove.move_uci) {
                  this.handleSuccessMove(currentMove);
                  console.log('good move! other branch');
                  // переключаемся на другую ветку, засчитываем ход, в список соседей попадает текущая ветка

                  return;
                }
              }
            }
          }

          //если ход плохой

          if (this.mode === 0) {
            console.log('bad move!');
            this.handleBadMove(currentMove);
            //        axios({
            //   method: 'post',
            //   headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            //   url: `${process.env.VUE_APP_DEV_API}/api/`,
            //   data: {
            //     mtd: 'training/update',
            //     id: this.gameId,
            //     l_passed: 1,
            //     l_need: 0
            //   },
            // }).catch((e) => console.log(e));

            this.$refs.board.resetBoard();
          } else {
            console.log('bad move, but go anyway!');
            this.handleBadMove(currentMove);
            await new Promise((res) => setTimeout(res, 500));
            this.showDefaultFen = false;

            const nextMove =
              getSubTrees?.[this.currentSubTree]?.[currentSubTreeNode + 2];

            // console.log('nextMove', nextMove);
            if (nextMove) {
    
              const { hm } = nextMove;

              //если следующий ход - вражий
              if (this.isEnemyMove(hm)) {
                if (
                  !getSubTrees?.[this.currentSubTree]?.[currentSubTreeNode + 3]
                ) {
                  //значит, ветка кончается на вражьем
                  console.log('line ends on opp move');
                  const clone = cloneDeep(finishedBranches);
                  clone[this.currentSubTree].finished = true;

                  const otherUnfinishedBranchesIndexes = Object.keys(
                    clone,
                  ).filter((key) => clone[key].finished === false);
                  this.finishedBranches = clone;
                  if (otherUnfinishedBranchesIndexes.length === 0) {
                    this.$refs.board.resetBoard();
                    this.abortExcercise();
                    console.log('exersize finished');
                    await axios({
                      method: 'post',
                      headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                          'token',
                        )}`,
                      },
                      url: `${process.env.VUE_APP_DEV_API}/api/`,
                      data: {
                        mtd:
                          this.mode === 0
                            ? 'training/completel'
                            : 'training/completet',
                        id: this.trainId,
       
                      },
                    }).catch((e) => console.log(e));
                    return;
                  } else {
                    this.currentSubTreeNode = 0;
                    this.currentSubTree = Number(
                      otherUnfinishedBranchesIndexes[0],
                    );

                    this.$refs.board.resetBoard();

                    this.originalFen = selectedTraining.fen;
                    this.originalLastMove = undefined;

                    return;
                  }
                }
              }

              this.originalFen = nextMove.fen;
              this.originalLastMove = [
                nextMove.move_uci.substring(0, 2),
                nextMove.move_uci.substring(2, 4),
              ];
            } else {
              console.log('line ends on hero move');

              const clone = cloneDeep(finishedBranches);
              clone[this.currentSubTree].finished = true;

              const otherUnfinishedBranchesIndexes = Object.keys(clone).filter(
                (key) => clone[key].finished === false,
              );
              this.finishedBranches = clone;
              if (otherUnfinishedBranchesIndexes.length === 0) {
                this.$refs.board.resetBoard();
                console.log('exersize finished');
                this.abortExcercise();
                return;
              } else {
             

                this.currentSubTreeNode = 0;
                this.currentSubTree = Number(otherUnfinishedBranchesIndexes[0]);

                this.$refs.board.resetBoard();

                this.originalFen =
                  getSubTrees[this.currentSubTree][this.currentSubTreeNode - 2]
                    ?.fen || selectedTraining.fen;
                this.originalLastMove = undefined;
                return;
              }
            }
            this.currentSubTreeNode += 2;
            return;

            // this.$refs.board.resetBoard();
          }
        }
      }
    },
    async abortExcercise() {
      this.boardDisabled = false;
      await axios({
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {
          mtd: this.mode === 0 ? 'training/abortl' : 'training/abortt',
          id: this.trainId,
        },
      }).catch((e) => console.log(e));

      this.gameId = undefined;
      this.gameMoves = [];
      this.currentIndex = undefined;
      this.currentFen = undefined;
      this.gifUrl = undefined;
      this.currentSubTree = 0;
      this.currentSubTreeNode = 0;
      this.defaultFen = this.selectedTraining.fen;
      this.showDefaultFen = true;
      this.finishedMoves = {};
      this.$refs.board.resetBoard();
    },
    gotToAnalysis() {
      this.$router.push({
        name: 'Analysis',
        params: {
          heroid: this.$route.params.heroid,
          categories: this.$route.params.categories,
          posid: this.selectedMistake.position_id,
        },
      });
    },
  },
  watch: {
    selectedMistake: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val) {
          if (!oldVal || val.position_id !== oldVal.position_id) {
            const exercisesList = this.exercisesList;

            const occur = exercisesList.find(
              (ex) =>
                ex.position_id === val.position_id &&
                ex.herowhite === val.herowhite,
            );

            if (occur) {
              this.handleMistakeClick(occur, val, true);
            }
          }
        }
      },
    },
    tooltip: {
      deep: true,
      handler: async function (val) {
        if (val && val.fen) {
          await this.$nextTick();
          this.handleUpdateTooltipBoard(val);
        }
      },
    },
    userPreferences: {
      immediate: true,
      handler: function (val) {
        if (val) {
          if (!this.heroesList) {
            this.getHeroesList();
          }
          this.getMistakes({
            heroid: this.heroid,
            herocats: this.heroCategories,
            position_id: this.$route.params.posid,
          });
        }
      },
    },
    mode: {
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.gameId = undefined;
          this.gameMoves = [];
          this.currentIndex = undefined;
          this.currentFen = undefined;
          this.gifUrl = undefined;
        }
      },
    },
    exercisesList: {
      deep: true,
      handler: function (val, oldVal) {
        if (val && val[0] && !oldVal) {
          this.handleMistakeClick(val[0]);
        }
      },
    },
    currentSubTree: {
      handler: function (val) {
        if (val !== undefined && this.getSubTrees) {
          this.currentSiblingsSubTree = this.prepareCurrentSubtreeSiblings();
        }
      },
    },
    arrowListener: {
      handler: function () {
        const newArrows = this.getArrows();
        const oldArrows = this.arrows;

        if (isEqual(newArrows, oldArrows)) {
          return;
        } else {
          this.prepareArrows();
        }
      },
    },
    currentSubTreeNode: {
      handler: async function (val) {
        if (val !== undefined && this.gameId !== undefined) {
          const getSubTrees = this.getSubTrees;
          const currentSubTree = this.currentSubTree;

          if (
            !getSubTrees ||
            currentSubTree === null ||
            currentSubTree === undefined
          ) {
            return;
          }

          const mode = this.mode;
          const nodeFromSubTree = getSubTrees[currentSubTree][val + 1];
          if (!nodeFromSubTree) {
            return;
          }

          const node = deepFind({
            array: [this.preparedTree],
            value: nodeFromSubTree?.nodethis,
            key: 'nodethis',
            cb: (n) => n.pos_from_id === nodeFromSubTree.pos_from_id,
          });
          const neededKey = mode === 0 ? 'l_need' : 't_need';
          const completedKey = mode === 0 ? 'l_passed' : 't_passed';

          if (node[neededKey] > 0 && node[neededKey] < node[completedKey]) {
            const nextMove = getSubTrees[currentSubTree][val + 2];
            // const moveAfter = getSubTrees[currentSubTree][val + 3];
            // const moveAfterAfter = getSubTrees[currentSubTree][val + 4];

      

            if (nextMove) {
              this.boardDisabled = true;
              this.clearArrowTimeout();
              const delay = Number(this.autoDelay) * 1000;
              await new Promise((res) => setTimeout(res, delay));
              this.$refs.board?.resetBoard();
              this.originalFen = getSubTrees[currentSubTree][val + 1].fen;
              await new Promise((res) => setTimeout(res, delay));
              this.$refs.board?.resetBoard();
              this.originalFen = getSubTrees[currentSubTree][val + 2].fen;
              this.boardDisabled = false;
              this.currentSubTreeNode += 2;
            }
          }
        }
      },
    },
    treeData: {
      handler: function (val) {
        console.time('prepareSubTree');
        const subTrees = this.prepareSubTrees(val)
        this.getSubTrees = subTrees;
        if(!subTrees) return
        this.prepareScores();
        const keys = Object.keys(subTrees);
        const res = {};
        const needKey = this.mode === 0 ? 'l_need' : 't_need';
        const completedKey = this.mode === 0 ? 'l_passed' : 't_passed';

        let once = false;
        for (let i = 0; i < keys.length; i++) {
          const allFinished = this.getSubTrees[keys[i]].reduce(
            (acc, item, index) => {
              if (
                index === this.getSubTrees[keys[i]].length - 1 &&
                this.isEnemyMove(item.hm)
              ) {
                return acc;
              }
              if (
                item[needKey] > 0 &&
                item[needKey] > item[completedKey] &&
                !this.isEnemyMove(item.hm)
              ) {
                acc = false;
              }
              return acc;
            },
            true,
          );
          console.log('allFinished', allFinished);
          res[i] = {
            finished: allFinished,
            lastIndex: 0,
          };

          if (!once && !allFinished) {
            this.currentSubTree = i;
            once = true;
          }
        }

        this.finishedBranches = res;
        console.timeEnd('prepareSubTree');
      },
    },
  },
  mounted() {
    this.getExercisesList({ heroid: Number(this.heroid) });
    const selectedMistake = this.selectedMistake;
    const occur = this.exercisesList.find(
      (ex) =>
        ex.position_id === selectedMistake?.position_id &&
        ex.herowhite === selectedMistake?.herowhite,
    );

    if (occur) {
      this.handleMistakeClick(occur, selectedMistake, true);
    }

    const arrowDelay = localStorage.getItem('arrowDelay');

    if (arrowDelay && !Number.isNaN(Number(arrowDelay))) {
      this.localArrowDelay = Number(arrowDelay);
    }

    const autoDelay = localStorage.getItem('autoDelay');

    if (autoDelay && !Number.isNaN(Number(autoDelay))) {
      this.autoDelay = autoDelay;
    }
  },
  provide() {
    return {
      openedNodesArr: () => null,
    };
  },
};
</script>

<style>
.container {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px; /* Отступы для красоты */
}

.sidebar {
  /* Выравнивание по левому краю */
  width: 240px; /* Фиксированная ширина для колонки */
}
</style>
