<template>
  <div
    class="d-flex justify-space-evenly px-6 pt-4 bg-darkGrey"
    style="gap: 30px"
  >
  <TooltipWithBoard v-if="isTooltipShown" :tooltip=tooltip>
        <div class="d-flex ma-1">
          <div style="width: 128px" class="regular-14">
            <div>Изменение оценки</div>
            <div>Количество ошибок</div>
            <div>Суммарные потери</div>
          </div>
          <v-spacer />
          <div class="semibold-14" style="width: 30px">
            <div>{{ tooltip.item && tooltip.item.eval }}</div>
            <div>{{ tooltip.item && tooltip.item.total_count }}</div>
            <div>{{ tooltip.item && tooltip.item.total_shortage }}</div>
          </div>
        </div>
  </TooltipWithBoard>

    <div
      :style="{
        height: '80vh',
        width: '346px',
      }"
    >
      <div style="width: 100%">
        <AnalysisFilters>
          <template v-slot:table>
            <MistakesList
              v-if="viewTypeId == 0"
              @mistakeClick="handleMistakeClick"
              :toggleTooltip="toggleTooltip"
              :getBg="getBg"
              mode="preAnalysisCompact"
              :customHeight="278"
            />
          </template>
        </AnalysisFilters>
      </div>
    </div>
    <div style="flex: auto">
      <!-- <div v-if="treeNodes">
        <div v-for="node in treeNodes" :key="node.position_id">
          {{ node.position_id }} - {{ node.ancestor }}
        </div>
      </div> -->
      <!-- {{ treeNodes }} -->
      <div>
        <button-selector label="" :options="viewOptions" v-model="viewModel" />
      </div>
      <div
        :style="` overflow: auto;
          width: auto;
          height: ${showInfo ? '348px' : '570px'};
          padding-top: 10px;`"
        ref="treeContainer"
        @scroll="onScroll"
      >
        <!-- calc(90vh - 17px) -->
        <div>
          <svg-tree
            v-if="treeNodes && viewTypeId == 0"
            :items="getFlatArray"
            :getPaddingTop="getPaddingTop"
            :loadChildren="() => {}"
            :blockHeight="blockHeight"
            :blockWidth="blockWidth"
            :getParentCoords="getParentCoords"
            :setTooltip="toggleTooltip"
            :handleSelectOpening="handleBlockClick"
            :selectedOpening="selectedBlock"
            :mode="1"
            :movesModel="0"
            :handleArrowPress="() => {}"
            :rootColor="sideOptionsFilter === 2 ? 'w' : 'b'"
            :simple="true"
            :getBg="getBg"
            :size="500"
            :customPadding="12"
            :hideMoveZones="true"
            :key="heroid + treeKey"
            :blockGap="blockWidth + 32 + 16"
            ref="tree"
            :showStartFenBoard="true"
            :variant="'preAnalysis'"
          />
        </div>
        <MistakesList
          v-if="viewTypeId == 1"
          @mistakeClick="handleMistakeClick"
          :toggleTooltip="toggleTooltip"
          :getBg="getBg"
          mode="preAnalisysFull"
          :customHeight="500"
        />

        <!-- :height="blockHeight * 3" -->
      </div>
    </div>
    <div style="width: 346px">
      <v-card>
        <div>
          <div
            class="d-flex align-center"
            style="
              color: #f4f4f4;
              font-size: 12px;
              cursor: pointer;
              margin-left: 24px;
              height: 48px;
            "
            @click="copyFenToClipboard"
          >
            <div class="mx-2">FEN</div>

            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.66732 13.3334H5.00065C4.08018 13.3334 3.33398 12.5872 3.33398 11.6667V5.00004C3.33398 4.07957 4.08018 3.33337 5.00065 3.33337H11.6673C12.5878 3.33337 13.334 4.07957 13.334 5.00004V6.66671M8.33398 16.6667H15.0007C15.9211 16.6667 16.6673 15.9205 16.6673 15V8.33337C16.6673 7.4129 15.9211 6.66671 15.0007 6.66671H8.33398C7.41351 6.66671 6.66732 7.4129 6.66732 8.33337V15C6.66732 15.9205 7.41351 16.6667 8.33398 16.6667Z"
                stroke="#797C84"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div
          :style="{
            height: Math.floor(sizes.vw * 0.15) + 40 + 'px',
            width: Math.floor(sizes.vw * 0.15) + 'px',
            paddingLeft: '24px',
          }"
        >
          <advanced-board
            :key="sideOptionsFilter + treeKey"
            :size="Math.floor(sizes.vw * 0.15) + 'px'"
            :config="{
              viewOnly: true,
              fen: selectedMistake && selectedMistake.fen,
              orientation: sideOptionsFilter === 2 ? 'white' : 'black',
              drawable: {
                brushes: selectedMistakeBrushes,
              },
              lastMove: getLastMove,
            }"
            :arrows="selectedMistakeArrows"
            :allMoves="[]"
            :currentMoveIndex="undefined"
            :movePieces="false"
            ref="bigBoard"
            @customMove="() => {}"
          />
        </div>

        <div
          style="height: 48px; padding-left: 24px"
          class="d-flex align-center"
        >
         <v-icon
            v-if="selectedMistake"
            class="mr-4"
            size="small"
            @click="
              (e) => {
                e.stopPropagation();
                toggleFav(selectedMistake);
              }
            "
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-if="selectedMistake && selectedMistake.labels && selectedMistake.labels.indexOf(2) > -1"
            >
             <path d="M4.31802 11.682C2.56066 9.92462 2.56066 7.07538 4.31802 5.31802C6.07538 3.56066 8.92462 3.56066 10.682 5.31802L12.0001 6.63609L13.318 5.31802C15.0754 3.56066 17.9246 3.56066 19.682 5.31802C21.4393 7.07538 21.4393 9.92462 19.682 11.682L12.0001 19.364L4.31802 11.682Z" fill="#9A9A9A" stroke="#9A9A9A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-else
            >
              <path d="M4.31802 5.31802C2.56066 7.07538 2.56066 9.92462 4.31802 11.682L12.0001 19.364L19.682 11.682C21.4393 9.92462 21.4393 7.07538 19.682 5.31802C17.9246 3.56066 15.0754 3.56066 13.318 5.31802L12.0001 6.63609L10.682 5.31802C8.92462 3.56066 6.07538 3.56066 4.31802 5.31802Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </v-icon>
          <v-icon
            v-if="selectedMistake"
            size="small"
            @click="
              (e) => {
                e.stopPropagation();
                handleEyeClick(selectedMistake);
              }
            "
          >
            {{ getEyeIcon(selectedMistake) }}
          </v-icon>
          <v-icon
            v-if="selectedMistake"
            class="mx-4"
            size="small"
            @click="
              (e) => {
                e.stopPropagation();
                handleToggleExercise(selectedMistake);
              }
            "
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-if="isInExercies(selectedMistake)"
            >
              <path
                d="M11.6999 2.80505C11.7946 2.76375 11.8967 2.74243 11.9999 2.74243C12.1032 2.74243 12.2053 2.76375 12.2999 2.80505C15.998 4.42145 19.5255 6.40294 22.8299 8.72005C22.9464 8.80171 23.0372 8.91481 23.0918 9.04614C23.1464 9.17748 23.1626 9.32163 23.1384 9.46179C23.1141 9.60195 23.0506 9.73233 22.9551 9.83773C22.8596 9.94312 22.736 10.0192 22.5989 10.057C19.1683 11.0045 15.8488 12.316 12.6969 13.969L12.6939 13.971C12.5799 14.031 12.4669 14.09 12.3539 14.151C12.2452 14.2092 12.1238 14.2396 12.0004 14.2396C11.8771 14.2396 11.7557 14.2092 11.6469 14.151C10.2951 13.4296 8.91135 12.7696 7.49994 12.173V11.949C7.49994 11.818 7.56694 11.701 7.67194 11.638C9.18131 10.7234 10.7341 9.88237 12.3249 9.11805C12.5042 9.03185 12.6419 8.87796 12.7078 8.69024C12.7736 8.50252 12.7621 8.29633 12.6759 8.11704C12.5897 7.93776 12.4359 7.80006 12.2481 7.73423C12.0604 7.66841 11.8542 7.67985 11.6749 7.76605C10.0407 8.55133 8.44552 9.41534 6.89494 10.355C6.67434 10.4889 6.48403 10.6672 6.33614 10.8786C6.18824 11.09 6.08601 11.3299 6.03594 11.583C4.51715 10.9986 2.97063 10.489 1.40194 10.056C1.26483 10.0182 1.14132 9.94212 1.04581 9.83673C0.9503 9.73133 0.886728 9.60095 0.862512 9.46079C0.838297 9.32063 0.854435 9.17648 0.909045 9.04514C0.963655 8.91381 1.05449 8.80071 1.17094 8.71905C4.47513 6.40236 8.00227 4.42121 11.6999 2.80505Z"
                fill="#F4F4F4"
              />
              <path
                d="M13.0607 15.4729C15.517 14.1607 18.0818 13.0626 20.7267 12.1909C20.8607 13.6049 20.9467 15.0339 20.9817 16.4749C20.9856 16.626 20.9438 16.7747 20.8617 16.9016C20.7796 17.0284 20.6611 17.1276 20.5217 17.1859C17.687 18.3699 14.9728 19.8239 12.4167 21.5279C12.2935 21.6101 12.1488 21.6539 12.0007 21.6539C11.8527 21.6539 11.7079 21.6101 11.5847 21.5279C9.02898 19.824 6.31513 18.3699 3.48074 17.1859C3.34134 17.1278 3.22275 17.0289 3.1405 16.9022C3.05824 16.7755 3.01615 16.6269 3.01974 16.4759C3.05474 15.0339 3.14074 13.6059 3.27474 12.1899C4.19574 12.4939 5.10474 12.8239 6.00074 13.1799V14.4499C5.78468 14.5744 5.60306 14.7508 5.47231 14.9632C5.34156 15.1755 5.26581 15.4171 5.25191 15.666C5.23801 15.915 5.28641 16.1635 5.39271 16.3891C5.49901 16.6147 5.65987 16.8102 5.86074 16.9579C5.77074 17.3379 5.63874 17.7109 5.46374 18.0679C5.91574 18.2809 6.36474 18.5019 6.80974 18.7279C7.06058 18.2177 7.24573 17.6777 7.36074 17.1209C7.61363 17.0085 7.83048 16.8283 7.98729 16.6003C8.14411 16.3722 8.23478 16.1052 8.24927 15.8289C8.26376 15.5525 8.20151 15.2775 8.0694 15.0343C7.93729 14.7911 7.74048 14.5892 7.50074 14.4509V13.8059C8.66885 14.3162 9.81641 14.8723 10.9407 15.4729C11.2669 15.6471 11.631 15.7383 12.0007 15.7383C12.3705 15.7383 12.7346 15.6471 13.0607 15.4729Z"
                fill="#F4F4F4"
              />
              <path
                d="M4.46201 19.462C4.88201 19.043 5.21501 18.572 5.46201 18.067C5.91501 18.281 6.36401 18.502 6.80901 18.729C6.48181 19.3936 6.04732 19.9997 5.52301 20.523C5.38084 20.6555 5.19279 20.7276 4.99849 20.7242C4.80419 20.7208 4.6188 20.6421 4.48139 20.5046C4.34398 20.3672 4.26526 20.1818 4.26184 19.9875C4.25841 19.7932 4.33053 19.6052 4.46301 19.463L4.46201 19.462Z"
                fill="#F4F4F4"
              />
            </svg>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-else
            >
              <path
                d="M4.25959 10.147C3.98453 12.2526 3.82064 14.3712 3.76859 16.494C6.64739 17.6965 9.40377 19.1733 11.9996 20.904C14.5957 19.1733 17.3524 17.6965 20.2316 16.494C20.1795 14.3712 20.0156 12.2526 19.7406 10.147M19.7406 10.147C20.6156 9.85301 21.5026 9.58101 22.3986 9.33301C19.1352 7.04526 15.6516 5.0889 11.9996 3.49301C8.34758 5.08924 4.86398 7.04593 1.60059 9.33401C2.49386 9.58064 3.38019 9.85174 4.25859 10.147C6.92721 11.0442 9.51649 12.1621 11.9996 13.489C14.4823 12.1621 17.0723 11.0442 19.7406 10.147ZM6.74959 15V11.325C8.44632 10.2703 10.1989 9.30828 11.9996 8.44301M4.99259 19.993C5.55056 19.4364 5.99302 18.775 6.29455 18.0469C6.59608 17.3187 6.75072 16.5381 6.74959 15.75V14.25"
                stroke="#9A9A9A"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-icon>
         
        </div>
      </v-card>
      <v-card class="mt-1">
        <div :style="{ width: '100%' }">
          <div
            class="d-flex align-center justify-center semibold-14"
            style="gap: 1em"
          >
            <v-btn
              v-if="selectedMistake"
              size="small"
              color="blue"
              @click="goToAnalysis"
              :disabled="!selectedMistake"
              >{{ $t('opening1.toTree') }}</v-btn
            >
            <v-btn
              v-if="selectedMistake"
              color="violet"
              size="small"
              @click="gotToExsercise(selectedMistake)"
              :disabled="!selectedMistake"
              >{{ $t('opening1.toTraining') }}</v-btn
            >
          </div>
        </div>
      </v-card>

      <v-card class="mt-1">
        <v-table v-if="selectedMistake" density="compact" class="custom-table">
          <template v-slot:default>
            <tbody>
              <tr style="color: #9a9a9a" class="semibold-14">
                <td width="25%">{{ $t('statistics.move') }}</td>
                <td width="25%">{{ $t('statistics.eval') }}</td>
                <td width="25%">{{ $t('statistics.numberOfMoveMade') }}</td>
                <td width="25%">{{ $t('opening1.mistakesTotalShortage') }}</td>
              </tr>
              <tr
                v-for="(child, index) in selectedMistakeChildren"
                :key="'tr-' + child.nodethis"
                :style="{ color: index === 0 ? '#06D295' : '#9A9A9A' }"
                class="regular-14"
              >
                <td width="25%">{{ getMoveText(child) }}</td>
                <td width="25%">
                  {{ child.ev_text }}
                </td>
                <td width="25%">
                  {{ index > 0 ? child.move_count_hero : '' }}
                </td>
                <td width="25%">
                  {{ index > 0 ? child.waste * child.move_count_hero / 100 : '' }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import AdvancedBoard from '@/components/AdvancedBoard';
import SvgTree from '@/components/SvgTree';
// import CanvasTree from '@/components/CanvasTree.vue';
import AnalysisFilters from '@/components/AnalysisFilters';
import MistakesList from '@/components/MistakesList';
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
import { Chess } from 'chess.js';
import deepObjectSearch from '@/helpers/deepObjectSearch';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import mistakeSorter from '@/helpers/mistakeSortHelper';
import TooltipWithBoard from '@/components/TooltipWithBoard.vue';

import { useTooltip } from '@/shared/composables/';

const TOOLTIP_HEIGHT = 292;

export default {
  data: () => ({
    showDialog: false,
    showInfo: false,
    currentFen: null,
    searchModel: '',
    treeNodes: null,
    blockHeight: 36,
    blockWidth: 79,
    treeKey: Math.random(),
    maxTotalShortage: 0,
    svgTree: true,
    scrollTop: 0,
    visibleItems: [],
    selectedMistakeArrows: [],
    selectedMistakeBrushes: {},
    selectedMistakeChildren: [],
  }),
  setup () {
    const { toggleTooltip, isTooltipShown, tooltip } = useTooltip(TOOLTIP_HEIGHT);
    return {
      toggleTooltip,
      isTooltipShown,
      tooltip,
    };
  },
  components: {
    AnalysisFilters,
    AdvancedBoard,
    SvgTree,
    MistakesList,
    TooltipWithBoard,
    // CanvasTree,
  },
  computed: {
    ...mapGetters('data', ['getMistakesOpenings', 'getOpeningsVariants']),
    ...mapState(['isAuthenticated', 'sizes']),
    ...mapState('data', [
      'sideOptionsFilter',
      'openingsTree',
      'selectedMistake',
      'compareFilters',
      'selectedOpening',
      'heroesList',
      'currentSide',
      'parsedFen',
      'mistakes',
      'sideOptionsFilter',
      'studiedOptionsFilter',
      'watchListOptionsFilter',
      'sortByOptionsFilter',
      'openingOptionsFilter',
      'hmRange',
      'deltaRange',
      'positionStatusFilter',
      'exercisesList',
    ]),
    ...mapState('ui', ['userPreferences', 'viewTypeId']),
    viewOptions() {
      return [
        { id: 0, text: this.$t('opening1.tree') },
        { id: 1, text: this.$t('opening1.list') },
      ];
    },
    viewModel: {
      get() {
        // console.log('viewTypeId', this.viewTypeId);
        return this.viewTypeId;
      },
      set(val) {
        this.$store.commit('ui/SET_DATA_BY_KEY', {
          key: 'viewTypeId',
          value: val,
        });
      },
    },
    // selectedMistakeBrushes() {
    //   const selectedMistake = this.selectedMistake;
    //   return {};
    //   //return this.getBrush(selectedMistake);
    // },
    // selectedMistakeArrows() {
    //   const selectedMistake = this.selectedMistake;
    //   return [];
    //   //return this.getArrows(selectedMistake);
    // },
    selectedBlock() {
      const selectedMistake = this.selectedMistake;
      if (!selectedMistake) {
        return null;
      }
      return { nodethis: selectedMistake.position_id };
    },
    getFlatArray() {
      const tree = this.treeNodes;

      if (!tree) return [];
      const reducer =
        (iteration, parentIndex = 0) =>
        (acc, item, index) => {
          if (!acc[iteration]) {
            acc[iteration] = [];
          }

          acc[iteration].push({
            ...item,
            parentIndex: parentIndex,
            visible: true,
          });

          if (!item.children) {
            item.children = [];
          } else {
            item.children.reduce(reducer(iteration + 1, index), acc);
          }

          return acc;
        };

      const result = tree.children.reduce(reducer(1, 0), [[tree]]);

      return result;
    },
    getLastMove() {
      return [];
    },
    heroid() {
      return this.$route.params.heroid;
    },
    heroCategories() {
      return this.$route.params.categories;
    },
    getBg() {
      const maxTotalShortage = this.maxTotalShortage;
      return (nodethis, hm, item) => {
        const minimalRed = 75;
        const maximalRed = 255;
        const totalShortage = item.total_shortage;
        const minVal = localStorage.getItem('minTSVal') || 600;

        if (item.mdegree === 0) {
          return 'rgb(125,185,125)';
        }

        const redNum =
          minimalRed +
          Math.round(
            (maximalRed - minimalRed) *
              Math.log2(
                1 +
                  (Math.max(Number(minVal), totalShortage) - 30) /
                    (Math.max(Number(minVal), maxTotalShortage) - 30),
              ),
          );

        // const evaluation = (
        //   Math.round(item.total_shortage / (item.total_count || 1)) / 100
        // ).toFixed(2);

        // function getColor(value) {
        //   // value = Math.max(0, Math.min(1, value));
        //   // const r = Math.round(55 + value * (255 - 55));
        //   const r = value;

        //   return `rgb(${r}, ${g}, ${b})`;
        // }
        const g = 55;
        const b = 55;
        return `rgb(${redNum}, ${g}, ${b})`;
        // if (selectedNodes.findIndex((i) => i.nodethis === nodethis) > -1) {
        //   return 'rgba(255,155,155,1)';
        // }
        // if (
        //   getFullSelectedPath.findIndex((i) => i.nodethis === nodethis) > -1
        // ) {
        //   return 'rgba(255,195,195,0.7)';
        // }
        // //если вражьи ходы
        // if (this.isEnemyMove(hm)) {
        //   return 'rgba(	152, 152, 152, 1)';
        // }

        // if (
        //   getSubTrees?.[currentSubTree].find((i) => i?.nodethis === nodethis)
        // ) {
        //   return 'rgba(	52, 170, 220, .9)';
        // }

        // return 'rgba(	52, 170, 220, .7)';
      };
    },
  },
  methods: {
    ...mapMutations('data', ['SET_DATA_BY_KEY']),
    ...mapActions('data', [
      'getOpenings',
      'loadChildren',
      'getMistakesOpening',
      'modifyPositionLabel',
      'getHeroesList',
      'addToExercises',
      'removeFromExercises',
      'getExercisesList',
      'getMistakes',
      'getHeroVariants',
    ]),
    ...mapActions(['logOutUser']),
    getMoveText(item) {
      if (!this.userPreferences) return;

      const { lang_pieces } = this.userPreferences;
      const move_lang = item?.move_lang || [];
      if (!move_lang) {
        return 'Root';
      }
      if (item.hideText === true) return '';
      if (move_lang && move_lang[lang_pieces]) {
        return move_lang[lang_pieces];
      }
      return move_lang[0] || '';
    },
    copyFenToClipboard() {
      let fen = this.selectedMistake?.fen;

      function copyToClipboard(text) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            console.log('Text copied to clipboard');
          })
          .catch((err) => {
            console.error('Error copying text: ', err);
          });
      }
      if (fen) {
        copyToClipboard(fen);
      }
    },
    onScroll() {
      this.scrollTop = this.$refs.treeContainer.scrollTop;
      this.updateVisibleItems(this.getFlatArray);
    },
    isInExercies(mistake) {
      const exercisesList = this.exercisesList;

      if (!exercisesList || exercisesList.length === 0) {
        return false;
      }

      return exercisesList.find(
        (i) =>
          i.position_id === mistake.position_id &&
          i.herowhite === mistake.herowhite,
      )
        ? true
        : false;
    },
    async updateVisibleItems(items) {
      return;
      // await this.$nextTick()
      // const top = this.scrollTop;
      // const bottom = this.scrollTop + this.$refs.treeContainer.clientHeight;

      // const result = []

      // for (let colIndex = 0; colIndex < items.length; colIndex++) {
      //   const column = items[colIndex];
      //   result[colIndex] = []
      //   for (let rowIndex = 0; rowIndex < column.length; rowIndex++) {

      //     const item = column[rowIndex];

      //     const y = this.getPaddingTop(rowIndex, column, items, colIndex);

      //     if(y <= bottom + 200) {
      //       result[colIndex][rowIndex] = item
      //     }
      //   }
      // }

      // this.visibleItems = result
    },
    getEyeIcon(item) {
      const labelsArray = this.$parseArray(item?.labels);
      if (!labelsArray || labelsArray.indexOf(2) > -1) {
        return 'mdi-eye';
      }
      return 'mdi-eye-off';
    },
    handleEyeClick(item) {
      const labelsArray = this.$parseArray(item.labels);

      const heroid = this.heroid;
      if (labelsArray.indexOf(2) > -1) {
        this.modifyPositionLabel({
          opening: item,
          scenario: 'toIgnore',
          heroid,
          herocats: this.heroCategories,
        });
        return;
      }

      if (labelsArray.indexOf(3) > -1) {
        this.modifyPositionLabel({
          opening: item,
          scenario: 'toDefault',
          heroid,
          herocats: this.heroCategories,
        });
        return;
      }

      this.modifyPositionLabel({
        opening: item,
        scenario: 'toWatchlist',
        heroid,
        herocats: this.heroCategories,
      });
    },
    toggleFav(item) {
    
      const inFav = item.labels?.indexOf(2) > -1;
      const heroid = this.heroid;
      if (!inFav) {
        this.modifyPositionLabel({
          opening: item,
          scenario: 'toWatchlist',
          heroid,
          herocats: this.heroCategories,
        });
      } else {
        this.modifyPositionLabel({
          opening: item,
          scenario: 'toDefault',
          heroid,
          herocats: this.heroCategories,
        });
      }
    },
    getMoveCoords(str) {
      if (!str) return [];
      return [str.substring(0, 2), str.substring(2, 4)];
    },
    getArrows(node) {
      if (!node) return [];

      const getFlatArray = this.getFlatArray || [];

      const target = this.$deepFind({
        array: getFlatArray,
        key: 'position_id',
        value: node?.position_id,
      });
      if (!target) return [];

      const children = target.children;

      if (!children || children.length === 0) {
        return [];
      }

      const arrows = children.reduce((acc, child) => {
        const coords = this.getMoveCoords(child.prevmove);
        if (!coords?.[0] || !coords?.[1]) return acc;
        acc.push({
          orig: coords[0],
          dest: coords[1],
          brush:
            child.b_arrow_color ||
            this.getBg(child.position_id, child.hm, child),
        });
        return acc;
      }, []);

      return arrows;
    },
    getBrush(node) {
      const vw = this.$store.state.sizes.vw;
      const tileSize = ((vw / 100) * 18) / 8;

      if (!node) return {};
      const getFlatArray = this.getFlatArray || [];

      const target = this.$deepFind({
        array: getFlatArray,
        key: 'position_id',
        value: node?.position_id,
      });
      if (!target) return {};

      const children = target.children;

      if (!children || children.length === 0) {
        return {};
      }

      const brushes = children.reduce((acc, child) => {
        const { b_arrow_color, b_arrow_thickness } = child;
        const color = b_arrow_color
          ? b_arrow_color
          : this.getBg(child.position_id, child.hm, child);
        acc[color] = {
          key: color,
          color: color,
          opacity: 1,
          // lineWidth: Math.round(20 * (b_arrow_thickness / 100)),
          // lineWidth: Math.round(b_arrow_thickness / 100 * tileSize),
          lineWidth: b_arrow_thickness
            ? Math.round(tileSize * 0.35 * (b_arrow_thickness / 100))
            : tileSize * 0.35,
        };
        return acc;
      }, {});
      return brushes;
    },
    getParentCoords(yIndex, column, allItems, columnIndex) {
      const parentSlice = allItems[columnIndex - 1];
      let result = 0;
      if (parentSlice) {
        const parentIndex = parentSlice.findIndex(
          (item) => item.nodethis === column[yIndex]?.nodeparent,
        );
        if (parentIndex === -1) return 0;
        const parentTop = this.getPaddingTop(
          parentIndex,
          parentSlice,
          allItems,
          columnIndex - 1,
        );
        result = parentTop;
      }
      return result;
    },
    getPaddingTop(yIndex, column, allItems, columnIndex) {
      //go back and find the parent, then calculate the top padding for it
      const coordsY = this.getParentCoords(
        yIndex,
        column,
        allItems,
        columnIndex,
      );
      let result = coordsY;

      for (let i = 0; i < yIndex; i++) {
        const neightbourItem = column[i];
        if (neightbourItem.nodeparent !== column[yIndex].nodeparent) {
          continue;
        }
        const neightbourCount = neightbourItem.visibleChildren;
        result +=
          (neightbourCount ? neightbourCount : 1) * (this.blockHeight + 20);
      }

      return result;
    },
    prepareTreeNodes(val) {
      const {
        sideOptionsFilter,
        studiedOptionsFilter,
        watchListOptionsFilter,
        sortByOptionsFilter,
        openingOptionsFilter,
        hmRange,
        getOpeningsVariants,
        deltaRange,
        positionStatusFilter,
      } = this;
      const currentVariant = getOpeningsVariants().find(
        ({ id }) => id === openingOptionsFilter,
      );

      let maxTotalShortage = 0;
      const clone = cloneDeep(val);

      const presentNodes = {};

      // for (let i = 0; i < clone.length; i++) {
      //   const mistake = clone[i];

      //   const {always} = mistake.ancestor

      //   for(let id of always) {
      //      presentNodes[id] = true
      //   }

      //   if(always.length > 0) {
      //     presentNodes[mistake.position_id] = true
      //   }

      // }

      //  for (let i = 0; i < clone.length; i++) {
      //   const mistake = clone[i];

      //   const {sometimes} = mistake.ancestor

      //   const allSometimes = sometimes.reduce((acc,id) => {
      //     if(!presentNodes[id]) {

      //     }
      //   })

      // }

      for (let i = 0; i < clone.length; i++) {
        const mistake = clone[i];

        const key =
          mistake.herowhite === 1 ? 'ancestors_white' : 'ancestors_black';

        const ancestors = this.mistakes[key];

        const currentAncestor = ancestors.find(
          (ancestor) => ancestor.position_id === mistake.position_id,
        );

        if (!currentAncestor) {
          console.log('no ancestor!');
          continue;
        }
        const fixedAlways = currentAncestor.always.filter((u) =>
          clone.find((i) => i.position_id === u),
        );
        const fixedSometimes = currentAncestor.sometimes.filter((u) =>
          clone.find((i) => i.position_id === u),
        );

        mistake.ancestor = {
          always: fixedAlways,
          sometimes: fixedSometimes,
        };
      }

      clone.sort((a, b) => {
        return b.ancestor.always.length - a.ancestor.always.length;
      });

      const tree = {
        children: [],
        nodethis: null,
      };

      const mistakesWithoutParent = [];
      let lastMistakesWithoutParentLength = 0;

      const checkAndAdd = (mistake, nodeparent, depth) => {
        mistake.nodeparent = nodeparent;
        const ancestor = mistake.ancestor;

        if (ancestor.always.length === 0 && ancestor.sometimes.length === 0) {
          if (
            !tree.children.find(
              (item) => item.position_id === mistake.position_id,
            )
          ) {
            presentNodes[mistake.position_id] = true;
            tree.children.push({
              ...mistake,
              nodethis: mistake.position_id,
              children: [],
              depth: 1,
            });
          }
        } else if (ancestor.always.length > 0) {
          for (let u = 0; u < ancestor.always.length; u++) {
            let occurance = deepObjectSearch({
              target: [tree],
              key: 'position_id',
              value: ancestor.always[u],
            })?.[0]?.target;

            if (occurance) {
              if (u === ancestor.always.length - 1) {
                if (
                  !occurance.children.find(
                    (item) => item.position_id === mistake.position_id,
                  )
                ) {
                  presentNodes[mistake.position_id] = true;
                  occurance.children.push({
                    ...mistake,
                    nodethis: mistake.position_id,
                    children: [],
                    skipRight: u,
                    depth: occurance.depth + 1,
                  });
                }
              }
            } else {
              checkAndAdd(
                clone.find((item) => item.position_id === ancestor.always[u]),
                mistake.nodethis,
                u + 1,
              );
              if (u === ancestor.always.length - 1) {
                occurance = deepObjectSearch({
                  target: [tree],
                  key: 'position_id',
                  value: ancestor.always[u],
                })?.[0]?.target;

                if (occurance) {
                  if (
                    !occurance.children.find(
                      (item) => item.position_id === mistake.position_id,
                    )
                  ) {
                    presentNodes[mistake.position_id] = true;
                    occurance.children.push({
                      ...mistake,
                      skipRight: 0,
                      nodethis: mistake.position_id,
                      children: [],
                      depth: occurance.depth + 1,
                    });
                  }
                } else {
                  //   console.log('steel no occurance! ', mistake.position_id);
                  mistakesWithoutParent.push(mistake);
                }
              }
            }
          }
        }
      };

      for (let i = 0; i < clone.length; i++) {
        const mistake = clone[i];
        checkAndAdd(mistake, undefined, 0);
      }

      const iterateMistakesWithoutParent = () => {
        if (lastMistakesWithoutParentLength === mistakesWithoutParent.length) {
          return;
        }
        lastMistakesWithoutParentLength = mistakesWithoutParent.length;
        for (let i = 0; i < mistakesWithoutParent.length; i++) {
          const mistake = mistakesWithoutParent[i];

          const { ancestor } = mistake;

          if (ancestor.sometimes.length === 0) {
            const occur = tree.children.find(
              (n) => n.nodethis === mistake.position_id,
            );
            if (!occur) {
              presentNodes[mistake.position_id] = true;
              tree.children.push({
                ...mistake,
                skipRight: 1,
                nodethis: mistake.position_id,
                children: [],
                allSometimesPresent: false,
                depth: 1,
              });
            }
            continue;
          }
          let lastSometimes, skipRight;

          for (let u = 0; u < ancestor.sometimes.length; u++) {
            lastSometimes = deepObjectSearch({
              target: [tree],
              key: 'position_id',
              value: ancestor.sometimes[u],
            })?.[0]?.target;

            skipRight = u;
          }

          if (!lastSometimes) {
            // console.log('no last sometimes', mistake);
            continue;
          } else {
            if (
              !lastSometimes.children.find(
                (item) => item.position_id === mistake.position_id,
              )
            ) {
              presentNodes[mistake.position_id] = true;
              lastSometimes.children.push({
                ...mistake,
                skipRight: skipRight,
                nodethis: mistake.position_id,
                children: [],
                allSometimesPresent: true,
                depth: lastSometimes.depth + 1,
              });

              mistakesWithoutParent.splice(i, 1);
              i--;
            }
          }
        }

        iterateMistakesWithoutParent();
      };

      iterateMistakesWithoutParent();

      // if (mistakesWithoutParent.length > 0) {
      //   for (let i = 0; i < mistakesWithoutParent.length; i++) {
      //     const mistake = mistakesWithoutParent[i];
      //     const occur = tree.children.find(
      //       (n) => n.nodethis === mistake.position_id,
      //     );
      //     if (!occur) {
      //       tree.children.push({
      //         ...mistake,
      //         skipRight: 1,
      //         nodethis: mistake.position_id,
      //         children: [],
      //         allSometimesPresent: false,
      //         depth: 1,
      //       });
      //     }
      //   }
      // }

      for (let mistake of clone) {
        const occur = presentNodes[mistake.position_id];

        if (!occur) {
          tree.children.push({
            ...mistake,
            skipRight: 1,
            nodethis: mistake.position_id,
            children: [],
            allSometimesPresent: false,
            depth: 1,
          });
        }
      }

      let max = 1000;
      let hasChanges = false;

      const iterateNodesPositions = () => {
        const iterationCycle = () => {
          max--;
          const updateNodePosition = (node) => {
            let childrenToFilter = [];

            const iterator = (item) => {
              const sometimes = item.ancestor.sometimes;

              let farthestSometimes;
              for (let i = 0; i < sometimes.length; i++) {
                const currentSometimes = deepObjectSearch({
                  target: [tree],
                  key: 'nodethis',
                  value: sometimes[i],
                })?.[0]?.target;

                if (currentSometimes) {
                  if (!farthestSometimes) {
                    farthestSometimes = currentSometimes;
                  } else {
                    if (currentSometimes.depth > farthestSometimes.depth) {
                      farthestSometimes = currentSometimes;
                    }
                  }
                }
              }

              if (farthestSometimes && farthestSometimes.depth >= item.depth) {
                childrenToFilter.push(item);
                if (!hasChanges) {
                  hasChanges = true;
                }

                const updateChildrenDepths = (child, newDepth) => {
                  child.depth = newDepth;
                  if (child.children) {
                    for (let u = 0; u < child.children.length; u++) {
                      updateChildrenDepths(child.children[u], newDepth + 1);
                    }
                  }
                };
                updateChildrenDepths(item, farthestSometimes.depth + 1);
                farthestSometimes.children.push({
                  ...item,
                  nodeparent: farthestSometimes.nodethis,
                  sometimesParent: true,
                });
              }
            };

            for (let i = 0; i < node.children.length; i++) {
              iterator(node.children[i]);
            }

            if (childrenToFilter.length > 0) {
              node.children = node.children.reduce((acc, item) => {
                if (
                  childrenToFilter.find((i) => i.nodethis === item.nodethis)
                ) {
                  return acc;
                }
                acc.push(item);
                return acc;
              }, []);
            }
            childrenToFilter = [];

            for (let i = 0; i < node.children.length; i++) {
              updateNodePosition(node.children[i]);
            }
          };
          updateNodePosition(tree, hasChanges);
          // console.log('hasChanges', hasChanges);
          if (hasChanges && max > 0) {
            // console.log('cycle')
            hasChanges = false;
            iterationCycle();
          } else {
            console.log(`hasChanges: ${hasChanges}, max: ${max}`);
          }
        };

        iterationCycle();
      };

      iterateNodesPositions();

      const reducer = () => (acc, item) => {
        acc += item.children?.reduce(reducer(), 0) || 0;
        if (!item.children || item.children.length === 0) acc++;
        return acc;
      };

      const addOpenedChildrenCount = (item, nodeparent) => {
        if (item.total_shortage > maxTotalShortage) {
          maxTotalShortage = item.total_shortage;
        }
        if (item.children) {
          item.children = mistakeSorter({
            array: item.children,
            currentVariant,
            sideOptionsFilter,
            studiedOptionsFilter,
            watchListOptionsFilter,
            sortByOptionsFilter,
            hmRange,
            deltaRange,
            positionStatusFilter,
          });
        }
        item.nodeparent = nodeparent;
        item.visibleChildren = item.children?.reduce(reducer(), 0) || 0;

        //  item.parent =  cloneDeep(parent)
        item.children?.forEach((child) =>
          addOpenedChildrenCount(child, item.nodethis),
        );
      };

      addOpenedChildrenCount(tree, tree.nodethis);

      //todo
      const addOffset = (item, index, parent) => {
        const blockWidth = this.blockWidth;

        if (!parent) {
          item.offsetTop = 0;
        } else {
          if (index === 0) {
            item.offsetTop = parent.offsetTop;
          } else {
            // всё сложнее
            // item.offsetTop = parent.offsetTop + (this.blockHeight / 2 + 10 * index)
          }
        }

        item.offsetLeft = item.depth * (blockWidth + blockWidth * 0.3);

        item.children?.forEach((child, index) => addOffset(child, index, item));
      };

      //   console.log('mistakesWithoutParent', mistakesWithoutParent);
      //   console.log(tree);

      //   const iterator = (acc, item) => {
      //     if (
      //       !getMistakesOpenings.find((i) => i.position_id === item.position_id)
      //     ) {
      //       return acc;
      //     }

      //     const fixedAlways = item.always.filter((u) =>
      //       getMistakesOpenings.find((i) => i.position_id === u),
      //     );
      //     const fixedSometimes = item.sometimes.filter((u) =>
      //       getMistakesOpenings.find((i) => i.position_id === u),
      //     );

      //     // if(fixedAlways.length ===0 && fixedSometimes.length === 0) {
      //     //     return acc
      //     // }

      //     acc.push({ ...item, always: fixedAlways, sometimes: fixedSometimes });

      //     return acc;
      //   };
      this.maxTotalShortage = maxTotalShortage;
      return tree;
    },
    handleBlockClick(node) {
      const occur = this.getMistakesOpenings.find(
        (i) => i.position_id === node.position_id,
      );

      if (occur) {
        this.SET_DATA_BY_KEY({
          key: 'selectedMistake',
          value: occur,
        });
      }
    },
    async goToNode(node, offset = { top: 0, left: 0 }) {
      const tree = this.$refs.tree;

      if (!tree) {
        return;
      }

      const coords = tree.getCoordsFromDict(node.nodethis);

      if (coords) {
        const container = this.$refs.treeContainer;

        if (container) {
          container.scrollTo({
            left: coords[0] + offset.left,
            top: coords[1] + offset.top,
            behavior: 'smooth',
          });
        }

        // el.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'end',
        //   inline: 'end',
        // });
      }
    },
    handleMistakeClick(item) {
      this.SET_DATA_BY_KEY({ key: 'selectedMistake', value: item });
      this.goToNode({ nodethis: item.position_id });
    },
    handleSearchDefaultPosition() {},
    handleSearchAfter() {},
    goToAnalysis() {
      this.$router.push({
        name: 'Analysis',
        params: {
          heroid: this.hero_id,
          categories: this.heroCategories,
          posid: this.selectedMistake.position_id,
        },
      });
    },
    handleToggleExercise(mistake) {
      const exercisesList = this.exercisesList;

      if (!exercisesList) {
        return;
      }

      const occur = exercisesList.find(
        (i) =>
          i.position_id === mistake.position_id &&
          i.herowhite === mistake.herowhite,
      );

      if (occur) {
        return this.removeFromExercises({
          id: occur.id,
          heroid: Number(this.heroid),
        });
      } else {
        return this.addToExercises({
          heroid: Number(this.heroid),
          depth: 30,
          barrier: 250,
          posid: mistake.position_id,
          herowhite: mistake.herowhite,
        });
      }
    },
    async gotToExsercise(mistake) {
      if (!this.isInExercies(mistake)) {
        await this.handleToggleExercise(mistake)
          .then((res) => {
            this.$router.push({
              name: 'ExercisePage',
              params: {
                heroid: this.$route.params.heroid,
                posid: mistake.position_id,
                categories: this.$route.params.categories,
                herowhite: mistake.herowhite,
              },
            });
          })
          .catch((e) => {
            this.SET_DATA_BY_KEY({
              key: 'appToast',
              value: 'Слишком мало позиций для построения дерева',
            });
          });
      } else {
        this.$router.push({
          name: 'ExercisePage',
          params: {
            heroid: this.$route.params.heroid,
            posid: mistake.position_id,
            categories: this.$route.params.categories,
            herowhite: mistake.herowhite,
          },
        });
      }
    },
  },
  mounted() {
    this.sfChess = new Chess();
    if (this.stockFishSync === undefined) {
      const local = localStorage.getItem('useStockfish') === '1';
      this.stockFishSync = local;
    }
    this.getExercisesList({ heroid: Number(this.heroid) });
  },
  beforeUnmount() {
    this.$stockfish?.postMessage('stop');
  },
  watch: {
    userPreferences: {
      immediate: true,
      handler: function (val) {
        if (val) {
          if (!this.heroesList) {
            this.getHeroesList();
          }
          if (this.$stockfish) {
            this.$stockfish.onmessage = this.handleStockFishMessage;
          }

          //   this.getMistakesOpening({
          //     heroid: this.heroid,
          //     herocats: this.heroCategories,
          //   });
        }
      },
    },
    getMistakesOpenings: {
      immediate: true,
      handler: function (val, oldVal) {
        if (!isEqual(val, oldVal)) {
          this.treeKey = Math.random();
          console.time('prepareTree');
          this.treeNodes = this.prepareTreeNodes(val);
          console.timeEnd('prepareTree');

          //   this.SET_DATA_BY_KEY({
          //     key: 'selectedMistake',
          //     value: val[0] || null,
          //   });
        }
      },
    },
    heroid: {
      immediate: true,
      handler: function (val, oldVal) {
        if (val !== oldVal) {
          this.treeNodes = null;
          this.SET_DATA_BY_KEY({
            key: 'mistakes',
            value: null,
          });
          this.SET_DATA_BY_KEY({
            key: 'selectedMistake',
            value: null,
          });
          this.getMistakes({
            heroid: this.heroid,
            herocats: this.heroCategories,
            position_id: this.$route.params.posid,
          });
        }
      },
    },
    getFlatArray: {
      handler: function (val) {
        if (val) {
          this.updateVisibleItems(val);
        } else {
          this.visibleItems = [];
        }
      },
    },
    selectedMistake: {
      handler: async function (val) {
        this.$router.push({
          name: 'PreAnalysis',
          params: {
            ...this.$route.params,
            posid: val?.position_id,
          },
        });

        this.selectedMistakeArrows = [];
        this.selectedMistakeBrushes = {};
        this.selectedMistakeChildren = [];
        const resp = await this.getHeroVariants({
          depthfilter: 1,
          depthfull: 1,
          herocats: this.heroCategories.split(',').map((i) => Number(i)),
          heroid: Number(this.heroid),
          herowhite: val.herowhite,
          hm: val.hm,
          posid: val.position_id,
        });

        

        if (resp && resp[0]) {
          const children = [];

          children.push(resp[0]);

          const filtered = resp
            .filter(
              (i) =>
                i.move_count_hero > 0 &&
                !children.find((u) => i.nodethis === u.nodethis),
            )
            .sort((a, b) => b.waste - a.waste);

          if (filtered.length < 3) {
            children.push(...filtered);
          } else {
            children.push(...filtered.slice(0, 2));
          }

          const best = resp
            .sort((a, b) => b.eval - a.eval)
            .reduce((acc, i) => {
              if (children.find((u) => i.nodethis === u.nodethis)) {
                return acc;
              }
              if (!acc) {
                acc = i;
              }
              return acc;
            }, null);
          if (best) {
            children.push(best);
          }
          const arrows = children.reduce((acc, child) => {
            const coords = this.getMoveCoords(child.move_uci);
            if (!coords?.[0] || !coords?.[1]) return acc;
            acc.push({
              orig: coords[0],
              dest: coords[1],
              brush:
                child.b_arrow_color ||
                this.getBg(child.position_id, child.hm, child),
            });
            return acc;
          }, []);

          const vw = this.$store.state.sizes.vw;
          const tileSize = ((vw / 100) * 18) / 8;

          const brushes = children.reduce((acc, child) => {
            const { b_arrow_color, b_arrow_thickness } = child;

            acc[b_arrow_color] = {
              key: b_arrow_color,
              color: b_arrow_color,
              opacity: 1,
              // lineWidth: Math.round(20 * (b_arrow_thickness / 100)),
              // lineWidth: Math.round(b_arrow_thickness / 100 * tileSize),
              lineWidth: Math.round(tileSize * 0.35 * 0.75),
            };
            return acc;
          }, {});

          this.selectedMistakeArrows = arrows;
          this.selectedMistakeBrushes = brushes;
          this.selectedMistakeChildren = children;
        }
        await new Promise(res => setTimeout(res, 100))
        this.SET_DATA_BY_KEY({key: 'autoLoading', value: false})
        // console.log(arrows, brushes)
      },
    },
    //Вернуть, когда Даниил признает мою правоту
    // mistakes: {
    //   immediate: true,
    //   handler: function(val) {
    //     if(!val) {
    //         this.maxTotalShortage = 0
    //     } else {
    //       const list = val.list

    //       if(!list) {
    //         this.maxTotalShortage = 0
    //         return
    //       }
    //       const result = list.reduce((acc,item) => {
    //         if(acc < item.total_shortage) {
    //           acc = item.total_shortage
    //         }
    //         return acc
    //       }, 0)
    //       this.maxTotalShortage = result
    //     }
    //   }
    // }
  },
  provide() {
    return {
      openedNodesArr: () => null,
    };
  },
};
</script>

<style scoped></style>
