<template>
  <div style="position: relative; width: 100%">
    <!-- <v-app-bar color="grey" prominent> </v-app-bar> -->
    <!-- <portal to="app-bar-left-portal"> 
      
       </portal> -->
    <portal to="app-bar-portal">
      <div class="mx-2 pointer">
        <analysis-preferences />
      </div>
    </portal>
    <analysis-sub-header>
      <template v-slot:filters>
        <analysis-moves-filter
          :updateMode="updateMode"
          :TOMode="TOMode"
          :practiceMode="practiceMode"
          :emphasisMode="emphasisMode"
        />
      </template>
      <template v-slot:zoom>
        <analysis-tree-zoom :zoom="zoom" @zoomChange="(val) => (zoom = val)" />
      </template>

      <template v-slot:training>
        <div class="d-flex">
          <v-btn
            size="small"
            color="blue"
            @click="loadAllExercises"
            :text="'test'"
            class="mt-2 text-none semibold-14"
          />
          <v-btn
            size="small"
            color="blue"
            @click="gotToExsercise"
            :text="$t('opening1.toTraining')"
            class="mt-2 text-none semibold-14"
          />
        </div>
      </template>
      <template v-slot:fen v-if="showRightMenu">
        <div
          class="d-flex align-center"
          style="color: #f4f4f4; font-size: 12px; cursor: pointer; width: 290px"
          @click="copyFenToClipboard"
          v-if="showRightMenu"
        >
          <div class="mx-2">FEN</div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.66732 13.3334H5.00065C4.08018 13.3334 3.33398 12.5872 3.33398 11.6667V5.00004C3.33398 4.07957 4.08018 3.33337 5.00065 3.33337H11.6673C12.5878 3.33337 13.334 4.07957 13.334 5.00004V6.66671M8.33398 16.6667H15.0007C15.9211 16.6667 16.6673 15.9205 16.6673 15V8.33337C16.6673 7.4129 15.9211 6.66671 15.0007 6.66671H8.33398C7.41351 6.66671 6.66732 7.4129 6.66732 8.33337V15C6.66732 15.9205 7.41351 16.6667 8.33398 16.6667Z"
              stroke="#797C84"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </template>
      <template v-slot:board>
        <v-btn
          @click="showRightMenu = !showRightMenu"
          size="small"
          class="ma-2"
        >
          <svg
            v-if="!showRightMenu"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9991 12.0004H15.5995V15.5985H11.9991V12.0004ZM11.9991 8.39973H8.40049V12.0004H11.9991V8.39973ZM21 6.6007V17.4002C21 19.3864 19.3888 21 17.3996 21H6.59858C4.61119 21 3 19.3864 3 17.4002V6.6007C3 4.61099 4.61117 3 6.59858 3H17.3997C19.3888 3 21 4.61097 21 6.6007ZM19.2007 7.49977C19.2007 6.00921 17.9903 4.79903 16.4996 4.79903H15.5995V8.39973H11.9991V4.79903H8.40049V8.39973H4.79837V12.0004H8.40049V15.5985H4.79837V16.5002C4.79837 17.9908 6.007 19.1992 7.50044 19.1992H8.40049V15.5985H11.9991V19.1992H15.5995V15.5985H19.2007V12.0004H15.5995V8.39973H19.2007V7.49977Z"
              fill="#9A9A9A"
            />
          </svg>
          <svg
            v-else
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.21505 3.27559C4.7911 3.45233 4.40819 3.70786 4.08462 4.02396L8.40049 8.33983V6.46103L5.21505 3.27559ZM8.85981 4.79903L7.06078 3H17.3997C19.3888 3 21 4.61097 21 6.6007V16.9392L19.2007 15.1399V12.0004H16.0612L15.5995 11.5387V8.39973H19.2007V7.49977C19.2007 6.00921 17.9903 4.79903 16.4996 4.79903H15.5995V8.39973H12.4605L11.9991 7.93833V4.79903H8.85981ZM10.3392 8.39973H8.46039L11.9991 11.9385V10.0597L10.3392 8.39973ZM13.9399 12.0004H12.0611L15.5995 15.5388V13.66L13.9399 12.0004ZM17.538 15.5985H15.6592L19.9755 19.9148C20.2915 19.5912 20.5471 19.2083 20.7239 18.7845L17.538 15.5985ZM18.698 20.7587L15.5995 17.6601V19.1992H11.9991V15.5985H13.5379L11.9991 14.0598V15.5985H8.40049V12.0004H9.93977L8.40049 10.4612V12.0004H4.79837V8.39973H6.33907L3.2411 5.30176C3.08539 5.70465 3 6.14264 3 6.6007V17.4002C3 19.3864 4.61119 21 6.59858 21H17.3996C17.8575 21 18.2953 20.9145 18.698 20.7587ZM8.40049 15.5985V19.1992H7.50044C6.007 19.1992 4.79837 17.9908 4.79837 16.5002V15.5985H8.40049Z"
              fill="#9A9A9A"
            />
            <path
              d="M22.0919 21.0919L3 2"
              stroke="#9A9A9A"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </template>
    </analysis-sub-header>

    <v-dialog v-model="optionsDialogModel" width="420px">
      <analysis-settings
        v-if="dialogType === 'settings'"
        @close="dialogType = null"
      />
    </v-dialog>

    <TooltipWithBoard v-if="isTooltipShown" :tooltip="tooltip">
      <analysis-summary
        :selectedOpening="tooltip.item"
        v-if="tooltip.item"
        :compareString="getCompareString"
        :heroName="getHeroName"
        :handleSelectOpening="handleSelectOpening"
        :compact="true"
      />
    </TooltipWithBoard>

    <div style="width: 100vw; height: calc(90vh - 40px)" class="d-flex">
      <div style="height: calc(90vh - 40px); flex: 10">
        <div
          style="width: 100%; height: calc(100vh - 100px); position: relative"
        >
          <div
            :style="`overflow: auto;
              width: ${!showRightMenu ? '100vw' : 'calc(54vw + 500px)'};
              height: calc(90vh - 46px);
              position: absolute;
              padding-top: 10px;
              left: ${showLeftMenu ? 500 : 0}px`"
            ref="treeContainer"
            @click="contextMenu = null"
          >
            <div
              v-if="contextMenu"
              :style="{
                position: 'absolute',
                top: contextMenu.top + 'px',
                left: contextMenu.left + 'px',
              }"
            >
              <sandwich-menu
                v-if="contextMenu.type === 'sandwich'"
                :item="contextMenu.item"
                :permanentlyHiddenNodes="permanentlyHiddenNodes"
                @close="contextMenu = null"
                @apply="(obj) => (permanentlyHiddenNodes = obj)"
                :userPreferences="userPreferences"
                :rootColor="openingsTree.hm % 2 === 0 ? 'black' : 'white'"
              />
              <tree-context-menu
                v-if="contextMenu.type === 'context'"
                :item="contextMenu.item"
                @close="contextMenu = null"
                @apply="(obj) => (permanentlyHiddenNodes = obj)"
                @changePractice="toggleNodeTraining"
                @changeBookmark="handleToogleBookmark"
                :permanentlyHiddenNodes="permanentlyHiddenNodes"
                :bookMarks="bookMarks"
              />
            </div>

            <svg-tree
              v-if="openingsTree"
              :items="getFlatArray"
              :getPaddingTop="getPaddingTop"
              :loadChildren="toggleNode"
              :blockHeight="blockHeight"
              :blockWidth="blockWidth"
              :getParentCoords="getParentCoords"
              :setTooltip="toggleTooltip"
              :handleSelectOpening="handleSelectOpening"
              :selectedOpening="selectedOpening"
              :onDblClick="toggleNodeTraining"
              :mode="treeModel"
              :movesModel="movesModel"
              :handleArrowPress="handleArrowPress"
              :rootColor="openingsTree.hm % 2 === 0 ? 'black' : 'white'"
              :simple="false"
              :size="900"
              :mistakeFens="mistakeFens"
              ref="tree"
              :blockGap="blockWidth + 32 + 16"
              :onSandwichClick="handleSandwichClick"
              :scale="zoom"
              :onContextClick="handleContextMenu"
              variant="analysis"
            />

            <!-- :height="blockHeight * 3" -->
          </div>
          <div
            :style="`margin-left: ${
              showLeftMenu ? 500 : 0
            }px; height: 30px; max-width: ${
              showLeftMenu ? '44vw' : 'calc(44vw + 500px)'
            }; overflow-x: auto; overflow-y:hidden;`"
          >
            <v-chip
              size="small"
              class="mx-1"
              v-for="bookMark in bookMarks"
              :key="bookMark.pos_to_id"
              @click="goToBookmark(bookMark)"
              @mouseenter="
                (e) =>
                  toggleTooltip({
                    top: 40,
                    left: e.clientX - (showLeftMenu ? 500 : 0),
                    fen: bookMark.fen,
                  })
              "
              @mouseleave="toggleTooltip()"
              >{{ `${bookMark.movenum} ${$getMoveText(bookMark)}` }}</v-chip
            >
          </div>
        </div>
      </div>

      <div
        style="
          width: 346px;
          height: calc(90vh - 40px);
          position: absolute;
          top: 40px;
          right: 12px;
        "
        class="pa-2 pr-7"
        v-if="showRightMenu"
      >
        <v-card style="width: 100%; background: #232323" class="mb-2 d-flex">
          <advanced-board
            size="264px"
            :config="{
              viewOnly: false,
              fen:
                customMovesArr.length > 0
                  ? customMove
                  : stockFishFen || (selectedOpening && selectedOpening.fen),
              orientation: currentSide,
              drawable: {
                brushes: getBrush,
              },
              lastMove: getLastMove,
            }"
            :arrows="getSelectedOpeningArrows"
            :allMoves="[]"
            :currentMoveIndex="undefined"
            :movePieces="true"
            ref="bigBoard"
            @customMove="handleCustomeMove"
          />
        </v-card>

        <div v-show="moveBoard !== null">
          <v-card
            :style="`position: absolute; left: 0; top: ${
              (moveBoard && moveBoard.top + 'px') || 0
            }; z-index: 100; display: ${moveBoard ? 'block' : 'none'}`"
            width="250"
            height="250"
            class="bg-white d-flex align-center justify-center"
            variant="outlined"
          >
            <advanced-board
              size="200px"
              :config="{
                viewOnly: true,
                fen: customMove || (selectedOpening && selectedOpening.fen),
                orientation: currentSide,
              }"
              :allMoves="stockfishMoves || []"
              :currentMoveIndex="moveBoard && moveBoard.movesArrayIndex"
            />
          </v-card>
        </div>

        <div style="width: 100%" class="d-flex align-center justify-start">
          <v-btn
            v-bind="props"
            size="small"
            class="d-flex align-center ma-2"
            v-if="selectedOpening"
            @click="toggleNodeTraining(selectedOpening)"
          >
            <svg
              width="24"
              height="20"
              viewBox="0 0 24 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              v-if="selectedOpening.status === 1"
            >
              <path
                d="M4.25959 8.14692C3.98453 10.2525 3.82064 12.3711 3.76859 14.4939C6.64739 15.6964 9.40377 17.1732 11.9996 18.9039C14.5957 17.1732 17.3524 15.6964 20.2316 14.4939C20.1795 12.3711 20.0156 10.2525 19.7406 8.14692M19.7406 8.14692C20.6156 7.85292 21.5026 7.58092 22.3986 7.33292C19.1352 5.04517 15.6516 3.08881 11.9996 1.49292C8.34758 3.08914 4.86398 5.04584 1.60059 7.33392C2.49386 7.58055 3.38019 7.85165 4.25859 8.14692C6.92721 9.04413 9.51649 10.162 11.9996 11.4889C14.4823 10.162 17.0723 9.04412 19.7406 8.14692ZM6.74959 12.9999V9.32492C8.44632 8.27026 10.1989 7.30818 11.9996 6.44292M4.99259 17.9929C5.55056 17.4363 5.99302 16.7749 6.29455 16.0468C6.59608 15.3186 6.75072 14.538 6.74959 13.7499V12.2499"
                stroke="#F4F4F4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-else
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.6999 2.80505C11.7946 2.76375 11.8967 2.74243 11.9999 2.74243C12.1032 2.74243 12.2053 2.76375 12.2999 2.80505C15.998 4.42145 19.5255 6.40294 22.8299 8.72005C22.9464 8.80171 23.0372 8.91481 23.0918 9.04614C23.1464 9.17748 23.1626 9.32163 23.1384 9.46179C23.1141 9.60195 23.0506 9.73233 22.9551 9.83773C22.8596 9.94312 22.736 10.0192 22.5989 10.057C19.1683 11.0045 15.8488 12.316 12.6969 13.969L12.6939 13.971C12.5799 14.031 12.4669 14.09 12.3539 14.151C12.2452 14.2092 12.1238 14.2396 12.0004 14.2396C11.8771 14.2396 11.7557 14.2092 11.6469 14.151C10.2951 13.4296 8.91135 12.7696 7.49994 12.173V11.949C7.49994 11.818 7.56694 11.701 7.67194 11.638C9.18131 10.7234 10.7341 9.88237 12.3249 9.11805C12.5042 9.03185 12.6419 8.87796 12.7078 8.69024C12.7736 8.50252 12.7621 8.29633 12.6759 8.11704C12.5897 7.93776 12.4359 7.80006 12.2481 7.73423C12.0604 7.66841 11.8542 7.67985 11.6749 7.76605C10.0407 8.55133 8.44552 9.41534 6.89494 10.355C6.67434 10.4889 6.48403 10.6672 6.33614 10.8786C6.18824 11.09 6.08601 11.3299 6.03594 11.583C4.51715 10.9986 2.97063 10.489 1.40194 10.056C1.26483 10.0182 1.14132 9.94212 1.04581 9.83673C0.9503 9.73133 0.886728 9.60095 0.862512 9.46079C0.838297 9.32063 0.854435 9.17648 0.909045 9.04514C0.963655 8.91381 1.05449 8.80071 1.17094 8.71905C4.47513 6.40236 8.00227 4.42121 11.6999 2.80505Z"
                fill="#F4F4F4"
              />
              <path
                d="M13.0598 15.4729C15.516 14.1607 18.0809 13.0626 20.7258 12.1909C20.8598 13.6049 20.9458 15.0339 20.9808 16.4749C20.9846 16.626 20.9428 16.7747 20.8607 16.9016C20.7786 17.0284 20.6601 17.1276 20.5208 17.1859C17.686 18.3699 14.9719 19.8239 12.4158 21.5279C12.2926 21.6101 12.1478 21.6539 11.9998 21.6539C11.8517 21.6539 11.707 21.6101 11.5838 21.5279C9.028 19.824 6.31415 18.3699 3.47977 17.1859C3.34037 17.1278 3.22178 17.0289 3.13952 16.9022C3.05726 16.7755 3.01518 16.6269 3.01877 16.4759C3.05377 15.0339 3.13977 13.6059 3.27377 12.1899C4.19477 12.4939 5.10377 12.8239 5.99977 13.1799V14.4499C5.7837 14.5744 5.60208 14.7508 5.47133 14.9632C5.34058 15.1755 5.26483 15.4171 5.25094 15.666C5.23704 15.915 5.28543 16.1635 5.39173 16.3891C5.49804 16.6147 5.6589 16.8102 5.85977 16.9579C5.76977 17.3379 5.63777 17.7109 5.46277 18.0679C5.91477 18.2809 6.36377 18.5019 6.80877 18.7279C7.05961 18.2177 7.24475 17.6777 7.35977 17.1209C7.61265 17.0085 7.8295 16.8283 7.98632 16.6003C8.14313 16.3722 8.2338 16.1052 8.24829 15.8289C8.26278 15.5525 8.20053 15.2775 8.06843 15.0343C7.93632 14.7911 7.73951 14.5892 7.49977 14.4509V13.8059C8.66787 14.3162 9.81543 14.8723 10.9398 15.4729C11.2659 15.6471 11.63 15.7383 11.9998 15.7383C12.3695 15.7383 12.7336 15.6471 13.0598 15.4729Z"
                fill="#F4F4F4"
              />
              <path
                d="M4.46201 19.4619C4.88201 19.0429 5.21501 18.5719 5.46201 18.0669C5.91501 18.2809 6.36401 18.5019 6.80901 18.7289C6.48181 19.3935 6.04732 19.9996 5.52301 20.5229C5.38084 20.6554 5.19279 20.7275 4.99849 20.7241C4.80419 20.7206 4.6188 20.6419 4.48139 20.5045C4.34398 20.3671 4.26526 20.1817 4.26184 19.9874C4.25841 19.7931 4.33053 19.6051 4.46301 19.4629L4.46201 19.4619Z"
                fill="#F4F4F4"
              />
            </svg>
          </v-btn>

          <v-btn
            v-bind="props"
            size="small"
            class="d-flex align-center ma-2"
            v-if="selectedOpening"
            @click="handleToogleBookmark(selectedOpening)"
          >
            <svg
              v-if="!getAddedToBookmarks(selectedOpening)"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V21L12 17.5L5 21V5Z"
                stroke="#F4F4F4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <svg
              v-else
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 5C5 3.89543 5.89543 3 7 3H17C18.1046 3 19 3.89543 19 5V20.191C19 20.5627 18.6088 20.8044 18.2764 20.6382L12 17.5L5.72361 20.6382C5.39116 20.8044 5 20.5627 5 20.191V5Z"
                fill="#F4F4F4"
                stroke="#F4F4F4"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </v-btn>
          <v-spacer />
          <div class="d-flex align-center justify-start ml-4 mt-2">
            <v-switch
              class="ml-2"
              density="compact"
              v-model="useStockFish"
              hide-details
              :label="$t('opening1.switchEngine')"
            />
            <v-btn
              icon
              size="small"
              v-if="selectedStockFishArr && selectedStockFishArr.moves"
              @click="resetStockFishArr"
              ><v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </div>

        <div
          v-if="currentStockFishIndex !== undefined && selectedStockFishArr"
          style="width: 100%"
          class="d-flex align-center flex-wrap text-caption bg-light-grey"
          @mouseleave="toggleTooltip()"
        >
          <div
            v-for="(move, i) in selectedStockFishArr.moves"
            :key="i"
            class="pa-1 text-caption"
          >
            <span class="mx-1">{{ move.moveStr }}</span>

            <span
              :class="`px-1 hoverableStockFishItem regular-14 ${
                currentStockFishIndex === i &&
                currentStockFishFigurineIndex === u
                  ? 'bg-black text--white'
                  : ''
              }`"
              @click="
                currentStockFishIndex = i;
                currentStockFishFigurineIndex = u;
                customMove = moveFugurine.fen;
              "
              @mouseover="
                (e) =>
                  toggleTooltip({
                    fen: moveFugurine.fen,
                    right: 400,
                    top: 390,
                    herowhite: selectedMistake && selectedMistake.herowhite,
                    height: 210,
                  })
              "
              v-for="(moveFugurine, u) in move.moves"
              :key="moveFugurine.moveIndex + 'figurine'"
              style="cursor: pointer; width: 46px"
            >
              {{ moveFugurine.moveName }}
            </span>

            <!-- {{ getPrettyMoves[itemIndex] &&  getPrettyMoves[itemIndex][i] }} -->
            <!-- <v-chip size="x-small">{{ move }}</v-chip> -->
          </div>
        </div>
        <div style="width: 100%" @mouseleave="toggleTooltip()">
          <div
            v-for="(item, itemIndex) in getPrettyMoves"
            :key="item.string"
            :class="
              toggledRow(itemIndex)
                ? 'stockfish-node__toggled'
                : 'stockfish-node'
            "
          >
            <div class="d-flex align-center flex-wrap text-caption">
              <v-chip size="small" class="bg-black">
                {{ item.string }}
              </v-chip>
              <span
                style="cursor: pointer"
                class="text-subtitle-1 px-2"
                @click="toggledRow = itemIndex"
                >{{ toggledRow(itemIndex) ? '↑' : '↓' }}</span
              >

              <div
                v-for="(move, i) in item.moves"
                :key="i"
                class="pa-1 text-caption"
              >
                <span class="mx-1">{{ move.moveStr }}</span>

                <span
                  class="px-1 hoverableStockFishItem regular-14"
                  @click="
                    selectedStockFishArr = prepareStockFishArray(item);
                    currentStockFishIndex = i;
                    currentStockFishFigurineIndex = u;
                  "
                  @mouseover="
                    (e) =>
                      toggleTooltip({
                        fen: moveFugurine.fen,
                        right: 400,
                        top: 390,
                        herowhite: selectedMistake && selectedMistake.herowhite,
                        height: 210,
                      })
                  "
                  v-for="(moveFugurine, u) in move.moves"
                  :key="moveFugurine.moveIndex + 'figurine'"
                  style="cursor: pointer; width: 46px"
                >
                  {{ moveFugurine.moveName }}
                </span>

                <!-- {{ getPrettyMoves[itemIndex] &&  getPrettyMoves[itemIndex][i] }} -->
                <!-- <v-chip size="x-small">{{ move }}</v-chip> -->
              </div>
            </div>
            <v-divider />
          </div>
        </div>

        <analysis-summary
          :selectedOpening="getFullNode"
          v-if="getFullNode"
          :compareString="getCompareString"
          :heroName="getHeroName"
          :handleSelectOpening="handleSelectOpening"
        />
      </div>
    </div>
    <div class="container">
      <flexible-sidebar>
        <collapsible-card
          :title="$t('opening1.legend')"
          maxWidth="240px"
          expandedMaxWidth="240px"
          card-id="1"
          :openedCardId="openedCardId"
          :changeOpenedCardId="changeOpenedCardId"
        >
          <AnaysisLegend />
        </collapsible-card>

        <collapsible-card
          :openedCardId="openedCardId"
          :changeOpenedCardId="changeOpenedCardId"
          card-id="2"
          :title="getCompareString"
          maxWidth="240px"
          expandedMaxWidth="240px"
          collapsedIconId="editCard"
          expandedIconId="cancelCard"
          v-if="userPreferences"
        >
          <AnaisysFiltersCard />
        </collapsible-card>

        <collapsible-card
          :openedCardId="openedCardId"
          :changeOpenedCardId="changeOpenedCardId"
          card-id="3"
          :title="$t('opening1.selectAnother')"
          expandedMaxWidth="520px"
          expandedMaxHeight="320px"
          collapsedIconId="editCard"
          expandedIconId="cancelCard"
        >
          <MistakesList
            @mistakeClick="handleMistakeClick"
            :toggleTooltip="() => {}"
            :getBg="getBg"
            :altFilters="true"
            :exercisesList="exercisesList"
            :customHeight="120"
          />
        </collapsible-card>
      </flexible-sidebar>
    </div>
  </div>
</template>

<script>
/*eslint-disable */
import axios from '@/plugins/axiosWrapper';
import SvgTree from '@/components/SvgTree';
import AnalysisFilters from '@/components/AnalysisFilters';
import MistakesList from '@/components/MistakesList';
import AnalysisSummary from '@/components/AnalysisSummary';
import AnalysisSettings from '@/components/AnalysisSettings.vue';
import AnalysisPreferences from '@/components/AnalysisPreferences.vue';
import AdvancedBoard from '@/components/AdvancedBoard';
import MiniMap from '@/components/MiniMap';
import CompareFilters from '@/components/CompareFilters';
import cloneDeep from 'lodash/cloneDeep';
import throttle from 'lodash/throttle';
import deepObjectSearch from '@/helpers/deepObjectSearch';
import { Chess } from 'chess.js';
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';

import SandwichMenu from '@/components/SandwichMenu';

import deepFind from '@/helpers/deepFind';
import requestOrchestrator from '@/plugins/requestOrchestrator';
import { getDb } from '@/plugins/db.js';
import isEqual from 'lodash/isEqual';
import AnalysisMovesFilter from '@/components/AnalysisMovesFilter.vue';
import AnalysisTreeZoom from '@/components/AnalysisTreeZoom.vue';
import AnalysisSubHeader from '@/components/AnalysisSubHeader.vue';
import TreeContextMenu from '@/components/TreeContextMenu.vue';

import BlockItem from '@/components/SvgTree/BlockItem.vue';

import AnaysisLegend from '@/components/Cards/AnalisysLegend.vue';
import AnaisysFiltersCard from '@/components/Cards/AnaisysFiltersCard.vue';
import FlexibleSidebar from '@/components/Common/FlexibleSidebar.vue';
import TooltipWithBoard from '@/components/TooltipWithBoard.vue';

import { useTooltip } from '@/shared/composables/';

const TOOLTIP_HEIGHT = 392;
const TOOLTIP_WIDTH = 212;

const mockSf = [
  {
    multipv: '1',
    string: '0.49 ',
    moves: [
      'e2e3',
      'c7c6',
      'h2h3',
      'c8f5',
      'f1d3',
      'e5e4',
      'd3c2',
      'd6d5',
      'g1e2',
      'f8d6',
      'e1g1',
      'e8g8',
      'c4d5',
      'c6d5',
      'c3b5',
      'b8c6',
      'b5d6',
      'd8d6',
      'e2f4',
      'c6b4',
      'c2b1',
    ],
  },
];

function transform(xy, angle, xy0) {
  // put x and y relative to x0 and y0 so we can rotate around that
  const rel_x = xy[0] - xy0[0];
  const rel_y = xy[1] - xy0[1];

  // compute rotated relative points
  const new_rel_x = Math.cos(angle) * rel_x - Math.sin(angle) * rel_y;
  const new_rel_y = Math.sin(angle) * rel_x + Math.cos(angle) * rel_y;

  return [xy0[0] + new_rel_x, xy0[1] + new_rel_y];
}

const getStockFishObj = (str) => {
  const splitted = str.split(' ');
  const result = {};
  for (let i = 0; i < splitted.length; i++) {
    const item = splitted[i];
    if (item === 'depth') {
      result.depth = splitted[i + 1];
    }
    if (item === 'multipv') {
      result.multipv = splitted[i + 1];
    }

    if (item === 'cp') {
      result.cp = splitted[i + 1];
    }

    if (item === 'mate') {
      result.mate = splitted[i + 1];
    }

    if (item === 'pv') {
      result.pv = [];

      for (let u = i + 1; u < splitted.length; u++) {
        result.pv.push(splitted[u]);
      }

      return result;
    }
  }
  return result;
};

const makeMove = (move, chess) => {
  try {
    chess.move(move.substring(0, 2) + '-' + move.substring(2, 4));
    return chess.fen();
  } catch {
    return;
  }
};

const convertToFigurineNotation = (moves, chess) => {
  if (!moves || moves.length === 0) {
    return;
  }
  const pieceSymbols = {
    w: { p: '', r: '♖', n: '♘', b: '♗', q: '♕', k: '♔' },
    b: { p: '', r: '♜', n: '♞', b: '♝', q: '♛', k: '♚' },
  };
  const fen = chess.fen();
  let color = fen.split(' ')[1];

  let moveNumber = fen.split(' ')[5];

  const pgnMoves = moves.reduce(
    (acc, sanMove, index) => {
      const figurine = chess.get(sanMove.substring(0, 2));
      const allMoves = chess.moves({ verbose: true });
      if (!figurine) {
        return acc;
      }

      const symbol = pieceSymbols[figurine.color][figurine.type];
      if (!symbol && figurine.type !== 'p') {
        console.log('!!!!!!', figurine);
      }
      let pgnNotation;

      const [A, B, C, D, E] = sanMove.split('');

      if (figurine.type === 'p') {
        if (A === C) {
          pgnNotation = `${C}${D}`;
        } else {
          pgnNotation = `${A}x${C}${D}`;
        }
        if (E) {
          pgnNotation += pieceSymbols[figurine.color][E];
        }
      }

      if (figurine.type !== 'p') {
        let X = '',
          Y,
          Z = `${C}${D}`;

        if (chess.get(`${C}${D}`)) {
          Y = 'x';
        } else {
          Y = '';
        }

        if (figurine.type === 'k') {
          if (A === 'e' && C == 'g') {
            pgnNotation = 'O-O';
          } else if (A === 'e' && C == 'c') {
            pgnNotation = 'O-O-O';
          } else {
            pgnNotation = `${
              pieceSymbols[figurine.color][figurine.type]
            }${Y}${Z}`;
          }
        } else {
          const similarPieces = allMoves.filter(
            (m) =>
              m.piece !== 'p' &&
              m.color === figurine.color &&
              m.piece === figurine.type &&
              m.from !== sanMove.substring(0, 2) &&
              m.to === `${C}${D}`,
          );

          if (similarPieces.length > 0) {
            const sameMoveTo = similarPieces[0];
            // if(sameMoveTo) {
            const sameVertical = sameMoveTo.from.substring(0, 1) === A;
            const sameHorizontal = sameMoveTo.from.substring(1, 2) === B;

            // if(sameVertical || sameHorizontal) {
            //   console.log(sameMoveTo, )
            //   console.log( `${symbol}${A}${Y}${Z}` )
            // }

            if (sameVertical && !sameHorizontal) {
              X = B;
            } else if (sameHorizontal && !sameVertical) {
              X = A;
            } else if (sameHorizontal && sameVertical) {
              X = `${A}${B}`;
            } else {
              X = A;
            }

            // }
          }
          pgnNotation = `${symbol}${X}${Y}${Z}`;
        }
      }

      if (color === 'w') {
        if (index !== 0) {
          moveNumber++;
          acc.push({
            moveStr: `${moveNumber}.`,
            moves: [],
          });
        }
      }
      acc[acc.length - 1].moves.push({
        moveIndex: index,
        moveName: pgnNotation,
        originalMove: sanMove,
        fen: chess.fen(),
      });

      color = color === 'w' ? 'b' : 'w';
      makeMove(sanMove, chess);
      return acc;
    },
    [
      {
        moveStr: `${moveNumber}${color === 'b' ? '...' : '.'}`,
        moves: [],
      },
    ],
  );

  return pgnMoves;
};

export default {
  data: function () {
    this.sfChess = null;
    return {
      dialogType: null,
      maxTotalShortage: 0,
      searchModel:
        // "rnbq1rk1/ppp1bppp/3p1n2/8/2BNP3/2N5/PPP2PPP/R1BQ1RK1 b - - 6 7",
        // 'rnbqkb1r/pp2pppp/3p1n2/8/3NP3/8/PPP2PPP/RNBQKB1R w KQkq - 1 5',
        '',
      // tooltip: null,
      stockfishCalculationsCount: 0,
      preparedTree: null,
      blockHeight: 28,
      blockWidth: 86,
      // boardApi: boardApi,
      // smallBoardApi: smallBoardApi,
      moveBoardApi: null,
      // stockFishStrings: [],
      stockFishStrings: [],

      //если выбрана ветвь стокфиша
      selectedStockFishArr: [],
      currentStockFishIndex: undefined,
      currentStockFishFigurineIndex: undefined,
      rowsToggled: [],
      customMovesArr: [],
      currentCustomMoveIndex: undefined,
      bookMarks: [],
      moveBoard: null,
      compareFiltersDialog: false,
      showLeftMenu: false,
      treeModel: 1,
      movesModel: 2,
      stockFishSync: undefined,
      stockfishMoveIndex: undefined,
      stockfishItemIndex: undefined,
      customMove: '',
      preventedByCustomMove: false,
      originalOpening: null,
      openedNodesArr: [],
      TOMode: 1,
      practiceMode: 0,
      emphasisMode: 2,
      contextMenu: null,
      permanentlyHiddenNodes: {},
      showRightMenu: true,
      openedCardId: '1',
      zoom: 1,
    };
  },
  setup() {
    const { toggleTooltip, isTooltipShown, tooltip } = useTooltip(
      TOOLTIP_HEIGHT,
      TOOLTIP_WIDTH,
    );
    return {
      toggleTooltip,
      isTooltipShown,
      tooltip,
    };
  },
  components: {
    SvgTree,
    AnalysisFilters,
    CompareFilters,
    AnalysisSummary,
    AnalysisSettings,
    AnalysisPreferences,
    AdvancedBoard,
    MistakesList,
    MiniMap,
    AnalysisSubHeader,
    SandwichMenu,
    AnalysisMovesFilter,
    AnalysisTreeZoom,
    TreeContextMenu,

    BlockItem,
    AnaysisLegend,
    AnaisysFiltersCard,
    FlexibleSidebar,
    TooltipWithBoard,
  },
  computed: {
    ...mapState(['isAuthenticated', 'sizes']),
    ...mapState('data', [
      'openingsTree',
      'selectedMistake',
      'compareFilters',
      'selectedOpening',
      'heroesList',
      'currentSide',
      'parsedFen',
      'mistakes',
      'exerciseTree',
      'autoLoadingProgress',
      'exercisesList',
    ]),
    ...mapState('ui', ['userPreferences']),
    ...mapGetters('data', ['getMistakesOpenings']),
    mistakeFens() {
      const allMistakes = this.getMistakesOpenings;
      if (!allMistakes) {
        return {};
      }
      return allMistakes.reduce((acc, item) => {
        acc[item.position_id] = item;
        return acc;
      }, {});
    },
    stockFishFen() {
      const {
        currentStockFishIndex,
        currentStockFishFigurineIndex,
        selectedStockFishArr,
      } = this;

      if (!selectedStockFishArr || currentStockFishIndex === undefined) return;

      const current =
        selectedStockFishArr.moves?.[currentStockFishIndex]?.moves?.[
          currentStockFishFigurineIndex
        ];
      if (!current) return;

      return current?.fen;
    },
    listBoxModel: {
      get() {
        const selectedOpening = this.selectedOpening;
        const getListboxName = this.getListboxName;

        if (
          !getListboxName ||
          !selectedOpening ||
          selectedOpening?.status === undefined ||
          selectedOpening.pos_to_id === null
        )
          return undefined;

        if (getListboxName === 'LBhero') {
          if (selectedOpening.status === 0) {
            return 'h0';
          }
          if (selectedOpening.status === 1) {
            return 'h1';
          }
          return 'h2';
        }

        if (getListboxName === 'LBopp') {
          if (selectedOpening.status === 0) {
            return 'o0';
          }
          if (selectedOpening.status === 1) {
            return 'o1';
          }
          return 'o2';
        }

        return undefined;
      },
      async set(val) {
        const handleListboxClick = this.handleListboxClick;
        const selectedOpening = cloneDeep(this.selectedOpening);
        const rootColor = this.openingsTree.hm % 2 === 0 ? 'black' : 'white';
        const heromove =
          (rootColor === 'black' && selectedOpening.hm % 2 !== 0) ||
          (rootColor === 'white' && selectedOpening.hm % 2 === 0)
            ? 1
            : 0;

        const handlers = {
          h0: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 0,
              heromove,
            }),
          h1: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 1,
              heromove,
            }),
          h2: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 2,
              heromove,
            }),
          o0: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 0,
              heromove,
            }),
          o1: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 1,
              heromove,
            }),
          o2: () =>
            handleListboxClick({
              mtd: 'openings/setmovestatus',
              status: 2,
              heromove,
            }),
        };

        if (handlers[val]) {
          handlers[val]();

          const clone = cloneDeep(this.treeData);
          const targets = deepObjectSearch({
            target: [clone],

            cb: (i) =>
              i.pos_from_id === selectedOpening.pos_from_id &&
              i.pos_to_id === selectedOpening.pos_to_id,
          });

          if (targets) {
            for (let u = 0; u < targets.length; u++) {
              targets[u].target.status = Number(val.substring(1));
              // targets[u].target.node_backlight = resp.node_backlight;
            }

            const target = targets.find(
              (i) => i.target.nodethis === selectedOpening?.nodethis,
            );

            this.treeData = clone;
            this.SET_DATA_BY_KEY({
              key: 'selectedOpening',
              value: target.target,
            });
            // updateNode(target, val)
          }
        } else {
          console.log('err', val);
        }
      },
    },
    getListboxDisabled() {
      const { selectedMistake, selectedOpening } = this;

      if (!selectedMistake || !selectedOpening) return false;
      const preparedTree = this.preparedTree;
      return selectedOpening?.pos_to_id === preparedTree?.pos_to_id;
    },
    getListboxName() {
      const getListboxDisabled = this.getListboxDisabled;
      const tree = this.openingsTree;
      if (getListboxDisabled || !tree) return '';
      const selectedOpening = this.selectedOpening;
      if (!selectedOpening || !selectedOpening.fen) return '';
      // if (!selectedOpening?.node_bgcolor) return '';
      // if (selectedOpening?.node_bgcolor === '#CCFFCCFF') return 'LBhero';
      // if (selectedOpening?.node_bgcolor === '#E0E0FFFF') return 'LBopp';
      // return '';
      // return selectedOpening.node_bgcolor === '#CCFFCCFF' ? 'LBhero' : 'LBopp'
      const rootColor = tree.hm % 2 === 0 ? 'b' : 'w';
      if (selectedOpening)
        return selectedOpening.fen.split(' ')?.[1] === rootColor
          ? 'LBopp'
          : 'LBhero';
    },
    getListboxItems() {
      const getListboxName = this.getListboxName;
      const selectedOpening = this.selectedOpening;

      if (!getListboxName) return [];

      const heroItems = [
        {
          title: this.$t('moveMarking.hchoice'),
          id: 'h0',
        },
        {
          title: this.$t('moveMarking.hignore'),
          id: 'h1',
        },
        {
          title: this.$t('moveMarking.hbydefault'),
          id: 'h2',
          // onClick: () => console.log('потом разберусь')
        },
      ];

      const oppItems = [
        {
          title: this.$t('moveMarking.ochoice'),
          id: 'o0',
        },
        {
          title: this.$t('moveMarking.oignore'),
          id: 'o1',
        },
        {
          title: this.$t('moveMarking.obydefault'),
          id: 'o2',
        },
      ];

      return getListboxName == 'LBhero' ? heroItems : oppItems;
    },
    openedNodesModel: {
      get() {
        const openedNodesArr = this.openedNodesArr;
        return (node) => {
          if (!node) return false;

          return (
            openedNodesArr.findIndex(
              (item) => item.nodethis === node.nodethis,
            ) > -1
          );
        };
      },
      set(node) {
        const openedNodesArr = cloneDeep(this.openedNodesArr);
        const index = openedNodesArr.findIndex(
          (item) => item.nodethis === node.nodethis,
        );

        if (index === -1) {
          this.openedNodesArr = [...openedNodesArr, node];
          // } else if (index === 0) {
          //   this.openedNodesArr = [];
        } else {
          openedNodesArr.splice(index, 1);
          this.openedNodesArr = openedNodesArr;
        }
      },
    },
    getPrettyMoves() {
      const stockFishStrings = this.stockFishStrings;
      if (!stockFishStrings || stockFishStrings.length === 0) {
        return [];
      }

      const all = stockFishStrings
        .filter((item) => item && item.moves && item.moves.length > 0)
        .map((item) => {
          const chess = new Chess(this.customMove || this.selectedOpening?.fen);

          const res = convertToFigurineNotation(item.moves, chess);
          // const result = item.moves.reduce((acc, move) => {
          //   const newFen = chess.fen();
          //   const figurine = convertToFigurineNotation(newFen, move);
          //   acc.push(figurine);
          //   chess.move(move.substring(0, 2) + '-' + move.substring(2, 4));

          //   return acc;
          // }, []);
          // const currentValue = Number(item.string)
          // const fixedValue = color === 'b' ? currentValue * -1 : currentValue

          return {
            moves: res,
            //  string: `${Number(item.string) * color === 'b' ? -1 : 1}`,
            string: item.string,
          };
        });
      return all;
    },
    getTooltipLastMove() {
      const tooltip = this.tooltip;
      if (!tooltip) {
        return [];
      }
      const move = tooltip.move_uci;
      if (move) {
        return [move.substring(0, 2), move.substring(2, 4)];
      } else {
        if (tooltip.prevmove) {
          return [
            tooltip.prevmove.substring(0, 2),
            tooltip.prevmove.substring(2, 4),
          ];
        } else {
          const prevmove = this.selectedMistake?.prevmove;
          if (prevmove) {
            return [prevmove.substring(0, 2), prevmove.substring(2, 4)];
          }
        }
      }

      return [];
    },
    getLastMove() {
      const parsedFen = this.parsedFen;

      if (parsedFen && parsedFen.prevmove) {
        return [
          parsedFen.prevmove.substring(0, 2),
          parsedFen.prevmove.substring(2, 4),
        ];
      }

      if (this.currentCustomMoveIndex !== undefined) {
        const move = this.customMovesArr[this.currentCustomMoveIndex];

        if (move?.prevMove) {
          // разобраться, почему доска не перестраивается
          return move.prevMove;
        }
      }

      const selectedOpening = this.selectedOpening;
      if (!selectedOpening) {
        return [];
      }
      const move = selectedOpening.move_uci;
      if (!move) {
        const selectedMistake = this.selectedMistake;
        const prevmove = selectedMistake?.prevmove;
        if (prevmove) {
          return [prevmove.substring(0, 2), prevmove.substring(2, 4)];
        }
      } else {
        return [move.substring(0, 2), move.substring(2, 4)];
      }
      // console.log(JSON.parse(JSON.stringify(selectedOpening)))
      return [];
    },
    stockfishMoves() {
      if (!this.stockFishSync) return [];

      //  console.log(JSON.stringify(this.stockFishStrings))
      return this.stockFishStrings[this.stockfishItemIndex]?.moves || [];
    },
    markStudiedDisabled() {
      const selectedMistake = this.selectedMistake;
      if (!selectedMistake) return true;
      const labels = selectedMistake.labels;
      if (!labels) return false;
      const labelsArray = this.$parseArray(labels);
      return labelsArray.indexOf(1) > -1;
    },
    toggledRow: {
      get() {
        const rowsToggled = this.rowsToggled;
        return (index) => rowsToggled.indexOf(index) > -1;
      },
      set(index) {
        const rowsToggled = this.rowsToggled.slice();

        const curIndex = rowsToggled.indexOf(index);

        if (curIndex === -1) {
          this.rowsToggled = [...rowsToggled, index];
        } else {
          rowsToggled.splice(curIndex, 1);
          this.rowsToggled = rowsToggled;
        }
      },
    },
    menuItems() {
      return [
        {
          title: this.$t('accountMenu.settings'),
          id: 'settings',
        },
        {
          title: this.$t('accountMenu.preferences'),
          id: 'preferences',
        },
      ];
    },
    treeModes() {
      return [
        {
          id: 1,
          title: this.$t('opening1.barModeResults'),
        },
        {
          id: 2,
          title: this.$t('opening1.barModeDecisions'),
        },
      ];
    },

    visibilityFilters() {
      const { TOMode, practiceMode, emphasisMode } = this;

      return { TOMode, practiceMode, emphasisMode };
    },

    useStockFish: {
      get() {
        return this.stockFishSync;
      },
      set(val) {
        if (!val) this.$stockfish?.postMessage('stop');
        localStorage.setItem('useStockfish', val ? '1' : '0');
        this.stockFishSync = val;
      },
    },
    getBrush() {
      const selectedOpening = this.selectedOpening;
      const vw = this.$store.state.sizes.vw;
      const tileSize = ((vw / 100) * 18) / 8;

      if (!selectedOpening) return {};
      const getFlatArray = this.getFlatArray || [];

      const target = this.$deepFind({
        array: getFlatArray,
        key: 'nodethis',
        value: selectedOpening?.nodethis,
      });
      if (!target) return {};

      const children = target.children;

      if (!children || children.length === 0) {
        return {};
      }

      const brushes = children.reduce((acc, child) => {
        const { b_arrow_color, b_arrow_thickness } = child;

        acc[b_arrow_color] = {
          key: b_arrow_color,
          color: b_arrow_color,
          opacity: 1,
          // lineWidth: Math.round(20 * (b_arrow_thickness / 100)),
          // lineWidth: Math.round(b_arrow_thickness / 100 * tileSize),
          lineWidth: Math.round(tileSize * 0.35 * (b_arrow_thickness / 100)),
        };
        return acc;
      }, {});
      return brushes;
    },
    compareFilters() {
      const userPreferences = this.userPreferences;
      if (!userPreferences) return null;
      const compareObj = {
        pgid: userPreferences?.compare_pgid,
        avelo:
          (typeof userPreferences?.compare_elo === 'string'
            ? this.$parseArray(userPreferences?.compare_elo)
            : userPreferences?.compare_elo) || [],
        categories:
          (typeof userPreferences?.compare_tc === 'string'
            ? this.$parseArray(userPreferences?.compare_tc)
            : userPreferences?.compare_tc) || [],
        elodiff:
          (userPreferences?.compare_ed?.indexOf('{') > -1
            ? this.$parseArray(userPreferences?.compare_ed).join(',')
            : userPreferences?.compare_ed) || [],
      };
      return compareObj;
    },
    getSelectedOpeningArrows() {
      const selectedOpening = this.selectedOpening;
      if (!selectedOpening) return [];

      const selectedStockFishArr = this.selectedStockFishArr;

      if (selectedStockFishArr?.moves) {
        return [];
      }

      const customMovesArr = this.customMovesArr;

      if (customMovesArr.length > 0) {
        return [];
      }
      const getFlatArray = this.getFlatArray || [];

      const target = this.$deepFind({
        array: getFlatArray,
        key: 'nodethis',
        value: selectedOpening?.nodethis,
      });
      if (!target) return [];

      const children = target.children;

      if (!children || children.length === 0) {
        return [];
      }

      const arrows = children.reduce((acc, child) => {
        if (!child.visible) {
          return acc;
        }
        const coords = this.getMoveCoords(child.move_uci);
        if (!coords?.[0] || !coords?.[1]) return acc;
        acc.push({
          orig: coords[0],
          dest: coords[1],
          brush: child.b_arrow_color,
        });
        return acc;
      }, []);

      return arrows;
    },
    orientation() {
      const rootEl = this.preparedTree;

      if (rootEl) {
        return rootEl.hm % 2 === 0 ? 'white' : 'black';
      }
      return 'white';
    },
    optionsDialogModel() {
      return this.dialogType ? true : false;
    },
    getFullNode() {
      const selectedOpening = this.selectedOpening;
      const preparedTree = this.preparedTree;

      if (!selectedOpening || !preparedTree) {
        return null;
      }
      const searchResult = deepObjectSearch({
        target: [preparedTree],
        key: 'nodethis',
        value: selectedOpening?.nodethis,
      });

      if (searchResult && searchResult[0] && searchResult[0].target) {
        return searchResult[0].target;
      }
      if (selectedOpening.id && selectedOpening.id === preparedTree.id) {
        return preparedTree;
      }
      return null;
    },
    heroid() {
      return this.$route.params.heroid;
    },
    heroCategories() {
      return this.$route.params.categories;
    },
    weakItems() {
      const selectedOpening = this.selectedOpening;
      if (!selectedOpening) return [];

      return [];
    },
    treeData: {
      set(val) {
        this.SET_DATA_BY_KEY({ key: 'openingsTree', value: val });
      },
      get() {
        return this.openingsTree;
      },
    },
    getFlatArray() {
      const tree = this.preparedTree;

      const openedNodesModel = this.openedNodesModel;
      if (!tree) return [];
      if (!openedNodesModel(tree)) {
        return [[tree]];
      }
      const reducer =
        (iteration, parentIndex = 0) =>
        (acc, item, index) => {
          if (!acc[iteration]) {
            acc[iteration] = [];
          }

          if (!item.visible) {
            return acc;
          }
          acc[iteration].push({ ...item, parentIndex: parentIndex });

          if (!openedNodesModel(item)) {
            return acc;
          } else {
            if (!item.children) {
              item.children = [];
            } else {
              item.children.reduce(reducer(iteration + 1, index), acc);
            }
          }

          return acc;
        };

      const result = tree.children.reduce(reducer(1, 0), [[tree]]);

      return result;
    },
    getHeroName() {
      const { heroesList, heroid } = this;
      if (!heroesList) return '';

      return heroesList.find((hero) => hero.hero_id == heroid)?.name || '';
    },
    getCompareString() {
      const compareFilters = this.compareFilters;
      if (!compareFilters || Object.keys(compareFilters).length === 0)
        return '';

      const { pgid, avelo, categories, elodiff } = compareFilters;
      const name = this.getHeroName;

      if (pgid === 1) {
        return this.$t('playground.pg1');
      }

      const siteMap = {
        1: this.$t('playground.pg1'),
        2: this.$t('playground.pg2'),
        3: this.$t('playground.pg3'),
      };

      const aveloMap = {
        2100: this.$t('compare.avelo1'),
        2500: this.$t('compare.avelo2'),
        2900: this.$t('compare.avelo3'),
      };

      const categoriesMap = {
        2: this.$t('compare.tc2_2'),
        3: this.$t('compare.tc2_3'),
        4: this.$t('compare.tc2_4'),
      };

      const elodiffMap = {
        4: this.$t('compare.headered4'),
        '3,4,5': this.$t('compare.headered345'),
        '2,3,4,5,6': this.$t('compare.headered23456'),
      };

      return (
        `${siteMap[pgid]}
      ${avelo.map((i) => aveloMap[i]).join(this.$t('compare.separator1'))}` +
        this.$t('compare.separator3') +
        `${categories
          .map((i) => categoriesMap[i])
          .join(this.$t('compare.separator1'))}
      ${elodiffMap[elodiff]}
      `
      );
    },
    getAddedToBookmarks() {
      const bookMarks = this.bookMarks;
      return (item) =>
        bookMarks.find(
          (i) => i.pos_to_id === item.pos_to_id && i.hm === item.hm,
        );
    },
  },
  methods: {
    ...mapMutations('data', ['SET_DATA_BY_KEY']),
    ...mapActions('data', [
      'getOpenings',
      'loadChildren',
      'getMistakes',
      'modifyPositionLabel',
      'getHeroesList',
      'addToExercises',
      'removeFromExercises',
      'getExercisesList',
      'getHeroVariants',
    ]),
    ...mapActions(['logOutUser']),
    getBg() {
      const maxTotalShortage = this.maxTotalShortage;
      return (nodethis, hm, item) => {
        const minimalRed = 55;
        const maximalRed = 255;
        const totalShortage = item.total_shortage;
        const minVal = localStorage.getItem('minTSVal') || 6000;

        if (item.mdegree === 0) {
          return 'rgb(125,185,125)';
        }

        const redNum =
          minimalRed +
          Math.round(
            (maximalRed - minimalRed) *
              Math.log2(
                1 +
                  (Math.min(Number(minVal), totalShortage) - 30) /
                    (Math.min(Number(minVal), maxTotalShortage) - 30),
              ),
          );

        // const evaluation = (
        //   Math.round(item.total_shortage / (item.total_count || 1)) / 100
        // ).toFixed(2);

        // function getColor(value) {
        //   // value = Math.max(0, Math.min(1, value));
        //   // const r = Math.round(55 + value * (255 - 55));
        //   const r = value;

        //   return `rgb(${r}, ${g}, ${b})`;
        // }
        const g = 55;
        const b = 55;
        return `rgb(${redNum}, ${g}, ${b})`;
        // if (selectedNodes.findIndex((i) => i.nodethis === nodethis) > -1) {
        //   return 'rgba(255,155,155,1)';
        // }
        // if (
        //   getFullSelectedPath.findIndex((i) => i.nodethis === nodethis) > -1
        // ) {
        //   return 'rgba(255,195,195,0.7)';
        // }
        // //если вражьи ходы
        // if (this.isEnemyMove(hm)) {
        //   return 'rgba(	152, 152, 152, 1)';
        // }

        // if (
        //   getSubTrees?.[currentSubTree].find((i) => i?.nodethis === nodethis)
        // ) {
        //   return 'rgba(	52, 170, 220, .9)';
        // }

        // return 'rgba(	52, 170, 220, .7)';
      };
    },
    async handleMistakeClick(item, mistake) {
      this.$refs.board.resetBoard();

      this.SET_DATA_BY_KEY({ key: 'selectedMistake', value: mistake });
      this.boardDisabled = false;
      //  console.log(JSON.parse(JSON.stringify(item)))
      this.unfinishedId = undefined;
      this.originalFen = null;
      this.originalLastMove = null;
      this.currentFen = null;
      this.gameId = undefined;
      this.mode = 0;
      this.gameMoves = [];
      this.treeData = null;
      this.currentSubTree = undefined;
      this.currentSubTreeNode = undefined;

      this.trainId = item.id;
      // this.$router.push({
      //   name: 'ExercisePage',
      //   params: {
      //     heroid: this.$route.params.heroid,
      //     posid: item.position_id,
      //     categories: this.$route.params.categories,
      //     herowhite: item.herowhite,
      //   },
      // });

      this.originalFen = item.fen;
      this.selectedTraining = item;
      if (item.prevmove) {
        this.originalLastMove = [
          item.prevmove.substring(0, 2),
          item.prevmove.substring(2, 4),
        ];
      } else {
        this.originalLastMove = null;
      }

      const resp = await axios({
        method: 'post',
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        data: {
          mtd: 'training/load',
          id: this.trainId,
          // mtd: 'leading/new',
          // heroid: this.heroid,
          // posid: this.posid,
          // herowhite: Number(this.$route.params.herowhite),
          // barrier: 250,
          // depthfilter: 20,
        },
      }).catch((e) => console.log(e));

      // axios({
      //       method: 'post',
      //       headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      //       url: `${process.env.VUE_APP_DEV_API}/api/`,
      //       data: {
      //         mtd: 'help',
      //       },
      //     })

      if (resp && resp.data) {
        console.time('prepareTree');
        this.preparedTree = await this.prepareTree(resp.data);
        console.timeEnd('prepareTree');

        this.prepareScores();
        // this.gameId = resp.data.leading_id;
        // this.gameMoves = resp.data.puzzles;
        // this.currentIndex = 0;
      }
    },
    copyFenToClipboard() {
      const board = this.$refs.bigBoard;
      let fen = '';
      if (board) {
        fen = board.getFen();
      }
      function copyToClipboard(text) {
        navigator.clipboard
          .writeText(text)
          .then(() => {
            console.log('Text copied to clipboard');
          })
          .catch((err) => {
            console.error('Error copying text: ', err);
          });
      }
      copyToClipboard(fen);
    },
    handleContextMenu({ e, item }) {
      this.contextMenu = {
        top: e.offsetY,
        left: e.offsetX,
        item,
        type: 'context',
      };
    },
    updateMode(key, val) {
      this[key] = val;
    },
    handleUpdateHiddenNodes() {},
    handleSandwichClick(e, item) {
      this.contextMenu = {
        top: e.offsetY,
        left: e.offsetX,
        item,
        type: 'sandwich',
      };
    },
    handleCustomMouseLeave(e) {
      // (!e.target.dataset || e.target.dataset && e.target.dataset.parent != 1) ? toggleTooltip() : null
    },
    toggleNodeTraining(node) {
      if (!node.pos_to_id) return;

      const statusStates = [1, 0, 0];

      const newNodeStatus = statusStates[node.status];
      const rootColor = this.openingsTree.hm % 2 === 0 ? 'b' : 'w';
      const heromove =
        (rootColor === 'b' && node.hm % 2 !== 0) ||
        (rootColor === 'w' && node.hm % 2 === 0)
          ? 1
          : 0;

      this.handleListboxClick(
        {
          mtd: 'openings/setmovestatus',
          status: newNodeStatus,
          heromove,
        },
        node,
      );

      const clone = cloneDeep(this.treeData);
      const targets = deepObjectSearch({
        target: [clone],
        cb: (i) =>
          i.pos_from_id === node.pos_from_id && i.pos_to_id === node.pos_to_id,
      });

      if (targets) {
        for (let u = 0; u < targets.length; u++) {
          targets[u].target.status = newNodeStatus;
          // targets[u].target.node_backlight = resp.node_backlight;
        }

        const target = targets.find(
          (i) => i.target.nodethis === node?.nodethis,
        );

        this.treeData = clone;
        if (
          this.selectedOpening &&
          this.selectedOpening.nodethis === target.target.nodethis
        ) {
          this.SET_DATA_BY_KEY({
            key: 'selectedOpening',
            value: target.target,
          });
        }

        if (
          this.contextMenu &&
          this.contextMenu.item.modethis === target.target.nodethis
        ) {
          this.contextMenu = { ...this.contextMenu, item: target.target };
        }

        // updateNode(target, val)
      }
    },
    resetStockFishArr() {
      this.selectedStockFishArr = [];
      this.currentStockFishIndex = undefined;
      this.currentStockFishFigurineIndex = undefined;
      this.customMove = '';
    },
    addUserMoveToStockFishArr({ fen, moveName, originalMove }) {
      const selectedMistake = this.selectedMistake;
      if (!this.selectedStockFishArr || !this.selectedStockFishArr.moves) {
        const tmp = {
          moves: [
            {
              moves: [],
              moveStr: `${
                (this.selectedMistake.hm - (this.selectedMistake.hm % 2)) / 2
              }${this.selectedMistake.hm % 2 === 0 ? '...' : '.'}`,
            },
          ],
        };
        this.currentStockFishIndex = 0;
        this.currentStockFishFigurineIndex =
          this.selectedMistake.hm % 2 === 0 ? 1 : 0;

        tmp.moves[0].moves[0] = {
          fen: selectedMistake.fen,
          moveIndex: selectedMistake.hm % 2 === 0 ? 1 : 0,
          prevMove: selectedMistake.prevmove,
          originalMove: selectedMistake.prevmove,
          moveName: selectedMistake.prevmove,
        };

        this.selectedStockFishArr = tmp;
      }

      const {
        selectedStockFishArr,
        currentStockFishIndex,
        currentStockFishFigurineIndex,
      } = this;

      const current =
        this.selectedStockFishArr?.moves?.[this.currentStockFishIndex]?.moves?.[
          this.currentStockFishFigurineIndex
        ];

      const clone = cloneDeep(this.selectedStockFishArr);

      const getNewObj = () => ({
        custom: true,
        fen: fen,
        moveIndex: current
          ? current.moveIndex + 1
          : selectedMistake.hm % 2 === 0
          ? 1
          : 0,

        moveName, //"♗f2"
        originalMove, // "e3f2"
        prevmove: current ? current.originalMove : selectedMistake.prevmove,
      });

      const getMoveStr = (str) => {
        if (str.indexOf('...' > -1)) {
          const num = Number(str.split('...')[0]);
          return `${num + 1}.`;
        } else {
          const num = Number(str.split('.')[0]);
          return `${num + 1}.`;
        }
      };

      if (current?.custom !== true) {
        clone.moves = selectedStockFishArr?.moves?.slice(
          0,
          currentStockFishIndex + 1,
        );
      }
      const newObj = getNewObj();

      if (currentStockFishFigurineIndex === 1) {
        console.log(
          'currentStockFishIndex',
          currentStockFishIndex,
          'this.selectedStockFishArr?.moves',
          this.selectedStockFishArr?.moves,
        );
        clone.moves.push({
          moveStr: getMoveStr(
            this.selectedStockFishArr?.moves?.[currentStockFishIndex].moveStr,
          ),
          moves: [newObj],
        });
      } else {
        clone.moves[currentStockFishIndex].moves[1] = newObj;
      }

      this.selectedStockFishArr = clone;

      if (currentStockFishFigurineIndex === 1) {
        this.currentStockFishIndex += 1;
      }
      this.currentStockFishFigurineIndex =
        currentStockFishFigurineIndex === 1 ? 0 : 1;

      console.log(this.selectedStockFishArr);
    },
    prepareStockFishArray(array) {
      const clone = cloneDeep(array);

      const selectedMistake = this.selectedMistake;
      const customMove = this.customMove;
      // console.log(array, selectedMistake)
      const sfChess = this.sfChess;
      sfChess.load(customMove || selectedMistake.fen);
      let lastMove = customMove ? null : selectedMistake.prevmove;
      clone.moves.forEach((item) => {
        item.moves.forEach((move) => {
          sfChess.move(
            move.originalMove.substring(0, 2) +
              '-' +
              move.originalMove.substring(2, 4),
          );
          move.prevmove = lastMove;
          lastMove = move.originalMove;
          move.fen = sfChess.fen();
        });
      });

      return clone;
    },
    async handleListboxClick(params, node) {
      const selectedOpening = node || this.selectedOpening;
      if (
        !selectedOpening ||
        !selectedOpening.pos_to_id ||
        !selectedOpening.pos_from_id
      )
        return;
      const heroid = Number(this.heroid);
      const postoid = selectedOpening.pos_to_id;
      const posfromid = selectedOpening.pos_from_id;
      const baseParams = {
        heroid,
        postoid,
        posfromid,
      };
      const resp = await axios({
        method: 'post',
        url: `${process.env.VUE_APP_DEV_API}/api/`,
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        data: { ...baseParams, ...params },
      });

      if (resp && resp.data) {
        return resp.data;
      } else {
        return null;
      }
    },
    removeFromTest() {
      const selectedMistake = this.selectedMistake;

      if (!selectedMistake) return;

      this.removeFromExercises({
        heroid: Number(this.heroid),
        roots: [
          {
            posid: selectedMistake.position_id,
            herowhite: selectedMistake.herowhite,
          },
        ],
      });
    },
    addToTest() {
      const selectedMistake = this.selectedMistake;

      if (!selectedMistake) return;

      this.addToExercises({
        heroid: Number(this.heroid),
        depth: 30,
        barrier: 250,
        posid: selectedMistake.position_id,
        herowhite: selectedMistake.herowhite,
        // roots: [
        //   {
        //     posid: selectedMistake.position_id,
        //     herowhite: selectedMistake.herowhite,
        //   },
        // ],
      });
    },
    async toggleNode(node, preventGoToChild = true, count) {
      const id = node.nodethis;

      const isOpened = this.openedNodesModel(node);

      if (isOpened) {
        //  this.openedNodesModel = node;
      } else {
        const currentNode = deepFind({
          array: [this.preparedTree],
          value: id,
          key: 'nodethis',
        });
        const hasChildren = currentNode?.children?.length > 0;

        if (hasChildren) {
          //  this.openedNodesModel = node;
        } else {
          await this.loadChildren({
            _node_id: id,
            auto: false,
            heroid: this.$route.params.heroid,
            needChangeStatus: false,
            herocats: this.$route.params.categories,
            hm: node.hm,
            posid: Number(node.posid || node.pos_to_id),
            nodeid: node.nodethis,
            count,
          });
        }
      }
      this.openedNodesModel = node;
      console.time('prepare tree');
      const prepared = await this.prepareTree(this.treeData);
      console.timeEnd('prepare tree');
      this.preparedTree = prepared;
      await new Promise((res) => setTimeout(res, 100));
      const newNode = deepFind({
        array: [prepared],
        value: id,
        key: 'nodethis',
      });

      if (newNode && newNode.children.length > 0 && !preventGoToChild) {
        // if (movesModel === 2) {
        for (let child of newNode.children) {
          if (child.visible === true) {
            this.goToNode(child);
            return;
          }
        }
        // } else {
        //   this.goToNode(newNode.children[0]);
        // }
      } else {
        this.goToNode(newNode);
      }
    },
    handleToogleBookmark(item) {
      const bookMarks = this.bookMarks;
      const index = bookMarks.findIndex(
        (i) => i.pos_to_id === item.pos_to_id && i.hm === item.hm,
      );
      const clone = cloneDeep(bookMarks);
      if (index === -1) {
        clone.push(item);
      } else {
        clone.splice(index, 1);
      }

      this.bookMarks = clone;
    },
    goToBookmark(item) {
      this.handleSelectOpening(item);
      this.goToNode(item);
    },
    resetBoard() {
      this.preventedByCustomMove = false;
      if (this.$refs.bigBoard?.resetBoard) {
        this.$refs.bigBoard?.resetBoard();
        this.customMove = null;
        this.customMovesArr = [];
        this.currentCustomMoveIndex = undefined;
      }

      if (this.selectedOpening && this.originalOpening) {
        this.SET_DATA_BY_KEY({
          key: 'selectedOpening',
          value: this.originalOpening,
        });
      }

      if (this.$stockfish) {
        this.$stockfish?.postMessage('stop');
        this.stockFishStrings = [];
        this.stockfishCalculationsCount = 0;

        this.$stockfish.onmessage = this.handleStockFishMessage;

        if (this.originalOpening) {
          this.$stockfish.postMessage(
            `position fen ${this.originalOpening.fen}`,
          );
          this.$stockfish.postMessage('go infinite');
        }
      }
    },
    handleLogOut() {
      const cb = () => this.$router.push('/login');
      this.logOutUser(cb);
    },
    async goToNode(node, offset = { top: 0, left: 0 }) {
      await new Promise((res) => setTimeout(res, 50));
      const tree = this.$refs.tree;

      if (!tree) {
        return;
      }

      const coords = tree.getCoordsFromDict(node.nodethis);

      if (coords) {
        const container = this.$refs.treeContainer;

        if (container) {
          container.scrollTo({
            left: coords[0] + offset.left,
            top: coords[1] + offset.top,
            behavior: 'smooth',
          });
        }

        // el.scrollIntoView({
        //   behavior: 'smooth',
        //   block: 'end',
        //   inline: 'end',
        // });
      }
    },
    handleStockFishStringChange(direction) {
      const {
        currentStockFishIndex,
        currentStockFishFigurineIndex,
        selectedStockFishArr,
      } = this;
      const moves = selectedStockFishArr.moves;
      if (direction === 'up' || direction === 'down') {
        return;
      }

      if (direction === 'right') {
        if (currentStockFishFigurineIndex === 1) {
          const nextMove = moves?.[currentStockFishIndex + 1]?.moves?.[0];
          if (!nextMove) {
            return;
          }
          this.currentStockFishIndex += 1;
          this.currentStockFishFigurineIndex = 0;
        } else {
          const nextMove = moves?.[currentStockFishIndex]?.moves?.[1];
          if (!nextMove) {
            return;
          }
          // this.stockFishFen = nextMove.fen
          this.currentStockFishFigurineIndex = 1;
        }
      }

      if (direction === 'left') {
        if (currentStockFishFigurineIndex === 1) {
          const nextMove = moves?.[currentStockFishIndex]?.moves?.[0];
          if (!nextMove) {
            return;
          }
          // this.stockFishFen = nextMove.fen
          this.currentStockFishFigurineIndex = 0;
        } else {
          const nextMove = moves?.[currentStockFishIndex - 1]?.moves?.[1];
          if (!nextMove) {
            return;
          }
          this.currentStockFishIndex -= 1;
          this.currentStockFishFigurineIndex = 1;
        }
      }

      return;
    },
    handleCustomMoveChange(direction) {
      if (direction === 'up' || direction === 'down') {
        return;
      }
      if (direction === 'right') {
        if (this.currentCustomMoveIndex === this.customMovesArr.length - 1) {
          return;
        }

        this.currentCustomMoveIndex++;
      }

      if (direction === 'left') {
        if (this.currentCustomMoveIndex === 0) {
          return;
        }

        this.currentCustomMoveIndex--;
      }

      const newMove = this.customMovesArr[this.currentCustomMoveIndex];

      if (newMove) {
        this.customMove = newMove.fen;

        if (this.useStockFish && this.$stockfish) {
          this.stockfishCalculationsCount = 0;
          this.$stockfish.postMessage('stop');
          this.stockFishStrings = [];
          this.$stockfish.postMessage(`position fen ${newMove.fen}`);
          this.$stockfish.postMessage('go infinite');
        }
      }
    },
    handleArrowPress(direction) {
      if (this.currentStockFishIndex !== undefined) {
        this.handleStockFishStringChange(direction);
        return;
      }

      if (this.customMovesArr.length > 0) {
        this.handleCustomMoveChange(direction);
        return;
      }

      const selectedOpening = this.getFullNode;
      const openedNodesModel = this.openedNodesModel;

      if (!selectedOpening) return;

      if (direction === 'right') {
        if (selectedOpening.iscrown === 1 || selectedOpening.iscrown === null)
          return;

        if (
          openedNodesModel(selectedOpening) &&
          selectedOpening.children.length > 0
        ) {
          const children = selectedOpening.children;

          for (let child of children) {
            if (child.visible === true) {
              this.handleSelectOpening(child);
              // this.goToNode(child, {left: -(this.blockWidth * 2)})
              return;
            }
          }

          // this.goToNode(selectedOpening.children[0]);
          return;
        } else {
          this.toggleNode(selectedOpening, true);
          // this.openedNodesModel =selectedOpening
          // this.loadChildren({
          //   _node_id: selectedOpening.id,
          //   preventOpen: false,
          //   heroid: this.heroid,
          //   herocats: this.heroCategories,
          // });
          return;
        }
      }

      const searchResult = deepObjectSearch({
        target: [this.preparedTree],
        key: 'nodethis',
        value: selectedOpening.nodeparent,
      });

      if (!searchResult || !searchResult[0] || !searchResult[0].target) {
        return;
      }

      const parent = searchResult[0].target;

      if (direction === 'left') {
        this.handleSelectOpening(parent);
        this.goToNode(parent);

        return;
      }

      const siblings = parent.children;

      if (siblings.length === 1) {
        return;
      }

      const currentIndex = siblings.findIndex(
        (el) => el.nodethis === selectedOpening?.nodethis,
      );

      if (currentIndex === -1) {
        return;
      }

      if (direction === 'up') {
        const newIndex = currentIndex - 1;
        if (newIndex < 0 || !siblings[newIndex]) {
          return;
        }

        for (let i = newIndex; i >= 0; i--) {
          const sibling = siblings[i];
          if (sibling && sibling.visible === true) {
            this.handleSelectOpening(sibling);
            this.goToNode(sibling);
            return;
          }
        }

        return;
      }

      if (direction === 'down') {
        const newIndex = currentIndex + 1;
        if (newIndex > siblings.length - 1 || !siblings[newIndex]) {
          return;
        }

        for (let i = newIndex; i < siblings.length; i++) {
          const sibling = siblings[i];
          if (sibling && sibling.visible === true) {
            this.handleSelectOpening(sibling);
            this.goToNode(sibling);
            return;
          }
        }

        return;
      }
    },
    getEyeColor(labels) {
      if (!labels) return 'grey';
      const has3 = labels.indexOf(3) > -1;
      const has2 = labels.indexOf(2) > -1;

      if (has2 || has3) return 'black';
      return 'grey';
    },
    getEyeIcon(labels) {
      if (!labels || labels.indexOf(2) > -1) {
        return 'mdi-eye';
      }
      return 'mdi-eye-off';
    },
    async setCurrentTreeToDB() {
      const selectedMistake = this.selectedMistake;

      if (selectedMistake && localStorage.getItem('useIDB') == 1) {
        const obj = {
          position_id: selectedMistake.position_id,
          tree: cloneDeep(this.preparedTree),
          openedNodesArr: cloneDeep(this.openedNodesArr),
          selectedOpening: cloneDeep(this.selectedOpening),
        };

        const localDb = await getDb();
        if (localDb) {
          const tx = localDb.transaction('tree', 'readwrite');

          const store = tx.objectStore('tree');

          store.put(obj);

          // const index = store.index('position_id');

          // if (index) {
          //   const request = index.get(selectedMistake.position_id);
          //   request.onsuccess = function () {
          //     const matching = request.result;
          //     if (matching !== undefined) {
          //       // A match was found.
          //       console.log(matching.tree);
          //     } else {
          //       // No match was found.

          //       console.log(null);
          //     }
          //   };
          // }
        }
      }
    },
    async handleMistakeClick(item) {
      this.SET_DATA_BY_KEY({ key: 'selectedMistake', value: item });
    },
    handleEyeClick(item) {
      const labelsArray = this.$parseArray(item.labels);
      const heroid = this.heroid;
      if (labelsArray.indexOf(2) > -1) {
        this.modifyPositionLabel({
          opening: item,
          scenario: 'toIgnore',
          heroid,
          herocats: this.heroCategories,
        });
        return;
      }

      if (labelsArray.indexOf(3) > -1) {
        this.modifyPositionLabel({
          opening: item,
          scenario: 'toDefault',
          heroid,
          herocats: this.heroCategories,
        });
        return;
      }

      this.modifyPositionLabel({
        opening: item,
        scenario: 'toWatchlist',
        heroid,
        herocats: this.heroCategories,
      });
    },
    markStudied(item) {
      this.modifyPositionLabel({
        opening: item,
        scenario: 'toStudied',
        heroid: this.heroid,
        herocats: this.heroCategories,
      });
    },
    handleMouseOver(
      e,
      { move, itemIndex, moveIndex, useCustomStockfish = false },
    ) {
      if (!useCustomStockfish) {
        this.stockfishItemIndex = itemIndex;
        this.stockfishMoveIndex = moveIndex;
      }
      this.stockfishItemIndex = itemIndex;
      this.stockfishMoveIndex = moveIndex;

      if (!this.moveBoard) {
        this.moveBoard = {
          top: e.pageY,
          move,
          movesArrayIndex: moveIndex,
          useCustomStockfish,
        };
      } else {
        if (this.moveBoard.move == move) return;
        this.moveBoard = {
          top: e.pageY,
          move,
          movesArrayIndex: moveIndex,
          useCustomStockfish,
        };
      }
    },
    handleMouseLeave() {
      this.moveBoard = null;
    },
    validateStockFishResponse(str) {
      if (!str) return;
      const has_pv = (str) => str.indexOf(' pv ') > -1;
      if (!has_pv(str)) return true;
      if (str.indexOf(' bound ') > -1) return true;
      return false;
    },
    getMoveCoords(str) {
      if (!str) return [];
      return [str.substring(0, 2), str.substring(2, 4)];
    },
    handleSelectOpening(opening) {
      this.resetStockFishArr();
      this.customMove = null;
      this.customMovesArr = [];
      this.currentCustomMoveIndex = undefined;

      this.SET_DATA_BY_KEY({ key: 'selectedOpening', value: opening });

      // const node = this.$deepFind({
      //   array: this.getFlatArray || [],
      //   key: 'id',
      //   value: opening.id,
      // });
      this.originalOpening = opening;
    },
    handleToggleExercise(mistake) {
      const exercisesList = this.exercisesList;

      if (!exercisesList) {
        return;
      }

      const occur = exercisesList.find(
        (i) =>
          i.position_id === mistake.position_id &&
          i.herowhite === mistake.herowhite,
      );

      if (occur) {
        return this.removeFromExercises({
          id: occur.id,
          heroid: Number(this.heroid),
        });
      } else {
        return this.addToExercises({
          heroid: Number(this.heroid),
          depth: 30,
          barrier: 250,
          posid: mistake.position_id,
          herowhite: mistake.herowhite,
        });
      }
    },
    async gotToExsercise() {
      const mistake = this.selectedMistake;
      if (
        !this.exercisesList.find(
          (i) =>
            i.position_id === mistake.position_id &&
            i.herowhite === mistake.herowhite,
        )
      ) {
        await this.handleToggleExercise(mistake)
          .then((res) => {
            this.$router.push({
              name: 'ExercisePage',
              params: {
                heroid: this.$route.params.heroid,
                posid: mistake.position_id,
                categories: this.$route.params.categories,
                herowhite: mistake.herowhite,
              },
            });
          })
          .catch((e) => {
            this.SET_DATA_BY_KEY({
              key: 'appToast',
              value: 'Слишком мало позиций для построения дерева',
            });
          });
      } else {
        this.$router.push({
          name: 'ExercisePage',
          params: {
            heroid: this.$route.params.heroid,
            posid: mistake.position_id,
            categories: this.$route.params.categories,
            herowhite: mistake.herowhite,
          },
        });
      }
    },
    getParentCoords(yIndex, column, allItems, columnIndex) {
      const parentSlice = allItems[columnIndex - 1];
      let result = 0;
      if (parentSlice) {
        const parentIndex = parentSlice.findIndex(
          (item) => item.nodethis === column[yIndex]?.nodeparent,
        );
        if (parentIndex === -1) return 0;
        const parentTop = this.getPaddingTop(
          parentIndex,
          parentSlice,
          allItems,
          columnIndex - 1,
        );
        result = parentTop;
      }
      return result;
    },
    getPaddingTop(yIndex, column, allItems, columnIndex) {
      const movesModel = this.movesModel;
      //go back and find the parent, then calculate the top padding for it
      const coordsY = this.getParentCoords(
        yIndex,
        column,
        allItems,
        columnIndex,
      );
      let result = coordsY;

      for (let i = 0; i < yIndex; i++) {
        const neightbourItem = column[i];
        if (neightbourItem.nodeparent !== column[yIndex].nodeparent) {
          continue;
        }
        const neightbourCount = neightbourItem.visibleChildren;

        result +=
          (neightbourCount ? neightbourCount : 1) * (this.blockHeight + 8);
      }

      return result;
    },
    updateNodeVisibility({ node, nodeIndex, parent, siblings }) {
      const permanentlyHiddenNodes = this.permanentlyHiddenNodes;
      if (permanentlyHiddenNodes[node.nodethis]) {
        node.visible = false;
        return;
      }
      // const TOmode = this.TOMode; // 0,1,2
      // const practiceMode = this.practiceMode; // 0,1,2
      // const emphasisMode = this.emphasisMode; //0,1,2,3
      const { TOMode, practiceMode, emphasisMode } = this.visibilityFilters;
      const updateParentVisibleChildren = () => {
        // parent.visibleChildren++;
        // let parentNode = parent;
        // // const visibleCount = parentNode.children.reduce((acc, item) => {
        // //             if(item.visible) {
        // //               acc+= 1
        // //             }
        // //             return acc
        // //           }, 0)
        // //           if(visibleCount === 1) {
        // //             return
        // //           }
        // while (parentNode) {
        //   parentNode.visibleChildren++;
        //   parentNode = parentNode?.parentObject;
        // }
      };

      // if(node.autoOpened) {
      //   node.visible = true;
      //   delete node.autoOpened
      // }

      if (node.hidden === 2) {
        return;
      }

      if (node.hidden === 0 || !parent) {
        if (!node.visible) {
          node.visible = true;
        }
        return;
      }

      // при выборе opening1.tOIgnore не имеет значения пойдёт вершина в тренировку или нет, перечень вершин по этому фильтру пуст (то есть дерево будет строиться только по значениям других выпадашек)
      const handleTo0 = () => {};

      // При выборе opening1.tOBest в перечень к отображению должна попасть та из тренировочных позиций (синих или зелёных вершин), которая в приходящем от API дереве стоит выше.
      const handleTo1 = () => {
        //чем меньше waste - тем лучше ход
        const sorted = siblings.slice().sort((a, b) => a.waste - b.waste);

        if (
          sorted.find((item) => {
            const cond1 = item.visible === true;
            const cond2 =
              item.status === 0 ||
              (item.status === 2 && item.default_training === 1);
            return cond1 && cond2;
          })
        ) {
          return;
        }

        for (let i = 0; i < sorted.length; i++) {
          const currentSibling = sorted[i];
          if (
            currentSibling &&
            currentSibling.visible &&
            !permanentlyHiddenNodes[currentSibling.nodethis]
          ) {
            return;
          }

          if (
            currentSibling.status === 0 ||
            (currentSibling.status === 2 &&
              currentSibling.default_training === 1)
          ) {
            if (
              !currentSibling.visible &&
              !permanentlyHiddenNodes[currentSibling.nodethis]
            ) {
              currentSibling.visible = true;
              return;
            }
          }
        }

        // if (nodeIndex > 0) {
        //   for (let i = nodeIndex - 1; i >= 0; i--) {
        //     const currentSibling = sorted[i];

        //     if (
        //       currentSibling &&
        //       currentSibling.visible &&
        //       (currentSibling.status === 0 ||
        //         (currentSibling.status === 2 &&
        //           currentSibling.default_training === 1))
        //     ) {
        //       return;
        //     }
        //   }

        //   if (
        //     node.status === 0 ||
        //     (node.status === 2 && node.default_training === 1)
        //   ) {
        //     if (!node.visible) {
        //       node.visible = true;
        //     }
        //   }
        // }
      };
      // При выборе opening1.tOAll все тренировочные позиции отображаются в перечне.
      const handleTo2 = () => {
        if (
          node.status === 0 ||
          (node.status === 2 && node.default_training === 1)
        ) {
          if (!node.visible) {
            node.visible = true;
            updateParentVisibleChildren();
          }
        }
      };

      const TOhandlers = [handleTo0, handleTo1, handleTo2];

      const { hm } = node;
      const herowhite = this.selectedMistake.herowhite;

      const oppMove =
        (hm % 2 === 0 && herowhite === 1) || (hm % 2 !== 0 && herowhite === 0);

      // при выборе opening1.practiceIgnore не имеет значения встречалась ли данная вершина в партиях героя или нет, перечень вершин по этому фильтру пуст.
      const handlePractice0 = () => {};
      // При выборе opening1.practiceHero в перечень к отображению должны попасть те вершины,
      // которые возникали в практике героя после хода героя.
      const handlePractice1 = () => {
        // если не ход героя и move_count_hero !== 0

        if (oppMove && node.move_count_hero !== 0) {
          if (!node.visible) {
            node.visible = true;
            // updateParentVisibleChildren();
          }
        }
      };
      //При выборе opening1.practiceAll все встречавшиеся в практике героя вершины отображаются в перечне.
      const handlePractice2 = () => {
        // если ход героя, move_count_hero !== 0
        if (node.move_count_hero !== 0) {
          if (!node.visible) {
            node.visible = true;
            // updateParentVisibleChildren();
          }
        }
      };

      const practiceHandlers = [
        handlePractice0,
        handlePractice1,
        handlePractice2,
      ];

      //при выборе opening1.emphasisIgnore не имеет значения какой emphasis (акцент) у вершины, перечень вершин по этому фильтру пуст
      const handleEmphasis0 = () => {};
      //При выборе opening1.emphasis3 в перечень попадают все вершины с emphasis=3
      const handleEmphasis1 = () => {
        if (node.emphasis === 3) {
          if (!node.visible) {
            node.visible = true;
            updateParentVisibleChildren();
          }
        }
      };
      //при выборе opening1.emphasis2 в перечень попадают все вершины с emphasis in (2, 3)
      const handleEmphasis2 = () => {
        if (node.emphasis >= 2) {
          if (!node.visible) {
            node.visible = true;
            updateParentVisibleChildren();
          }
        }
      };
      //при выборе opening1.emphasis1 в перечень попадают все вершины с emphasis in (1, 2, 3)
      const handleEmphasis3 = () => {
        if (node.emphasis >= 1) {
          if (!node.visible) {
            node.visible = true;
            updateParentVisibleChildren();
          }
        }
      };

      const emphasisHandlers = [
        handleEmphasis0,
        handleEmphasis1,
        handleEmphasis2,
        handleEmphasis3,
      ];

      TOhandlers[TOMode]();
      if (node.visible) {
        return;
      }
      practiceHandlers[practiceMode]();
      if (node.visible) {
        return;
      }

      emphasisHandlers[emphasisMode]();
    },
    async prepareTree(obj) {
      const permanentlyHiddenNodes = this.permanentlyHiddenNodes;
      if (!obj || this.autoLoadingProgress !== null) return;
      if (this.exerciseTree) {
        this.TOMode = 2;
        this.emphasisMode = 1;
      }

      const treeData = {...cloneDeep(obj), visible: true};
      let autoOpenedNodes = [];
      const iterateExersizeChildren = (item) => {
        if (item.autoOpened === true) {
          // if (item.autoOpened === true) {
          delete item.autoOpened;
          if (item.iscrown === 0) {
            autoOpenedNodes.push(item);
          }
          // autoOpenedNodes.push(item);

          // }
        }

        if (item.children) {
          for (let child of item.children) {
            iterateExersizeChildren(child);
          }
        }
      };
      if (this.exerciseTree) {
        iterateExersizeChildren(treeData);
      }

      if (autoOpenedNodes.length > 0) {
        // const filtered = autoOpenedNodes
        //   .slice()
        //   .filter((node) => !this.openedNodesModel(node));
        // console.log('filtered', filtered);
        // console.log('autoOpenedNodes', autoOpenedNodes);

        this.SET_DATA_BY_KEY({ key: 'exerciseTree', value: null });
        this.openedNodesArr = cloneDeep(autoOpenedNodes);
        autoOpenedNodes = [];
      }

      const iterateChildren = (item, index, parent, siblings) => {
        item.nodeparent = parent?.nodethis;
        // item.parentObject = parent;
        item.visibleChildren = 0;
        this.updateNodeVisibility({
          node: item,
          nodeIndex: index,
          parent: parent,
          siblings,
        });
        const isOpened =
          !permanentlyHiddenNodes[item.nodethis] &&
          (this.openedNodesModel(item) ||
            autoOpenedNodes.find((i) => i.nodethis === item.nodethis));
        if (item.children && isOpened && item.visible) {
          item.children = item.children.map((ch) => ({
            ...ch,
            visible: false,
          }));
          item.children?.forEach((child, i, arr) => {
            iterateChildren(child, i, item, arr);
          });
        }
        
      };

      iterateChildren(treeData, 0, null, []);
      // console.log(autoOpenedNodes.length,'autoOpenedNodes.length')

      const openedNodesModel = this.openedNodesModel;

      if (this.openedNodesArr.length === 0) {
        this.openedNodesArr = [treeData];
      }
      const reducer = () => (acc, item) => {
        if (openedNodesModel(item) && !permanentlyHiddenNodes[item.nodethis]) {
          acc += item.children?.reduce(reducer(), 0) || 0;
        } else {
          if (item.visible && !permanentlyHiddenNodes[item.nodethis]) {
            acc++;
          }
        }
        return acc;
      };
      const addOpenedChildrenCount = (item, nodeparent) => {
        item.nodeparent = nodeparent;
        if (!openedNodesModel(item)) {
          item.visibleChildren = 0;
          // item.parent = cloneDeep(parent)
        } else {
          item.visibleChildren = item.children?.reduce(reducer(), 0) || 0;

          //  item.parent =  cloneDeep(parent)
          item.children?.forEach((child) =>
            addOpenedChildrenCount(child, item.nodethis),
          );
        }
      };
      addOpenedChildrenCount(treeData, treeData.nodethis, 0);

      const rootColor = treeData.hm % 2 === 0 ? 'b' : 'w';

      const setBgDisplayOption = (item, parentShown) => {
        const { status, default_training } = item;

        if (parentShown === false) {
          item.showBg = false;
        } else {
          if (status === 0 || (status === 2 && default_training === 1)) {
            item.showBg = true;
          } else if (status === 2 && default_training === 0) {
            item.showBg = false;
          } else {
            item.showBg = false;
          }
        }

        if (item.children) {
          item.children.forEach((child) =>
            setBgDisplayOption(child, item.showBg),
          );
        }
      };

      treeData.children.forEach((child) => setBgDisplayOption(child, true));
      await new Promise((res) => setTimeout(res, 100));
      this.SET_DATA_BY_KEY({ key: 'autoLoading', value: false });
      return treeData;
    },
    handleSearchDefaultPosition() {
      this.searchModel = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq -';
      this.handleSearch();
    },
    handleSearch() {
      this.getOpenings({
        fen: this.searchModel,
        heroid: this.heroid,
        herocats: this.heroCategories,
      });
    },
    handleSearchAfter() {
      this.getMistakes({
        heroid: this.heroid,
        fen: this.searchModel,
        herocats: this.heroCategories,
      });
    },
    prepareString(str) {
      // if (!selectedOpening || !selectedOpening.hm) return '';

      const result = {
        multipv: 0,
        string: '',
        moves: [],
      };
      const obj = getStockFishObj(str);
      if (obj.depth < 14) return '';
      const chess = new Chess(this.customMove || this.selectedOpening?.fen);

      const color = chess.turn();

      if (obj) {
        if (obj.cp) {
          const currentValue = Number((obj.cp / 100).toFixed(2));

          const fixedValue = color === 'b' ? currentValue * -1 : currentValue;

          result.string += `${fixedValue} `;
        } else {
          if (obj.mate) {
            result.string += `# ${obj.mate}`;
          }
        }
        result.moves = obj.pv;
        result.multipv = obj.multipv;
      } else {
        return '';
      }

      return result;
    },
    getMistakeOpeningColor(item) {
      if (!item.labels) return 'grey--text';
    },
    updateNodeEmphasis(node) {
      const clone = cloneDeep(this.treeData);
      const targets = deepObjectSearch({
        target: [clone],

        cb: (i) =>
          i.pos_from_id === node.pos_from_id && i.pos_to_id === node.pos_to_id,
      });
      //           const target = this.$deepFind({
      //             array: clone,
      //             key: 'nodethis',
      //             value: selectedOpening.nodethis,
      //           });
      // console.log('target', target)
      if (targets) {
        for (let u = 0; u < targets.length; u++) {
          targets[u].target.emphasis = 1;
          // targets[u].target.node_backlight = resp.node_backlight;
        }

        const target = targets.find(
          (i) => i.target.nodethis === node?.nodethis,
        );

        this.treeData = clone;
        return target;
      }
    },
    handleCustomeMove(fen, orig, dest) {
      this.customMove = fen;
      // if (this.selectedStockFishArr?.moves) {
      this.addUserMoveToStockFishArr({
        fen,
        moveName: orig + dest,
        originalMove: orig + dest,
      });
      // }
      // else {
      //   const currentCustomMoveIndex = this.currentCustomMoveIndex;

      //   if (currentCustomMoveIndex === undefined) {
      //     this.customMovesArr.push({ fen, prevMove: [orig, dest] });
      //     this.currentCustomMoveIndex = 0;
      //   } else {
      //     if (currentCustomMoveIndex > this.customMovesArr.length - 1) {
      //       const clone = cloneDeep(this.customMovesArr);
      //       clone.slice(0, currentCustomMoveIndex);
      //       clone.push({ fen, prevMove: [orig, dest] });
      //       this.customMovesArr = clone;
      //       this.currentCustomMoveIndex++;
      //     } else {
      //       this.customMovesArr.push({ fen, prevMove: [orig, dest] });
      //       this.currentCustomMoveIndex++;
      //     }
      //   }
      // }

      if (this.useStockFish && this.$stockfish) {
        this.stockfishCalculationsCount = 0;
        this.$stockfish.postMessage('stop');
        this.stockFishStrings = [];
        this.$stockfish.postMessage(`position fen ${fen}`);
        this.$stockfish.postMessage('go infinite');
      }
    },
    handleStockFishMessage(event) {
      if (event.data && event.data.indexOf('info depth') > -1) {
        this.stockfishCalculationsCount += 1;

        const needToIgnore = this.validateStockFishResponse(event.data);

        if (needToIgnore) {
          return;
          // this.$stockfish.postMessage("stop");
        } else {
          //  console.log(event.data)

          const newItem = this.prepareString(event.data);

          if (newItem && newItem.moves.length > 3) {
            const clone = this.stockFishStrings.reduce((acc, item) => {
              if (newItem.multipv === item.multipv) {
                acc.push(newItem);
              } else {
                acc.push(item);
              }
              return acc;
            }, []);

            if (
              clone.findIndex((item) => item.multipv === newItem.multipv) === -1
            ) {
              clone.push(newItem);
            }

            this.stockFishStrings = clone;
          }
        }
      }
    },
    handleUpdateTooltipBoard: throttle(function (val) {
      this.smallBoardApi?.setPosition(val.fen);
      const coords = this.getMoveCoords(val.move_uci);

      if (coords.length === 2) {
        if (val.arrowColor) {
          this.smallBoardApi?.setShapes([
            { orig: coords[0], dest: coords[1], brush: val.arrowColor },
          ]);
        }
      }
    }, 200),
    changeOpenedCardId(val) {
      this.openedCardId = val;
    },
    //Открывает все вершины и загружает потомков, если есть тренировки
    async loadAllExercises() {
      if (!this.treeData) {
        return;
      }

      const treeData = cloneDeep(this.treeData);
      const newOpenedNodes = [treeData];
      const maxHm = treeData.hm + 10;
      const heroid = Number(this.heroid);
      const herocats = this.heroCategories;

      const updateChildren = async (item) => {
        const { status, default_training, iscrown } = item;

        if (
          (status === 0 || (status === 2 && default_training === 1)) &&
          iscrown === 0 &&
          item.hm <= maxHm
        ) {
          newOpenedNodes.push(item);
          if (item.children.length === 0) {
            await new Promise((res) => setTimeout(res, 100));
            const loadedNestedChildren = await this.getHeroVariants({
              posid: Number(item.pos_to_id),
              hm_child: item.hm_child,
              hm: Number(item.hm),
              heroid: Number(heroid),
              herocats: Array.isArray(herocats)
                ? herocats
                : herocats.split(',').map((i) => Number(i)),
              depthfull: 1,
              depthfilter: 0,
              herowhite: treeData.hm % 2 === 0 ? 0 : 1,
              nodeid: item.nodethis,
            });

            for (let child of loadedNestedChildren) {
              await updateChildren(child);
            }
            item.children = loadedNestedChildren;
          } else {
            for (let child of item.children) {
              await updateChildren(child);
            }
          }
        }
      };

      for (const child of treeData.children) {
        await updateChildren(child);
      }
      this.SET_DATA_BY_KEY({ key: 'autoLoading', value: false });
      this.treeData = treeData;
      this.openedNodesArr = newOpenedNodes;
      this.TOMode = 2;
      this.emphasisMode = 1;
    },
  },
  watch: {
    selectedMistake: {
      immediate: true,
      handler: async function (item) {
        if (item) {
          await requestOrchestrator.killAll();
          this.permanentlyHiddenNodes = {};
          this.bookMarks = [];
          this.originalOpening = null;
          this.customMovesArr = [];
          this.currentCustomMoveIndex = undefined;
          this.customMove = null;
          this.searchModel = '';

          this.SET_DATA_BY_KEY({ key: 'parsedFen', value: null });

          const flip_board_black = this.userPreferences.flip_board_black;
          this.SET_DATA_BY_KEY({
            key: 'currentSide',
            value:
              item.herowhite === 0 && flip_board_black === 1
                ? 'black'
                : 'white',
          });

          //tmp

          this.getOpenings({
            position_id: item.position_id,
            hm: item.hm,
            heroid: this.heroid,
            herocats: this.heroCategories,
            herowhite: item.herowhite,
          });

          await this.$nextTick();
          const isBlack = item.herowhite == 0;
          const current = this.boardApi?.getTurnColor();

          if (
            (current === 'black' && !isBlack) ||
            (isBlack && current === 'white')
          ) {
            this.boardApi?.toggleOrientation();
          }
        }
      },
    },
    customMove: {
      handler: async function (val, oldVal) {
        if (val !== oldVal) {
          if (val) {
            if (this.useStockFish && this.$stockfish) {
              this.stockfishCalculationsCount = 0;
              this.$stockfish.postMessage('stop');
              this.stockFishStrings = [];
              this.$stockfish.postMessage(`position fen ${val}`);
              this.$stockfish.postMessage('go infinite');
            }

            if (this.selectedOpening) {
              const selectedOpening = this.getFullNode;
              const openedNodesModel = this.openedNodesModel;

              let occurance = selectedOpening.children.find(
                (op) => op.fen.split(' ')[0] === val.split(' ')[0],
              );
              // console.log(JSON.parse(JSON.stringify(selectedOpening.children)))
              this.preventedByCustomMove = true;
              if (occurance) {
                if (occurance.emphasis === -1 || occurance.emphasis === 0) {
                  occurance = this.updateNodeEmphasis(occurance);
                }

                if (
                  openedNodesModel(selectedOpening) &&
                  selectedOpening.children.length > 0
                ) {
                  this.goToNode(occurance);
                } else {
                  await this.loadChildren({
                    _node_id: selectedOpening.id,
                    preventOpen: false,
                    heroid: this.heroid,
                    herocats: this.heroCategories,
                    nodethis: selectedOpening?.nodethis,
                    posid: Number(
                      selectedOpening.posid || selectedOpening.pos_to_id,
                    ),
                    nodeid: selectedOpening?.nodethis,
                  });
                  // this.goToNode(occurance);
                }

                this.SET_DATA_BY_KEY({
                  key: 'selectedOpening',
                  value: occurance,
                });
                this.goToNode(occurance);
              }
            }
          }
        }
      },
    },
    treeData: {
      deep: true,
      immediate: true,
      handler: async function (val, oldVal) {
        if (!val || this.autoLoadingProgress !== null) return;
        const prepared = await this.prepareTree(val);
        this.preparedTree = prepared;
      },
    },
    selectedOpening: {
      deep: true,
      handler: async function (val) {
        if (val) {
          // await this.$nextTick();

          // this.boardApi?.resetBoard();
          // this.boardApi?.setPosition(val.fen);

          // this.smallBoardApi?.orientation(val.hm % 2 === 0 ? 'black' : 'white');

          // this.moveBoardApi?.resetBoard();
          // this.moveBoardApi?.setPosition(this.selectedOpening.fen);

          // this.boardApi?.move("g1f3");
          // this.boardApi?.move("f8g7");

          if (this.preventedByCustomMove) {
            this.preventedByCustomMove = false;
          } else {
            this.customMove = null;
            this.customMovesArr = [];
            this.currentCustomMoveIndex = undefined;
          }

          if (this.useStockFish && this.$stockfish) {
            this.stockfishCalculationsCount = 0;
            this.$stockfish.postMessage('stop');
            this.stockFishStrings = [];
            this.$stockfish.postMessage(`position fen ${val.fen}`);
            this.$stockfish.postMessage('go infinite');
          }

          if (val && val.iscrown === 0) {
            if (val?.children?.length === 0) {
              await this.loadChildren({
                _node_id: val.id,
                preventOpen: false,
                heroid: this.heroid,
                herocats: this.heroCategories,
                nodethis: val.nodethis,
                posid: Number(val.posid || val.pos_to_id),
                nodeid: val.nodethis,
              });

              await new Promise((res) => setTimeout(res, 100));
              this.SET_DATA_BY_KEY({ key: 'autoLoading', value: false });
            }
            if (!this.openedNodesModel(val) && val?.children?.length > 0) {
              this.toggleNode(val);
            }
          }

          // const shapeSet3: DrawShape[] = [{ orig: 'e5', brush: 'blue' }];
          // drawMove(orig: Square, dest: Square, brushColor: BrushColor): void;
          // const coords = this.getMoveCoords(val.move_uci);
          // if (coords.length === 2) {
          //   this.boardApi?.drawMove(coords[0], coords[1], "green");
          // }
        }
      },
    },
    tooltip: {
      deep: true,
      handler: async function (val) {
        if (val && val.fen) {
          await this.$nextTick();
          this.handleUpdateTooltipBoard(val);
        }
      },
    },
    userPreferences: {
      immediate: true,
      handler: function (val) {
        if (val) {
          if (!this.heroesList) {
            this.getHeroesList();
          }
          if (this.$stockfish) {
            this.$stockfish.onmessage = this.handleStockFishMessage;
          }

          if (!this.mistakes || this.mistakes.length === 0) {
            this.getMistakes({
              heroid: this.heroid,
              herocats: this.heroCategories,
              position_id:
                this.selectedMistake?.position_id || this.$route.params.posid,
            });
          }
        }
      },
    },
    stockFishSync: {
      handler: function (val) {
        if (val) {
          const selectedOpening = this.selectedOpening;
          const customMove = this.customMove;

          if (this.$stockfish && (customMove || selectedOpening?.fen)) {
            const fen = customMove || selectedOpening?.fen;

            this.stockfishCalculationsCount = 0;
            this.$stockfish.postMessage('stop');
            this.stockFishStrings = [];
            this.$stockfish.postMessage(`position fen ${fen}`);
            this.$stockfish.postMessage('go infinite');
          }
        }
      },
    },
    parsedFen: {
      handler: function (val) {
        const userPreferences = this.userPreferences;
        if (val) {
          if (val.herowhite === 0) {
            this.SET_DATA_BY_KEY({
              key: 'currentSide',
              value:
                val.herowhite === 0 && userPreferences.flip_board_black === 1
                  ? 'black'
                  : 'white',
            });
          }
        }
      },
    },
    visibilityFilters: {
      deep: true,
      handler: async function () {
        const treeData = this.treeData;
        if (treeData) {
          this.preparedTree = await this.prepareTree(treeData);
        }
      },
    },
    permanentlyHiddenNodes: {
      deep: true,
      handler: async function () {
        const treeData = this.treeData;
        if (treeData) {
          this.preparedTree = await this.prepareTree(treeData);
        }
      },
    },
  },
  beforeUnmount() {
    this.$stockfish?.postMessage('stop');
  },
  mounted() {
    this.sfChess = new Chess();
    if (this.stockFishSync === undefined) {
      const local = localStorage.getItem('useStockfish') === '1';
      this.stockFishSync = local;
    }
    this.getExercisesList({ heroid: Number(this.heroid) });

    // if(this.selectedMistake) {
    //   this.handleMistakeClick(this.selectedMistake)
    // }
  },
  provide() {
    return {
      openedNodesArr: () => this.openedNodesArr,
    };
  },
};
</script>

<style>
.main-wrap {
  width: 100% !important;
}

.left-menu {
  position: absolute;
  top: 6 0px;
  transition: left smooth;
  z-index: 100;
}

.left-menu-toggler {
  width: 30px;
  height: 30px;
  position: absolute;
  top: calc(50% - 15px);
  right: -30px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  cursor: pointer;
}

.stockfish-node {
  overflow: hidden;
  height: 34px;
}

.stockfish-node__toggled {
  overflow: visible;
  height: auto;
}

.hoverableStockFishItem:hover {
  color: #1d85dd;
}

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px; /* Отступы для красоты */
}

.sidebar {
  /* Выравнивание по левому краю */
  width: 240px; /* Фиксированная ширина для колонки */
}
</style>
