<template>
  <sc-table
    :height="height"
    fixed-header
    :headers="tableHeaders"
    :rows="tableData"
    clickable
    @clickOnRow="selectRow"
  />
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { TableHeader, TableRow } from '../Common/ScTable/ScTable.types';

interface ExercisesVariantsListProps {
  tableHeaders: TableHeader[];
  tableData: Record<string, unknown>[];
  height: String
};

const emit = defineEmits(['select']);

const props = defineProps<ExercisesVariantsListProps>();

const selectRow = (row: TableRow) => {
  emit('select', row);
};

</script>

<style scoped>

</style>